const GameMode = [
  {
    label: 'Undefined',
    abbreviation: 'NA',
    value: -1,
  },
  {
    label: 'Batting Practice',
    abbreviation: 'BP',
    value: 0,
  },
  {
    label: 'Warm Up',
    abbreviation: 'WU',
    value: 1,
  },
  {
    label: 'Live',
    abbreviation: 'Live',
    value: 2,
  },
  {
    label: 'Game Over',
    abbreviation: 'Over',
    value: 3,
  },
  {
    label: 'Test Mode',
    abbreviation: 'Test',
    value: 4,
  },
]

const GameModeMap = new Map(GameMode.map((mode) => [mode.value, mode]))

const DataSource = {
  BOSS: 'boss',
  STRINGER: 'stringer',
  TRACKING: 'tracking',
}

const HttpMethod = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE',
  PATCH: 'PATCH',
}

const Role = {
  ADMIN: 'ADMIN',
  SUPER_USER: 'SUPER_USER',
  POWER_USER: 'POWER_USER',
  DATA_QUALITY: 'DATA_QUALITY',
  GAMEDAY: 'GAMEDAY',
  USER: 'USER',
  TRACKING: 'TRACKING',
}

const ApiRole = {
  ROLE_BROADCAST: 'ROLE_BROADCAST',
  ROLE_ENHANCED_BROADCAST: 'ROLE_ENHANCED_BROADCAST',
  ROLE_TEAM: 'ROLE_TEAM',
  ROLE_EDITORIAL: 'ROLE_EDITORIAL',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_AUDIT_USER: 'ROLE_AUDIT_USER',
  ROLE_SUPER_USER: 'ROLE_SUPER_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
}

const OktaRole = {
  ADMIN: 'norad-admin',
  SUPER_USER: 'norad-super-user',
  POWER_USER: 'norad-power-user',
  DATA_QUALITY: 'norad-data-quality-user',
  GAMEDAY: 'norad-gameday-user',
  TRACKING: 'norad-tracking-user',
  GAME_LOCK: 'norad-game-lock',
  CLEAR_GAME_STATS: 'norad-clear-game-stats',
  HAWKEYE_USER: 'norad-hawkeye-user',
  OFFICIAL_SCORER: 'norad-official-scorer',
  USER: 'norad-user',
}

const NonGameEventColumn = {
  TIME: {
    label: 'Time',
    editable: false,
    id: 'time',
  },
  GAME_MODE: {
    label: 'Game Mode',
    editable: true,
    id: 'game_mode',
    sortIndex: 0,
  },
  PITCHER: {
    label: 'Pitcher',
    editable: true,
    id: 'pitcher',
    sortIndex: 1,
  },
  BATTER: {
    label: 'Batter',
    editable: true,
    id: 'batter',
    sortIndex: 2,
  },
  PITCH_TYPE: {
    label: 'Pitch Type',
    editable: true,
    id: 'pitch_type',
    sortIndex: 3,
  },
  PITCH_CALL: {
    label: 'Pitch Call',
    editable: true,
    id: 'pitch_call',
    sortIndex: 4,
  },
  RESULT: {
    label: 'Pitch Result',
    editable: true,
    id: 'pitch_result',
    sortIndex: 5,
  },
}

const NonGameEventPitchCall = [
  {
    label: 'Ball - Called',
    value: 'ball',
  },
  {
    label: 'Strike - Foul',
    value: 'foul',
  },
  {
    label: 'Strike - Called',
    value: 'called_strike',
  },
  {
    label: 'Strike - Swinging',
    value: 'swinging_strike',
  },
  {
    label: 'Hit Into Play',
    value: 'hit_into_play',
  },
  {
    label: 'Ball - Hit By Pitch',
    value: 'hit_by_pitch',
  },
  {
    label: 'Pickoff',
    value: 'pickoff',
  },
]

const NonGameEventPlayResult = [
  {
    label: 'Field Out',
    value: 'field_out',
  },
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Double',
    value: 'double',
  },
  {
    label: 'Triple',
    value: 'triple',
  },
  {
    label: 'Home Run',
    value: 'home_run',
  },
  {
    label: 'Field Error',
    value: 'error',
  },
]

const playerInfoRosterColumns = [
  {
    value: 'jerseyNumber',
    label: 'Jersey',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'batSide',
    label: 'Bats',
  },
  {
    value: 'birthCity',
    label: 'Birth City',
  },
  {
    value: 'birthCountry',
    label: 'Birth Country',
  },
  {
    value: 'birthDate',
    label: 'Birth Date',
  },
  {
    value: 'birthStateProvince',
    label: 'Birth State',
  },
  {
    value: 'boxscoreName',
    label: 'Boxscore Name',
  },
  {
    value: 'currentAge',
    label: 'Age',
  },
  {
    value: 'draftYear',
    label: 'Draft Year',
  },
  {
    value: 'firstLastName',
    label: 'First Last Name',
  },
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'fullFMLName',
    label: 'First Middle Last Name',
  },
  {
    value: 'fullLFMName',
    label: 'Last First Middle Name',
  },
  {
    value: 'fullName',
    label: 'Full Name',
  },
  {
    value: 'height',
    label: 'Height',
  },
  {
    value: 'id',
    label: 'Id',
  },
  {
    value: 'initLastName',
    label: 'First Initial Last Name',
  },
  {
    value: 'lastFirstName',
    label: 'Last First Name',
  },
  {
    value: 'lastInitName',
    label: 'Last Name First Initial',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'middleName',
    label: 'Middle Name',
  },
  {
    value: 'mlbDebutDate',
    label: 'Mlb Debut Date',
  },
  {
    value: 'nameFirstLast',
    label: 'First Last Name',
  },
  {
    value: 'pitchHand',
    label: 'Throws',
  },
  {
    value: 'primaryNumber',
    label: 'Number',
  },
  {
    value: 'primaryPosition',
    label: 'Position',
  },
  {
    value: 'useName',
    label: 'Nick Name',
  },
  {
    value: 'weight',
    label: 'Weight',
  },
]

const pitchingStatsRosterColumns = [
  {
    value: 'gamesPlayed',
    label: 'G',
  },
  {
    value: 'inningsPitched',
    label: 'IP',
  },
  {
    value: 'era',
    label: 'ERA',
  },
  {
    value: 'whip',
    label: 'WHIP',
  },
  {
    value: 'wins',
    label: 'W',
  },
  {
    value: 'losses',
    label: 'L',
  },
  {
    value: 'saves',
    label: 'S',
  },
  {
    value: 'strikeOuts',
    label: 'K',
  },
  {
    value: 'baseOnBalls',
    label: 'BB',
  },
  {
    value: 'hits',
    label: 'H',
  },
  {
    value: 'homeRuns',
    label: 'HR',
  },
  {
    value: 'earnedRuns',
    label: 'ER',
  },
  // {
  // 	value: 'gamesPitched',
  // 	label: 'G',
  // },

  // {
  // 	value: 'groundOuts',
  // 	label: 'groundOuts',
  // },
  {
    value: 'runs',
    label: 'R',
  },
  // {
  // 	value: 'strikes',
  // 	label: 'S',
  // },
]

const hittingStatsRosterColumns = [
  {
    value: 'gamesPlayed',
    label: 'G',
  },
  {
    value: 'plateAppearances',
    label: 'PA',
  },
  {
    value: 'atBats',
    label: 'AB',
  },
  {
    value: 'runs',
    label: 'R',
  },
  {
    value: 'homeRuns',
    label: 'HR',
  },
  {
    value: 'rbi',
    label: 'RBI',
  },
  {
    value: 'avg',
    label: 'AVG',
  },
  {
    value: 'babip',
    label: 'BABIP',
  },
  {
    value: 'obp',
    label: 'OBP',
  },
  {
    value: 'slg',
    label: 'SLG',
  },
  {
    value: 'ops',
    label: 'OPS',
  },
  {
    value: 'strikeOuts',
    label: 'K',
  },
  {
    value: 'baseOnBalls',
    label: 'BB',
  },
  {
    value: 'hits',
    label: 'H',
  },
  {
    value: 'doubles',
    label: '2B',
  },
  {
    value: 'triples',
    label: '3B',
  },
  {
    value: 'stolenBases',
    label: 'SB',
  },
  {
    value: 'caughtStealing',
    label: 'CS',
  },
  // {
  // 	value: 'groundOuts',
  // 	label: 'groundOuts',
  // },
  {
    value: 'hitByPitch',
    label: 'HBP',
  },
  // {
  // 	value: 'leftOnBase',
  // 	label: 'LOB',
  // },
  // {
  // 	value: 'sacBunts',
  // 	label: 'Sac Bunt',
  // },
  // {
  // 	value: 'sacFlies',
  // 	label: 'Sac Fly',
  // },
]

const gameTypes = {
  regularSeason: {
    label: 'Regular Season',
    value: 'R',
  },
  springTraining: {
    label: 'Spring Training',
    value: 'S',
  },
  playoffs: {
    label: 'Playoffs',
    value: 'P',
  },
}

const teamColors = {
  159: {
    // American All Stars
    primary: '#074882',
  },
  160: {
    // National All Stars
    primary: '#EA1E4F',
  },
  109: {
    // Diamondbacks
    primary: '#A71930',
    alternate: '#E3D4AD',
  },
  144: {
    // Braves
    primary: '#13274F',
    alternate: '#CE1141',
  },
  110: {
    // Orioles
    primary: '#DF4601',
    alternate: '#000000',
  },
  111: {
    // Red Sox
    primary: '#BD3039',
    alternate: '#0D2B56',
  },
  112: {
    // Cubs
    primary: '#0E3386',
    alternate: '#CC3433',
  },
  145: {
    // White Sox
    primary: '#000000',
    alternate: '#C4CED4',
  },
  113: {
    // Reds
    primary: '#C6011F',
    alternate: '#C4CED4',
  },
  114: {
    // Indians
    primary: '#002B5C',
    alternate: '#E31937',
  },
  115: {
    // Rockies
    primary: '#333366',
    alternate: '#C4CED4',
  },
  116: {
    // Tigers
    primary: '#0C2C56',
    alternate: '#C4CED4',
  },
  117: {
    // Astros
    primary: '#002D62',
    alternate: '#EB6E1F',
  },
  118: {
    // Royals
    primary: '#004687',
    alternate: '#C09A5B',
  },
  108: {
    // Angels
    primary: '#BA0021',
    alternate: '#003263',
  },
  119: {
    // Dodgers
    primary: '#005A9C',
    alternate: '#C4CED4',
  },
  146: {
    // Marlins
    primary: '#FF6600',
    alternate: '#0077C8',
  },
  158: {
    // Brewers
    primary: '#0A2351',
    alternate: '#B5957F',
  },
  142: {
    // Twins
    primary: '#D31145',
    alternate: '#002B5C',
  },
  121: {
    // Mets
    primary: '#002D72',
    alternate: '#FF5910',
  },
  147: {
    // Yankees
    primary: '#132448',
    alternate: '#C4CED4',
  },
  133: {
    // Athletics
    primary: '#003831',
    alternate: '#EFB21E',
  },
  143: {
    // Phillies
    primary: '#E81828',
    alternate: '#C4CED4',
  },
  134: {
    // Pirates
    primary: '#FDB827',
    alternate: '#000000',
  },
  135: {
    // Padres
    primary: '#002D62',
    alternate: '#FEC325',
  },
  137: {
    // Giants
    primary: '#FD5A1E',
    alternate: '#000000',
  },
  136: {
    // Mariners
    primary: '#0C2C56',
    alternate: '#C4CED4',
  },
  138: {
    // Cardinals
    primary: '#C41E3A',
    alternate: '#C4CED4',
  },
  139: {
    // Rays
    primary: '#092C5C',
    alternate: '#8FBCE6',
  },
  140: {
    // Rangers
    primary: '#003278',
    alternate: '#C0111F',
  },
  141: {
    // Blue Jays
    primary: '#134A8E',
    alternate: '#C4CED4',
  },
  120: {
    // Nationals
    primary: '#AB0003',
    alternate: '#11225B',
  },
}

const percentiles = [
  {
    value: '95',
    legend: '95th',
  },
  {
    value: '90',
    legend: '90th',
  },
]

const pitchHands = [
  {
    value: 'R',
    legend: 'Right',
  },
  {
    value: 'L',
    legend: 'Left',
  },
]

const batSides = [
  {
    value: 'R',
    legend: 'Right',
  },
  {
    value: 'L',
    legend: 'Left',
  },
]

const trajectoryTypes = [
  {
    value: 'lineDrive',
    legend: 'Line Drive',
  },
  {
    value: 'flyBall',
    legend: 'Fly Ball',
  },
  {
    value: 'groundBall',
    legend: 'Ground Ball',
  },
  {
    value: 'popUp',
    legend: 'Pop Up',
  },
]

const sortTypes = [
  {
    value: 'asc',
    legend: 'Lowest',
  },
  {
    value: 'desc',
    legend: 'Highest',
  },
]

const eventTypes = [
  {
    value: 'homeRun',
    legend: 'Home Run',
  },
  {
    value: 'single',
    legend: 'Single',
  },
  {
    value: 'double',
    legend: 'Double',
  },
  {
    value: 'triple',
    legend: 'Triple',
  },
  {
    value: 'stolenBaseAttempt',
    legend: 'Stolen Base Attempt',
  },
  {
    value: 'stolenBaseAny',
    legend: 'Stolen Base (Any)',
  },
  {
    value: 'stolenBase2b',
    legend: 'Stolen Base (2nd)',
  },
  {
    value: 'stolenBase3b',
    legend: 'Stolen Base (3rd)',
  },
  {
    value: 'stolenBaseHp',
    legend: 'Stolen Base (Home)',
  },
  {
    value: 'caughtStealingAny',
    legend: 'Caught Stealing (Any)',
  },
  {
    value: 'caughtStealing2b',
    legend: 'Caught Stealing (2nd)',
  },
  {
    value: 'caughtStealing3b',
    legend: 'Caught Stealing (3rd)',
  },
  {
    value: 'caughtStealingHp',
    legend: 'Caught Stealing (Home)',
  },
  {
    value: 'sacFly',
    legend: 'Sac Fly',
  },
  {
    value: 'fieldersChoice',
    legend: "Fielder's Choice",
  },
  {
    value: 'fieldersChoiceOut',
    legend: "Fielder's Choice (Out)",
  },
  {
    value: 'fieldOut',
    legend: 'Batter Out',
  },
  {
    value: 'fieldError',
    legend: 'Error',
  },
  {
    value: 'doublePlay',
    legend: 'Double Play',
  },
  {
    value: 'triplePlay',
    legend: 'Triple Play',
  },
]

const pitchTypes = [
  {
    value: 'FF',
    legend: 'Four-seam FB',
    description: 'Fastball (4-seam)',
  },
  {
    value: 'FT',
    legend: 'Two-seam FB',
    description: 'Fastball (2-seam)',
  },
  {
    value: 'FC',
    legend: 'Cutter',
    description: 'Fastball (Cut)',
  },
  {
    value: 'SI',
    legend: 'Sinker',
    description: 'Sinker',
  },
  {
    value: 'FS',
    legend: 'Splitter',
    description: 'Fastball (Split-finger)',
  },
  {
    value: 'FO',
    legend: 'Forkball',
    description: 'Forkball',
  },
  {
    value: 'SL',
    legend: 'Slider',
    description: 'Slider',
  },
  {
    value: 'CU',
    legend: 'Curveball',
    description: 'Curveball',
  },
  {
    value: 'KC',
    legend: 'Knuckle Curve',
    description: 'Knuckle Curve',
  },
  {
    value: 'GY',
    legend: 'Gyroball',
    description: 'Gyroball',
  },
  {
    value: 'SC',
    legend: 'Screwball',
    description: 'Screwball',
  },
  {
    value: 'CH',
    legend: 'Changeup',
    description: 'Changeup',
  },
  {
    value: 'KN',
    legend: 'Knuckleball',
    description: 'Knuckleball',
  },
  {
    value: 'EP',
    legend: 'Eephus Pitch',
    description: 'Eephus Pitch',
  },
  {
    value: 'IN',
    legend: 'Intentional Ball',
    description: 'Intentional Ball',
  },
  {
    value: 'PO',
    legend: 'Pitchout',
    description: 'Pitchout',
  },
  {
    value: 'AB',
    legend: 'Automatic Ball',
    description: 'Automatic Ball',
  },
  {
    value: 'AS',
    legend: 'Automatic Strike',
    description: 'Automatic Strike',
  },
  {
    value: 'NP',
    legend: 'No Pitch',
    description: 'No Pitch',
  },
  {
    value: 'UN',
    legend: 'Unknown',
    description: 'Unknown',
  },
]

const positions = [
  null,
  {
    name: 'Pitcher',
    abbrev: 'P',
    code: 1,
  },
  {
    name: 'Catcher',
    abbrev: 'C',
    code: 2,
  },
  {
    name: 'First Base',
    abbrev: '1B',
    code: 3,
  },
  {
    name: 'Second Base',
    abbrev: '2B',
    code: 4,
  },
  {
    name: 'Third Base',
    abbrev: '3B',
    code: 5,
  },
  {
    name: 'Short Stop',
    abbrev: 'SS',
    code: 6,
  },
  {
    name: 'Left Field',
    abbrev: 'LF',
    code: 7,
  },
  {
    name: 'Center Field',
    abbrev: 'CF',
    code: 8,
  },
  {
    name: 'Right Field',
    abbrev: 'RF',
    code: 9,
  },
  {
    name: 'Batter',
    abbrev: 'B',
    code: 10,
  },
  {
    name: 'Runner on 1st',
    abbrev: 'R1',
    code: 11,
  },
  {
    name: 'Runner on 2nd',
    abbrev: 'R2',
    code: 12,
  },
  {
    name: 'Runner on 3rd',
    abbrev: 'R3',
    code: 13,
  },
]

const groups = {
  hitting: {
    key: 'hitting',
    name: 'Hitting',
    group: 'hitting',
    abbreviation: 'Hit',
  },
  pitching: {
    key: 'pitching',
    name: 'Pitching',
    group: 'pitching',
    abbreviation: 'Pitch',
  },
  catching: {
    key: 'catching',
    name: 'Catching',
    group: 'fielding',
    abbreviation: 'Catch',
    position: 'C',
  },
  infield: {
    key: 'infield',
    name: 'Infield',
    group: 'fielding',
    abbreviation: 'IF',
    position: 'IF',
  },
  outfield: {
    key: 'outfield',
    name: 'Outfield',
    group: 'fielding',
    abbreviation: 'OF',
    position: 'OF',
  },
  running: {
    key: 'running',
    name: 'Base Running',
    group: 'running',
    abbreviation: 'Run',
  },
}

const metrics = [
  {
    key: 'spin_rate',
    name: 'Spin Rate',
    abbrev: 'Spin',
    group: 'pitching',
    apiKey: 'releaseSpinRate',
    sqlKey: 'p_release_spin_rate',
    unit: 'RPM',
    decimalPlaces: 0,
    context: true,
    metricId: 1000,
  },
  {
    key: 'extension',
    name: 'Extension',
    abbrev: 'Ext',
    group: 'pitching',
    apiKey: 'releaseExtension',
    sqlKey: 'p_release_extension',
    unit: 'FT',
    decimalPlaces: 1,
    context: true,
    metricId: 1001,
  },
  {
    key: 'pitch_speed',
    name: 'Pitch Speed',
    abbrev: 'Speed',
    group: 'pitching',
    apiKey: 'releaseSpeed',
    sqlKey: 'p_release_speed',
    unit: 'MPH',
    decimalPlaces: 1,
    context: true,
    metricId: 1002,
  },
  {
    key: 'plate_time',
    name: 'Plate Time',
    abbrev: 'PT',
    group: 'pitching',
    apiKey: 'plateTime',
    sqlKey: 'p_plate_time',
    unit: 'SEC',
    decimalPlaces: 2,
    // context: true,
    metricId: 1095,
  },
  {
    key: 'effective_speed',
    name: 'Effective Speed',
    abbrev: 'Eff Speed',
    group: 'pitching',
    apiKey: 'effectiveSpeed',
    sqlKey: 'p_effective_speed',
    unit: 'MPH',
    decimalPlaces: 1,
    // context: true,
    metricId: 1028,
  },
  {
    key: 'primary_lead',
    name: 'Primary Lead',
    abbrev: 'Pri Lead',
    group: 'running',
    apiKey: 'primaryLead',
    sqlKey: 'r_primary_lead',
    unit: 'FT',
    decimalPlaces: 1,
    metricId: 1009,
  },
  {
    key: 'exit_velocity_against',
    name: 'Exit Velocity Against',
    abbrev: 'EVA',
    group: 'pitching',
    apiKey: 'launchSpeed',
    sqlKey: 'p_launch_speed',
    unit: 'MPH',
    metricId: 1003,
  },
  {
    key: 'exit_velocity',
    name: 'Exit Velocity',
    abbrev: 'EV',
    group: 'hitting',
    apiKey: 'launchSpeed',
    sqlKey: 'h_launch_speed',
    unit: 'MPH',
    decimalPlaces: 1,
    context: true,
    metricId: 1003,
  },
  {
    key: 'launch_angle_against',
    name: 'Launch Angle Against',
    abbrev: 'LAA',
    group: 'pitching',
    apiKey: 'launchAngle',
    sqlKey: 'p_launch_angle',
    unit: 'DEG',
    decimalPlaces: 0,
    metricId: 1005,
  },
  {
    key: 'launch_angle',
    name: 'Launch Angle',
    abbrev: 'LA',
    group: 'hitting',
    apiKey: 'launchAngle',
    sqlKey: 'h_launch_angle',
    unit: 'DEG',
    decimalPlaces: 0,
    context: true,
    metricId: 1005,
  },
  {
    key: 'secondary_lead',
    name: 'Secondary Lead',
    abbrev: 'Sec Lead',
    group: 'running',
    apiKey: 'secondaryLead',
    sqlKey: 'r_secondary_lead',
    unit: 'FT',
    decimalPlaces: 1,
    metricId: 1010,
  },
  {
    key: 'top_speed_fielder',
    name: 'Top Speed',
    abbrev: 'Speed',
    group: 'fielding, running',
    apiKey: 'topSpeed',
    sqlKey: 'f_top_speed',
    unit: 'MPH',
    metricId: 1008,
  },
  {
    key: 'top_speed_runner',
    name: 'Top Speed',
    abbrev: 'Speed',
    group: 'fielding, running',
    apiKey: 'topSpeed',
    sqlKey: 'r_top_speed',
    unit: 'MPH',
    metricId: 1008,
  },
  {
    key: 'acceleration_fielder',
    name: 'Acceleration',
    abbrev: 'Acc',
    group: 'fielding, running',
    apiKey: 'acceleration',
    sqlKey: 'f_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1011,
  },
  {
    key: 'acceleration_runner',
    name: 'Acceleration',
    abbrev: 'Acc',
    group: 'fielding, running',
    apiKey: 'acceleration',
    sqlKey: 'r_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1011,
  },
  {
    key: 'acceleration',
    name: 'Acceleration',
    abbrev: 'Acc',
    group: 'fielding, running',
    apiKey: 'acceleration',
    sqlKey: 'r_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1011,
  },
  {
    key: 'top_acceleration',
    name: 'Top Acceleration',
    abbrev: 'Top Acc',
    group: 'fielding, running',
    apiKey: 'topAcceleration',
    sqlKey: 'f_top_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1032,
  },
  {
    key: 'top_acceleration_fielder',
    name: 'Top Acceleration',
    abbrev: 'Top Acc',
    group: 'fielding, running',
    apiKey: 'topAcceleration',
    sqlKey: 'f_top_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1032,
  },
  {
    key: 'top_acceleration_runner',
    name: 'Top Acceleration',
    abbrev: 'Top Acc',
    group: 'fielding, running',
    apiKey: 'topAcceleration',
    sqlKey: 'r_top_acceleration',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1032,
  },
  {
    key: 'home_to_1st',
    name: 'Home to 1st',
    abbrev: 'H-1st',
    group: 'running',
    apiKey: 'homeToFirst',
    sqlKey: 'r_home_to_first',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1012,
  },
  {
    key: 'home_to_2nd',
    name: 'Home to 2nd',
    abbrev: 'Ext',
    group: 'running',
    apiKey: 'homeToSecond',
    sqlKey: 'r_home_to_second',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1013,
  },
  {
    key: 'home_to_3rd',
    name: 'Home to 3rd',
    abbrev: 'Ext',
    group: 'running',
    apiKey: 'homeToThird',
    sqlKey: 'r_home_to_third',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1014,
  },
  {
    key: 'home_to_home',
    name: 'Home to Home',
    abbrev: 'H-H',
    group: 'running',
    apiKey: 'homeToHome',
    sqlKey: 'r_home_to_home',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1029,
  },
  {
    key: '1st_to_2nd',
    name: '1st to 2nd',
    abbrev: '1st-2nd',
    group: 'running',
    apiKey: 'firstToSecond',
    sqlKey: 'r_first_to_second',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1035,
  },
  {
    key: '1st_to_2nd_steal',
    name: '1st to 2nd Steal',
    abbrev: '1st-2nd Steal',
    group: 'running',
    apiKey: 'firstToSecondSteal',
    sqlKey: 'r_first_to_second_steal',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1040,
  },
  {
    key: '1st_to_2nd_tag',
    name: '1st to 2nd Tag',
    abbrev: '1st-2nd Tag',
    group: 'running',
    apiKey: 'firstToSecondStealTag',
    sqlKey: 'r_first_to_second_tag',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1055,
  },
  {
    key: '1st_to_2nd_all',
    name: '1st to 2nd',
    abbrev: '1st-2nd',
    group: 'running',
    apiKey: 'firstToSecondAll',
    sqlKey: 'r_first_to_second_all',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    key: '1st_to_3rd',
    name: '1st to 3rd',
    abbrev: 'Ext',
    group: 'running',
    apiKey: 'firstToThird',
    sqlKey: 'r_first_to_third',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1015,
  },
  {
    key: '1st_to_home',
    name: '1st to Home',
    abbrev: '1st-H',
    group: 'running',
    apiKey: 'firstToHome',
    sqlKey: 'r_first_to_home',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1016,
  },
  {
    key: '2nd_to_3rd',
    name: '2nd to 3rd',
    abbrev: '2nd-3rd',
    group: 'running',
    apiKey: 'secondToThird',
    sqlKey: 'r_second_to_third',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1036,
  },
  {
    key: '2nd_to_3rd_steal',
    name: '2nd to 3rd Steal',
    abbrev: '2nd-3rd Steal',
    group: 'running',
    apiKey: 'secondToThirdSteal',
    sqlKey: 'r_second_to_third_steal',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1041,
  },
  {
    key: '2nd_to_3rd_tag',
    name: '2nd to 3rd Tag',
    abbrev: '2nd-3rd Tag',
    group: 'running',
    apiKey: 'secondToThirdTag',
    sqlKey: 'r_second_to_third_tag',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1056,
  },
  {
    key: '2nd_to_3rd_all',
    name: '2nd to 3rd All',
    abbrev: '2nd-3rd All',
    group: 'running',
    apiKey: 'secondToThirdAll',
    sqlKey: 'r_second_to_third_all',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    key: '2nd_to_home',
    name: '2nd to Home',
    abbrev: '2nd-H',
    group: 'running',
    apiKey: 'secondToHome',
    sqlKey: 'r_second_to_home',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1017,
  },
  {
    key: '3rd_to_home',
    name: 'Third to Home',
    abbrev: '3rd-H',
    group: 'running',
    apiKey: 'thirdToHome',
    sqlKey: 'r_third_to_home',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1037,
  },
  {
    key: '3rd_to_home_steal',
    name: '3rd to Home Steal',
    abbrev: '3rd-H Steal',
    group: 'running',
    apiKey: 'thirdToHomeSteal',
    sqlKey: 'r_third_to_home_steal',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1042,
  },
  {
    key: '3rd_to_home_tag',
    name: '3rd to Home Tag',
    abbrev: '3rd-H Tag',
    group: 'running',
    apiKey: 'thirdToHomeTag',
    sqlKey: 'r_third_to_home_tag',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1057,
  },
  {
    key: '3rd_to_home_all',
    name: '3rd to Home',
    abbrev: '3rd-H',
    group: 'running',
    apiKey: 'thirdToHomeAll',
    sqlKey: 'r_third_to_home_all',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    key: 'first_step',
    name: 'First Step',
    abbrev: 'Step',
    group: 'fielding, running',
    apiKey: 'firstStep',
    sqlKey: 'f_first_step',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1007,
  },
  {
    key: 'first_step_steal',
    name: 'First Step',
    abbrev: 'Step',
    group: 'running',
    apiKey: 'firstStepSteal',
    sqlKey: 'r_first_step_steal',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1020,
  },
  {
    key: 'first_step_tag',
    name: 'First Step',
    abbrev: 'Step',
    group: 'running',
    apiKey: 'firstStepTag',
    sqlKey: 'r_first_step_tag',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1058,
  },
  {
    key: 'first_step_All',
    name: 'First Step',
    abbrev: 'Step',
    group: 'running',
    apiKey: 'firstStepAll',
    sqlKey: 'r_first_step_all',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 0,
  },
  {
    key: 'first_Step_effeciency',
    name: 'First Step Efficiency',
    abbrev: 'Step Eff',
    group: 'fielding',
    apiKey: 'firstStepEfficiency',
    sqlKey: 'f_first_step_efficiency',
    unit: 'PCT',
    metricId: 1021,
  },
  {
    key: 'pop_time',
    name: 'Pop Time',
    abbrev: 'Pop',
    group: 'fielding',
    apiKey: 'popTime',
    sqlKey: 'f_pop_time',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 1026,
  },
  {
    key: 'pop_time_1st',
    name: 'Pop Time 1st',
    abbrev: 'Pop 1st',
    group: 'fielding',
    apiKey: 'popTime1b',
    sqlKey: 'f_pop_time_1b',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    key: 'pop_time_2nd',
    name: 'Pop Time 2nd',
    abbrev: 'Pop 2nd',
    group: 'fielding',
    apiKey: 'popTime2b',
    sqlKey: 'f_pop_time_2b',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    key: 'pop_time_3rd',
    name: 'Pop Time 3rd',
    abbrev: 'Pop 3rd',
    group: 'fielding',
    apiKey: 'popTime3b',
    sqlKey: 'f_pop_time_3b',
    unit: 'SEC',
    decimalPlaces: 2,
    metricId: 0,
  },
  {
    apiKey: 'positioning',
    sqlKey: '',
    metricId: 1019,
  },
  {
    key: 'route_efficiency',
    name: 'Route Efficiency',
    abbrev: 'Route',
    group: 'fielding',
    apiKey: 'routeEfficiency',
    sqlKey: 'f_route_efficiency',
    unit: 'PCT',
    metricId: 1022,
  },
  {
    key: 'exchange',
    name: 'Exchange',
    abbrev: 'Exchange',
    group: 'fielding',
    apiKey: 'exchange',
    sqlKey: 'f_exchange',
    unit: 'SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1024,
  },
  {
    key: 'exchange_1st',
    name: 'Exchange 1st',
    abbrev: 'Exchange 1st',
    group: 'fielding',
    apiKey: 'exchange1b',
    sqlKey: 'f_exchange_1b',
    unit: 'SEC',
    metricId: 0,
  },
  {
    key: 'exchange_2nd',
    name: 'Exchange 2nd',
    abbrev: 'Exchange 2nd',
    group: 'fielding',
    apiKey: 'exchange2b',
    sqlKey: 'f_exchange_2b',
    unit: 'SEC',
    metricId: 0,
  },
  {
    key: 'exchange_3rd',
    name: 'Exchange 3rd',
    abbrev: 'Exchange 3rd',
    group: 'fielding',
    apiKey: 'exchange3b',
    sqlKey: 'f_exchange_3b',
    unit: 'SEC',
    metricId: 0,
  },
  {
    key: 'arm_strength',
    name: 'Arm Strength',
    abbrev: 'Arm',
    group: 'fielding',
    apiKey: 'armStrength',
    sqlKey: 'f_arm_strength',
    unit: 'MPH',
    decimalPlaces: 1,
    context: true,
    metricId: 1018,
  },
  {
    key: 'arm_strength_1st',
    name: 'Arm Strength 1st',
    abbrev: 'Arm 1st',
    group: 'fielding',
    apiKey: 'armStrength1b',
    sqlKey: 'f_arm_strength_1b',
    unit: 'MPH',
    metricId: 0,
  },
  {
    key: 'arm_strength_2nd',
    name: 'Arm Strength 2nd',
    abbrev: 'Arm 2nd',
    group: 'fielding',
    apiKey: 'armStrength2b',
    sqlKey: 'f_arm_strength_2b',
    unit: 'MPH',
    metricId: 0,
  },
  {
    key: 'arm_strength_3rd',
    name: 'Arm Strength 3rd',
    abbrev: 'Arm 3rd',
    group: 'fielding',
    apiKey: 'armStrength3b',
    sqlKey: 'f_arm_strength_3b',
    unit: 'MPH',
    metricId: 0,
  },
  {
    key: 'arm_strength_home',
    name: 'Arm Strength Home',
    abbrev: 'Arm Home',
    group: 'fielding',
    apiKey: 'armStrengthHp',
    sqlKey: 'f_arm_strength_hp',
    unit: 'MPH',
    metricId: 0,
  },
  {
    key: 'throwing_accuracy',
    name: 'Throwing Accuracy',
    abbrev: 'Throw Acc',
    group: 'fielding',
    apiKey: 'throwingAccuracy',
    sqlKey: 'f_throwing_accuracy',
    unit: 'FT',
    metricId: 1034,
  },
  {
    apiKey: 'throwFielderAngle',
    sqlKey: '',
    metricId: 1046,
  },
  {
    key: 'throw_distance',
    name: 'Throw Distance',
    abbrev: 'Throw Dist',
    group: 'fielding',
    apiKey: 'throwDistance',
    sqlKey: 'f_throw_distance',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1038,
  },
  {
    key: 'distance_covered',
    name: 'Distance Covered',
    abbrev: 'Dist Cov',
    group: 'fielding',
    apiKey: 'distanceCovered',
    sqlKey: 'f_distance_covered',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1023,
  },
  // {
  //     key: 'distance_covered',
  //     name: 'Distance Covered',
  //     abbrev: 'Dist Cov',
  //     group: "fielding",
  //     apiKey: "distanceCovered",
  //     sqlKey: 'f_distance_covered',
  //     unit: "FT",
  //     decimalPlaces: 0,
  //     context: true,
  //     metricId: 1086
  // },
  {
    key: 'release_time',
    name: 'Release',
    abbrev: 'Release',
    group: 'pitching',
    apiKey: 'releaseTiming',
    sqlKey: 'p_release_timing',
    unit: 'SEC',
    decimalPlaces: 2,
    context: true,
    metricId: 1006,
  },
  {
    key: 'release_timing',
    name: 'Release',
    abbrev: 'Release',
    group: 'pitching',
    apiKey: 'releaseTiming',
    sqlKey: 'p_release_timing',
    unit: 'SEC',
    decimalPlaces: 2,
    context: true,
    metricId: 1006,
  },
  {
    key: 'backspin_rate',
    name: 'Backspin Rate',
    abbrev: 'BSR',
    group: 'pitching',
    apiKey: 'releaseBackspinRate',
    sqlKey: 'p_release_backspin_rate',
    unit: 'RPM',
    decimalPlaces: 0,
    metricId: 1047,
  },
  {
    key: 'sidespin_rate',
    name: 'Sidespin Rate',
    abbrev: 'SSR',
    group: 'pitching',
    apiKey: 'releaseSidespinRate',
    sqlKey: 'p_release_sidespin_rate',
    unit: 'RPM',
    decimalPlaces: 0,
    metricId: 1048,
  },
  {
    key: 'spin_angle',
    name: 'Spin Angle',
    abbrev: 'SA',
    group: 'pitching',
    // apiKey: "releaseSidespinRate",
    // sqlKey: 'p_release_sidespin_rate',
    unit: 'DEG',
    decimalPlaces: 0,
    metricId: 1065,
  },
  {
    key: 'generated_speed',
    name: 'Generated Speed',
    abbrev: 'Gen EV',
    group: 'hitting, pitching',
    apiKey: 'generatedSpeed',
    sqlKey: 'h_generated_speed',
    unit: 'MPH',
    metricId: 1044,
  },
  {
    key: 'generated_speed_against',
    name: 'Gen EVA',
    abbrev: 'Ext',
    group: 'hitting, pitching',
    apiKey: 'generatedSpeed',
    sqlKey: 'p_generated_speed',
    unit: 'MPH',
    metricId: 1044,
  },
  {
    key: 'estimated_swing_speed',
    name: 'Estimated Swing Speed',
    abbrev: 'Swing',
    group: 'hitting',
    apiKey: 'estimatedSwingSpeed',
    sqlKey: 'h_estimated_swing_speed',
    unit: 'MPH',
    metricId: 1045,
  },
  {
    key: 'stance',
    name: 'Stance',
    abbrev: 'Stance',
    group: 'pitching',
    apiKey: 'position',
    sqlKey: 'p_position',
    unit: '-',
    metricId: 1043,
  },
  // {
  //     key: 'extension',
  //     name: 'Extension',
  //     abbrev: 'Ext',
  //     group: "fielding",
  //     apiKey: "distanceFromLanding",
  //     sqlKey: '',
  //     unit: "FT",
  //     metricId: 1051
  // },
  {
    key: 'distance_from',
    name: 'Distance From',
    abbrev: 'Dist From',
    group: 'fielding',
    apiKey: 'projectedDistFromLanding',
    sqlKey: 'f_projected_dist_from_landing',
    unit: 'FT',
    decimalPlaces: 0,
    metricId: 1052,
  },
  {
    key: 'distance_needed',
    name: 'Distance Needed',
    abbrev: 'Dist From',
    group: 'fielding',
    apiKey: 'projectedDistFromLanding',
    sqlKey: 'f_projected_dist_from_landing',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1052,
  },
  {
    key: 'angle_to_ball_landing',
    name: 'Angle to Ball Landing',
    abbrev: 'Angle',
    group: 'fielding',
    apiKey: 'angleToLanding',
    sqlKey: 'f_angle_to_ball_landing',
    unit: 'DEG',
    metricId: 1053,
  },
  // {
  //     key: 'extension',
  //     name: 'Extension',
  //     abbrev: 'Ext',
  //     group: "fielding",
  //     apiKey: "angleToCaught",
  //     sqlKey: '',
  //     unit: "DEG",
  //     metricId: 1054
  // },
  {
    key: 'height',
    name: 'Height',
    abbrev: 'Height',
    group: 'hitting',
    apiKey: 'maxHeight',
    sqlKey: 'h_max_height',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1039,
  },
  {
    key: 'travel_time',
    name: 'Travel Time',
    abbrev: 'Travel',
    group: 'hitting',
    apiKey: 'travelTime',
    sqlKey: 'h_travel_time',
    unit: 'SEC',
    metricId: 1049,
  },
  {
    key: 'hang_time',
    name: 'Hang Time',
    abbrev: 'Hang',
    group: 'hitting',
    apiKey: 'hangTime',
    sqlKey: 'h_hang_time',
    unit: 'SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1027,
  },
  {
    key: 'opportunity_time',
    name: 'Opportunity Time',
    abbrev: 'Opp',
    group: 'hitting, running',
    apiKey: 'opportunityTime',
    sqlKey: 'f_opportunity_time',
    unit: 'SEC',
    decimalPlaces: 1,
    metricId: 1091,
  },
  // {
  //     apiKey: "opportunityTimeGround",
  //     sqlKey: '',
  //     metricId: 1091
  // },
  {
    key: 'proj_distance',
    name: 'Projected Distance',
    abbrev: 'Proj Dist',
    group: 'hitting',
    apiKey: 'distance',
    sqlKey: 'h_distance',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1031,
  },
  {
    key: 'distance',
    name: 'Distance',
    abbrev: 'Dist',
    group: 'hitting',
    apiKey: 'distance',
    sqlKey: 'h_distance',
    unit: 'FT',
    decimalPlaces: 0,
    context: true,
    metricId: 1030,
  },
  {
    key: 'travel_distance',
    name: 'Travel Distance',
    abbrev: 'Travel Dist',
    group: 'hitting',
    apiKey: 'travelDistance',
    sqlKey: 'h_travel_distance',
    unit: 'FT',
    metricId: 1050,
  },
  {
    key: 'homerun_distance',
    name: 'Home Run Distance',
    abbrev: 'HR Dist',
    group: 'hitting',
    apiKey: 'hrDistance',
    sqlKey: 'h_hr_distance',
    unit: 'FT',
    decimalPlaces: 0,
    metricId: 0,
  },
  {
    key: 'launch_trajectory',
    name: 'Trajectory',
    abbrev: 'Traj',
    group: 'hitting',
    apiKey: 'hitTrajectory',
    sqlKey: 'h_hit_trajectory',
    unit: '-',
    metricId: 1033,
  },
  {
    key: 'launch_vector',
    name: 'Vector',
    abbrev: 'Vec',
    group: 'hitting',
    apiKey: 'hitVector',
    sqlKey: 'h_hit_vector',
    unit: '-',
    metricId: 1004,
  },
  {
    key: 'trajectory',
    name: 'Trajectory',
    abbrev: 'Traj',
    group: 'hitting',
    apiKey: 'hitTrajectory',
    sqlKey: 'h_hit_trajectory',
    unit: '-',
    metricId: 1033,
  },
  {
    key: 'starting_depth',
    name: 'Starting Depth',
    group: 'fielding',
    apiKey: 'startPlayerPosition',
    sqlKey: '',
    unit: 'FT',
    decimalPlaces: 0,
    metricId: 1059,
  },
  {
    group: 'fielding',
    apiKey: 'endPlayerPosition',
    sqlKey: '',
    unit: 'FT',
    metricId: 1060,
  },
  {
    group: 'fielding',
    apiKey: 'startPlayerAngle',
    sqlKey: '',
    unit: 'DEG',
    metricId: 1061,
  },
  {
    group: 'fielding',
    apiKey: 'endPlayerAngle',
    sqlKey: '',
    unit: 'DEG',
    metricId: 1062,
  },
  {
    key: 'launch_spin_rate',
    name: 'Launch Spin Rate',
    abbrev: 'Launch Spin',
    group: 'hitting',
    apiKey: 'launchSpinRate',
    sqlKey: 'h_launch_spin_rate',
    unit: 'RPM',
    decimalPlaces: 0,
    metricId: 1063,
  },
  {
    key: 'gyrospin_rate',
    name: 'Gyro Spin Rate',
    abbrev: 'GSR',
    group: 'pitching',
    apiKey: 'gyrospinRate',
    sqlKey: 'p_gyrospin_rate',
    unit: 'RPM',
    decimalPlaces: 0,
    metricId: 1064,
  },
  {
    key: 'fielding_zone',
    name: 'Fielding Zone',
    abbrev: 'Zone',
    group: 'fielding',
    apiKey: 'fieldingZone',
    sqlKey: 'f_fielding_zone',
    unit: '-',
    metricId: 1068,
  },
  {
    key: 'barrels',
    name: 'Barrels',
    abbrev: 'Barrels',
    group: 'hitting',
    apiKey: 'barreledBall',
    sqlKey: 'h_barreled_ball',
    decimalPlaces: 0,
    unit: '-',
    metricId: 1076,
  },
  {
    key: 'barrel',
    name: 'Barrel',
    abbrev: 'Barrel',
    group: 'hitting',
    apiKey: 'barreledBall',
    sqlKey: 'h_barreled_ball',
    decimalPlaces: 0,
    unit: '-',
    metricId: 1076,
  },
  {
    key: 'launch_spin_axis_octants',
    name: 'Launch Spin Axis Octants',
    abbrev: 'Launch Spin Axis',
    group: 'hitting',
    apiKey: 'spinAxisOctants',
    sqlKey: 'h_spin_axis_octants',
    unit: '-',
    metricId: 1084,
  },
  {
    key: 'spin_axis_octants',
    name: 'Launch Spin Axis Octants',
    abbrev: 'Launch Spin Axis',
    group: 'hitting',
    apiKey: 'spinAxisOctants',
    sqlKey: 'h_spin_axis_octants',
    unit: '-',
    metricId: 1084,
  },
  {
    apiKey: 'shiftClassification',
    sqlKey: '',
    unit: '-',
    metricId: 1067,
  },
  {
    group: 'fielding',
    apiKey: 'outfielderDistanceToFence',
    sqlKey: '',
    unit: 'FT',
    metricId: 1078,
  },
  {
    group: 'hitting',
    apiKey: 'ballProjDistanceFromFence',
    sqlKey: '',
    unit: 'FT',
    metricId: 1080,
  },
  {
    group: 'running',
    apiKey: 'distanceFromBaseOnThrow',
    sqlKey: '',
    unit: 'FT',
    metricId: 1071,
  },
  // {
  //     key: 'extension',
  //     name: 'Extension',
  //     abbrev: 'Ext',
  //     apiKey: "infieldOopCode",
  //     sqlKey: '',
  //     metricId: 1069
  // },
  {
    apiKey: 'outfieldOopCode',
    sqlKey: '',
    metricId: 1070,
  },
  {
    key: 'r_sprint_speed',
    name: 'Sprint Speed',
    abbrev: 'Sprint',
    group: 'running',
    apiKey: 'sprintSpeed',
    sqlKey: 'r_sprint_speed',
    unit: 'FT/SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1081,
  },
  {
    key: 'f_sprint_speed',
    name: 'Sprint Speed',
    abbrev: 'Sprint',
    group: 'fielding',
    apiKey: 'sprintSpeed',
    sqlKey: 'f_sprint_speed',
    unit: 'FT/SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1081,
  },
  {
    key: 'sprint_speed',
    name: 'Sprint Speed',
    abbrev: 'Sprint',
    group: 'running',
    apiKey: 'sprintSpeed',
    sqlKey: 'r_sprint_speed',
    unit: 'FT/SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1081,
  },
  {
    key: 'sprint_speed',
    name: 'Sprint Speed',
    abbrev: 'Sprint',
    group: 'fielding',
    apiKey: 'sprintSpeed',
    sqlKey: 'f_sprint_speed',
    unit: 'FT/SEC',
    decimalPlaces: 1,
    context: true,
    metricId: 1081,
  },
  {
    key: 'startup_time',
    name: 'Startup Time',
    abbrev: 'Startup Time',
    group: 'fielding, running',
    apiKey: 'startupTime',
    sqlKey: 'f_startup_time',
    unit: 'SEC',
    metricId: 1082,
  },
  {
    apiKey: 'routeToSprintSpeed',
    sqlKey: '',
    metricId: 1099,
  },
  {
    key: 'catch_probability',
    name: 'Catch Probability',
    abbrev: 'Catch Prob',
    group: 'fielding',
    apiKey: 'catchProbability',
    sqlKey: 'f_catch_probability',
    decimalPlaces: 0,
    unit: 'PCT',
    metricId: 17001,
  },
  {
    key: 'hit_probability',
    name: 'Hit Probability',
    abbrev: 'Hit Prob',
    group: 'hitting',
    apiKey: 'hitProbability',
    sqlKey: 'h_hit_probability',
    decimalPlaces: 0,
    unit: 'PCT',
    metricId: 17002,
  },
  {
    key: 'intended_plate_x',
    name: 'Intended Plate X',
    abbrev: 'IPX',
    group: 'pitching',
    apiKey: 'intendedPlateX',
    sqlKey: 'p_intended_plate_x',
    unit: 'FT',
    metricId: 17003,
  },

  // CUSTOM

  {
    key: 'confidence',
    name: 'Confidence',
    abbrev: 'Conf',
    group: 'hitting',
    apiKey: 'confidence',
    sqlKey: 'h_confidence',
    unit: 'String',
  },
  {
    key: 'last_measured_distance',
    name: 'Last Measured Distance',
    abbrev: 'LMD',
    group: 'hitting',
    apiKey: 'last_measured_distance',
    sqlKey: 'h_last_measured_distance',
    decimalPlaces: 0,
    unit: 'FT',
  },
]

const metricKeyToIdMap = {}
const metricIdToKeyMap = {}
const metricMap = {}
const metricIdMap = {}
const metricSqlMap = {}
const metricApiMap = {}

for (let i = 0; i < metrics.length; i++) {
  const m = metrics[i]

  m.u = (m.unit || '')
    .replace(/FT/g, 'ft')
    .replace(/SEC/g, 's')
    .replace(/MPH/g, 'mph')
    .replace(/RPM/g, 'rpm')
    .replace(/DEG/g, '°')
    .replace(/PCT/g, '%')
    .replace(/-/g, '')

  m.format = (value, withUnit) => {
    const _value = value

    if (m.hasOwnProperty('decimalPlaces')) {
      value = parseFloat(value).toFixed(m.decimalPlaces)
    }

    if (isNaN(value)) {
      value = _value
    }

    if (withUnit) {
      if (['°', '%'].includes(m.u)) {
        value += m.u
      } else {
        value += ' ' + m.u
      }
    }

    return value
  }

  metricMap[m.key] = m
  metricIdMap[m.metricId] = m
  metricKeyToIdMap[m.key] = m.metricId
  metricIdToKeyMap[m.metricId] = m.key
  metricSqlMap[m.sqlKey] = m
  metricApiMap[m.apiKey] = m
}

const pitchTypeMap = pitchTypes.reduce((memo, pitch) => {
  pitch.name = pitch.legend
  pitch.code = pitch.value

  memo[pitch.value] = pitch

  return memo
}, {})

const getMetricIdByKey = (key) => metricKeyToIdMap[key]
const getMetricKeyById = (id) => metricIdToKeyMap[id]
const getMetric = (key) => metricMap[key]
const getMetricByKey = (key) => metricMap[key]
const getMetricById = (id) => metricIdMap[id]
const DataKey = require('./DataKey')
const DateFormat = require('./DateFormat')
const SortOrder = require('./SortOrder')
const BrokerType = require('./BrokerType')

module.exports = {
  GameMode,
  GameModeMap,
  DataSource,
  HttpMethod,
  Role,
  OktaRole,
  ApiRole,
  NonGameEventColumn,
  NonGameEventPitchCall,
  NonGameEventPlayResult,
  playerInfoRosterColumns,
  pitchingStatsRosterColumns,
  hittingStatsRosterColumns,
  teamColors,
  pitchHands,
  batSides,
  positions,
  percentiles,
  metrics,
  metricMap,
  metricIdMap,
  metricSqlMap,
  metricApiMap,
  getMetricIdByKey,
  getMetricKeyById,
  getMetric,
  getMetricByKey,
  getMetricById,
  groups,
  pitchTypes,
  pitchTypeMap,
  eventTypes,
  trajectoryTypes,
  sortTypes,
  gameTypes,
  DataKey,
  DateFormat,
  SortOrder,
  BrokerType,
}
