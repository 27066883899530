import ImageLoader from 'components/ui/ImageLoader'
import { debounce, get } from 'lodash'
import { action, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef } from 'react'
import ReactCursorPosition from 'react-cursor-position'

/*
 *   This is getting crazy now...
 *   https://ethanselzer.github.io/react-cursor-position/#/reset
 *   https://github.com/ethanselzer/react-cursor-position/blob/master/example/src/components/Reset.js
 */

const CursorPosition = (props) => {
  const ref = useRef(null)
  useEffect(() => {
    const reset = debounce(
      () => {
        ref.current && ref.current.reset()
      },
      20,
      {
        leading: true,
        trailing: false,
      },
    )

    window.addEventListener('mousemove', reset)

    return () => {
      window.removeEventListener('mousemove', reset)
    }
  }, [])

  return (
    <ReactCursorPosition ref={ref}>
      <ChildContainer>{props.children}</ChildContainer>
    </ReactCursorPosition>
  )
}

const ChildContainer = (props) => {
  const { position = {}, children } = props

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { ...position }),
  )
}

const Hit = inject('store')(
  observer((props) => {
    const { venueId = 'generic' } = props.store.game

    const fieldX = get(
      props.store.edit.play,
      'playEvent.hitLocation.fieldX',
      -10,
    )
    const fieldY = get(
      props.store.edit.play,
      'playEvent.hitLocation.fieldY',
      -10,
    )
    const defaultField =
      'https://prod-gameday.mlbstatic.com/responsive-gameday-assets/1.2.0/images/fields/generic.svg'
    const field = `https://prod-gameday.mlbstatic.com/responsive-gameday-assets/1.2.0/images/fields/${venueId}.svg`

    const clickHandler = action(() => {
      const { x, y } = props

      const play = toJS(props.store.edit.play)

      play.playEvent = play.playEvent || {}
      play.playEvent.hitLocation = play.playEvent.hitLocation || {}
      play.playEvent.hitLocation.fieldX = x
      play.playEvent.hitLocation.fieldY = y

      props.store.edit.setPlay(play)
    })

    return (
      <div style={{ width: '255px', height: '255px' }} onClick={clickHandler}>
        <div style={{ position: 'absolute' }}>
          <ImageLoader width='255' src={field} fallback={defaultField} />
        </div>
        <div style={{ position: 'absolute' }}>
          <svg height='255px' width='255px'>
            <circle
              cx={fieldX}
              cy={fieldY}
              r='6'
              stroke='black'
              strokeWidth='1'
              fill='red'
            />
          </svg>
        </div>
      </div>
    )
  }),
)

export default CursorPosition
