import axios from 'axios'
import { NotificationTypes } from 'constants'
import { range } from 'lodash'
import { action, computed, toJS } from 'mobx'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)

export default class PreGameStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  positionMap = {
    1: 'P',
    2: 'C',
    3: '1B',
    4: '2B',
    5: '3B',
    6: 'SS',
    7: 'LF',
    8: 'CF',
    9: 'RF',
    10: 'DH',
    11: 'PH',
    12: 'PR',
    13: 'EH',
  }

  umpirePositionMap = {
    hp: 'Home Plate',
    '1b': '1st Base',
    '2b': '2nd Base',
    '3b': '3rd Base',
    lf: 'Left Field',
    rf: 'Right Field',
  }

  @computed get gameInfo() {
    const { gameInfo = {} } = this.store.game.stringerData

    return gameInfo
  }

  @computed get gameStateInfo() {
    const { gameStateInfo = {} } = this.store.game.stringerData

    return gameStateInfo
  }

  @computed get gameRosters() {
    const { rosters = {} } = this.store.game
    return rosters
  }

  @computed get nonEventUpdates() {
    const { nonEventUpdates = {} } = this.store.game
    return nonEventUpdates
  }

  @computed get location() {
    let location = null

    if (this.gameInfo.city) {
      location = this.gameInfo.city
    }

    if (this.gameInfo.state) {
      location += `, ${this.gameInfo.state}`
    }

    if (this.gameInfo.country) {
      location += `, ${this.gameInfo.country}`
    }

    return location
  }

  @computed get venue() {
    let venue = this.gameInfo.venueName

    if (venue && this.location) {
      venue = `${venue}, ${this.location}`
    }

    return venue || null
  }

  @computed get date() {
    return this.store.game.gameDate
  }

  @computed get awayTeamName() {
    return this.gameInfo.awayNameDisplayLong || this.gameInfo.awayTeam || 'Away'
  }

  @computed get homeTeamName() {
    return this.gameInfo.homeNameDisplayLong || this.gameInfo.homeTeam || 'Home'
  }

  @computed get awayTeamShortName() {
    return this.gameInfo.awayBrief || 'Away'
  }

  @computed get homeTeamShortName() {
    return this.gameInfo.homeBrief || 'Home'
  }

  @computed get awayStartingLineup() {
    const lineup = range(1, 10).map((i) => ({
      name: 'No Player Entered',
      positionStr: '-',
      id: i,
    }))

    try {
      if (this.gameStateInfo && this.gameStateInfo.awayLineup) {
        var _lineup = toJS(this.gameStateInfo.awayLineup).map((player, i) => {
          if (
            this.nonEventUpdates.awayStarters &&
            this.nonEventUpdates.awayStarters.updates &&
            this.nonEventUpdates.awayStarters.updates.edit
          ) {
            const updateLineup = toJS(
              this.nonEventUpdates.awayStarters.updates.edit.lineup,
            )
            const updatedPlayer = updateLineup[i]
            if (
              updatedPlayer &&
              (updatedPlayer.player != player.id ||
                updatedPlayer.position != player.position)
            ) {
              player.hasChange = true
              player.id = updatedPlayer.player
              player.position = updatedPlayer.position
            }
          }

          return {
            ...player,
            positionStr: this.positionMap[parseInt(player.position)] || '-',
            position: player.position,
            player: player.id,
            name:
              this.store.dware.getPlayerName(parseInt(player.id)) ||
              'No Player Entered',
          }
        })
      }

      Object.assign(lineup, _lineup)
    } catch (e) {
      console.error(e)
    }

    return lineup
  }

  @computed get awayStartingPitcher() {
    return (
      this.awayStartingLineup.find((player) => player.position == 1) || {
        positionStr: '-',
        name: 'No Player Entered',
        id: null,
      }
    )
  }

  @computed get homeStartingLineup() {
    const lineup = range(1, 10).map((i) => ({
      name: 'No Player Entered',
      positionStr: '-',
      id: i,
    }))

    try {
      if (this.gameStateInfo && this.gameStateInfo.homeLineup) {
        var _lineup = toJS(this.gameStateInfo.homeLineup).map((player, i) => {
          if (
            this.nonEventUpdates.homeStarters &&
            this.nonEventUpdates.homeStarters.updates &&
            this.nonEventUpdates.homeStarters.updates.edit
          ) {
            const updateLineup = toJS(
              this.nonEventUpdates.homeStarters.updates.edit.lineup,
            )
            const updatedPlayer = updateLineup[i]
            if (
              updatedPlayer &&
              (updatedPlayer.player != player.id ||
                updatedPlayer.position != player.position)
            ) {
              player.hasChange = true
              player.id = updatedPlayer.player
              player.position = updatedPlayer.position
            }
          }

          return {
            ...player,
            positionStr: this.positionMap[parseInt(player.position)] || '-',
            position: player.position,
            player: player.id,
            hasChange: player.hasChange,
            name:
              this.store.dware.getPlayerName(parseInt(player.id)) ||
              'No Player Entered',
          }
        })
      }

      Object.assign(lineup, _lineup)
    } catch (e) {
      // console.error(e)
    }

    return lineup
  }

  @computed get homeStartingPitcher() {
    return (
      this.homeStartingLineup.find((player) => player.position == 1) || {
        positionStr: '-',
        name: 'No Player Entered',
        id: null,
      }
    )
  }

  @computed get scoreKeepers() {
    try {
      let primaryStringerId = this.infoMap.primaryDatacaster
      let secondaryStringerId = this.infoMap.secondaryDatacaster
      let officialScorerId = this.infoMap.officialScorer
      let primaryStringerHasChange = false
      let secondaryStringerHasChange = false
      let officialScorerHasChange = false
      if (
        this.nonEventUpdates.scoreKeepers &&
        this.nonEventUpdates.scoreKeepers.updates &&
        this.nonEventUpdates.scoreKeepers.updates.edit
      ) {
        primaryStringerId =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .primaryStringer.id
        secondaryStringerId =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .secondaryStringer.id
        officialScorerId =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .officialScorer.id
        primaryStringerHasChange =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .primaryStringer.hasChange
        secondaryStringerHasChange =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .secondaryStringer.hasChange
        officialScorerHasChange =
          this.nonEventUpdates.scoreKeepers.updates.edit.scoreKeepers
            .officialScorer.hasChange
      }
      return {
        primaryStringer: {
          id: primaryStringerId,
          name: this.store.dware.getPlayerName(primaryStringerId) || '',
          hasChange: primaryStringerHasChange,
        },
        secondaryStringer: {
          id: secondaryStringerId,
          name: this.store.dware.getPlayerName(secondaryStringerId) || '',
          hasChange: secondaryStringerHasChange,
        },
        officialScorer: {
          id: officialScorerId,
          name: this.store.dware.getPlayerName(officialScorerId) || '',
          hasChange: officialScorerHasChange,
        },
      }
    } catch (e) {
      return {
        primaryStringer: {
          id: '',
          name: '',
        },
        secondaryStringer: {
          id: '',
          name: '',
        },
        officialScorer: {
          id: '',
          name: '',
        },
      }
    }
  }

  @computed get startingUmpires() {
    const umpires = range(1, 5).map((i) => ({
      name: 'No Umpire Entered',
      pos: '-',
      id: i,
    }))

    try {
      const enteredUmpires = toJS(this.gameStateInfo.umpires)
      const _umpires = range(1, 7).map((num) => {
        const i = num - 1
        const player = enteredUmpires[i] || {
          pos: '-',
          name: 'No Official Entered',
        }
        if (
          this.nonEventUpdates.umpires &&
          this.nonEventUpdates.umpires.updates &&
          this.nonEventUpdates.umpires.updates.edit
        ) {
          const updateLineup = toJS(
            this.nonEventUpdates.umpires.updates.edit.umpires,
          )
          const updatedPlayer = updateLineup[i]
          if (
            updatedPlayer &&
            (updatedPlayer.player != player.id ||
              updatedPlayer.position != player.position)
          ) {
            player.hasChange = true
            player.id = updatedPlayer.player
            player.position = updatedPlayer.position
          }
        }

        return {
          ...player,
          position: player.position,
          pos: this.umpirePositionMap[player.position] || '-',
          player: player.id,
          id: player.id,
          hasChange: player.hasChange,
          full_name: this.store.dware.getPlayerFullNameNoFormat(
            parseInt(player.id),
          ),
          name:
            this.store.dware.getPlayerName(parseInt(player.id)) ||
            'No Umpire Entered',
        }
      })

      Object.assign(umpires, _umpires)
    } catch (e) {
      // console.error(e)
    }

    return umpires.map((umpire) => {
      return {
        ...umpire,
        name: umpire.name ? umpire.name : 'No Umpire Entered',
        pos: umpire.pos || '-',
      }
    })
  }

  @computed get infoMap() {
    const { gameStateInfo = {} } = this.store.game.stringerData

    const map = gameStateInfo.gameInfo

    return map || {}
  }

  @computed get wind() {
    return this.windSpeed
      ? `${this.windSpeed} MPH, ${this.windDirection}`
      : null
  }

  @computed get windSpeed() {
    if (
      this.nonEventUpdates.weather &&
      this.nonEventUpdates.weather.updates &&
      this.nonEventUpdates.weather.updates.edit &&
      this.nonEventUpdates.weather.updates.edit.weather.hasChange
    ) {
      return this.nonEventUpdates.weather.updates.edit.weather.windSpeed
    } else {
      return this.infoMap.windSpeed ? this.infoMap.windSpeed : null
    }
  }

  @computed get windDirection() {
    if (
      this.nonEventUpdates.weather &&
      this.nonEventUpdates.weather.updates &&
      this.nonEventUpdates.weather.updates.edit &&
      this.nonEventUpdates.weather.updates.edit.weather.hasChange
    ) {
      return this.nonEventUpdates.weather.updates.edit.weather.windDirection
    } else {
      return this.infoMap.windDirection ? this.infoMap.windDirection : null
    }
  }

  @computed get sky() {
    if (
      this.nonEventUpdates.weather &&
      this.nonEventUpdates.weather.updates &&
      this.nonEventUpdates.weather.updates.edit &&
      this.nonEventUpdates.weather.updates.edit.weather.hasChange
    ) {
      return this.nonEventUpdates.weather.updates.edit.weather.sky
    } else {
      return this.infoMap.weather ? this.infoMap.weather : null
    }
  }

  @computed get temp() {
    if (
      this.nonEventUpdates.weather &&
      this.nonEventUpdates.weather.updates &&
      this.nonEventUpdates.weather.updates.edit &&
      this.nonEventUpdates.weather.updates.edit.weather.hasChange
    ) {
      return this.nonEventUpdates.weather.updates.edit.weather.temperature
    } else {
      return this.infoMap.temperature ? `${this.infoMap.temperature}` : null
    }
  }

  @computed get weather() {
    return this.sky && this.temp ? `${this.temp}°, ${this.sky}` : null
  }

  @computed get weatherInfo() {
    return {
      sky: this.sky,
      temperature: this.temp,
      windDirection: this.windDirection,
      windSpeed: this.windSpeed,
      hasChange:
        this.nonEventUpdates.weather &&
        this.nonEventUpdates.weather.updates &&
        this.nonEventUpdates.weather.updates.edit &&
        this.nonEventUpdates.weather.updates.edit.weather.hasChange,
    }
  }

  @action refreshRosters() {
    return fetch({
      method: 'POST',
      url: '/api/updates/refreshRosters',
      data: {
        gamePk: this.store.gamePk,
      },
    })
      .then((data) => {
        this.store.notifications.trigger(NotificationTypes.ROSTERS_REFRESHED)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
      .catch((error) => {
        console.error(error)
        this.store.notifications.trigger(
          NotificationTypes.ROSTERS_REFRESH_FAILED,
        )
      })
  }

  initialize() {}
}
