import {
  observable,
  computed,
  action,
  transaction,
  reaction,
  toJS,
  spy,
  extendObservable,
  extras,
} from 'mobx'
import axios, { CancelToken } from 'axios'
import moment from 'moment'
import { camelizeKeys } from 'humps'
import { range } from 'lodash'
import { NotificationTypes } from 'constants'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)

export default class PostGameStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @computed get isLocked() {
    return this.store.game.isFinal ? false : true
  }

  @computed get positions() {
    const { positions = [] } = this.store.game.stringerData

    return positions.map((p) => ({
      value: p.value,
      label: `${p.value} - ${p.label}`,
    }))
  }

  @computed get awayTeamName() {
    return this.gameInfo.awayFull || 'Away'
  }

  @computed get homeTeamName() {
    return this.gameInfo.homeFull || 'Home'
  }

  @computed get awayTeamShortName() {
    return this.gameInfo.awayShort || 'Away'
  }

  @computed get homeTeamShortName() {
    return this.gameInfo.homeShort || 'Home'
  }

  @computed get gameStatusOptions() {
    const { gameStatuses = [] } = this.store.game.stringerData

    const filteredGameStatuses = gameStatuses.filter(
      (status) =>
        status.statusCode[0] == 'R' ||
        status.statusCode[0] == 'F' ||
        status.statusCode == this.gameStatus,
    )

    return filteredGameStatuses.map((status) => ({
      label: status.statusCode + ' - ' + status.detailedState,
      value: status.statusCode,
      detailedState: status.detailedState,
    }))
  }

  formatName = function (id) {
    return this.store.dware.getPlayerName(id)
  }

  @computed get awayRoster() {
    const { awayRoster = [] } = this.store.game.stringerData

    return awayRoster.map((player) => ({
      label: this.formatName(player.id),
      value: player.id,
      nameFirstLast: this.formatName(player.id),
    }))
  }

  @computed get homeRoster() {
    const { homeRoster = [] } = this.store.game.stringerData

    return homeRoster.map((player) => ({
      label: this.formatName(player.id),
      value: player.id,
      nameFirstLast: this.formatName(player.id),
    }))
  }

  // game info map

  @computed get gameInfo() {
    const { gameInfo = {} } = this.store.game.stringerData

    return gameInfo
  }

  // info map created from playstrings of type info
  @computed get infoMap() {
    const { gameStateInfo = {} } = this.store.game.stringerData

    const map = gameStateInfo.gameInfo

    return map || {}
  }

  // option arrays for selects

  @computed get teams() {
    return [
      {
        value: this.gameInfo.awayTeamId,
        label: this.gameInfo.awayFull,
      },
      {
        value: this.gameInfo.homeTeamId,
        label: this.gameInfo.homeFull,
      },
    ]
  }

  getOtherTeamId(id) {
    if (this.gameInfo.awayTeamId == id) {
      return this.gameInfo.homeTeamId
    }

    return this.gameInfo.awayTeamId
  }

  @computed get awayTeamId() {
    return this.gameInfo.awayTeamId || null
  }

  @computed get homeTeamId() {
    return this.gameInfo.homeTeamId || null
  }

  @computed get winningRoster() {
    if (this.winningTeam) {
      if (this.winningTeam == this.homeTeamId) {
        return this.homeRoster
      } else {
        return this.awayRoster
      }
    } else {
      return this.awayRoster.concat(this.homeRoster)
    }
  }

  @computed get losingRoster() {
    if (this.losingTeam) {
      if (this.losingTeam == this.homeTeamId) {
        return this.homeRoster
      } else {
        return this.awayRoster
      }
    } else {
      return this.awayRoster.concat(this.homeRoster)
    }
  }

  @computed get combinedRoster() {
    return this.awayRoster.concat(this.homeRoster)
  }

  // properties

  // decisions

  @computed get gameStatus() {
    return this.infoMap.gameStatusInd
  }

  @computed get winningTeam() {
    return this.infoMap.winTeamId
  }

  @computed get winningPitcher() {
    return this.infoMap.win
  }

  @computed get awayHolds() {
    return this.infoMap.awayHolds || []
  }

  @computed get homeHolds() {
    return this.infoMap.homeHolds || []
  }

  @computed get awayBlownSaves() {
    return this.infoMap.awayBlownSaves || []
  }

  @computed get homeBlownSaves() {
    return this.infoMap.homeBlownSaves || []
  }

  @computed get losingTeam() {
    if (this.winningTeam) {
      return this.getOtherTeamId(this.winningTeam)
    }

    return ''
  }

  @computed get losingPitcher() {
    return this.infoMap.loss
  }

  @computed get savePitcher() {
    return this.infoMap.save
  }

  @action removeHold(idx, teamId) {
    let holdPitchers = []
    if (teamId == this.awayTeamId) {
      holdPitchers = this.infoMap.awayHolds
    } else if (teamId == this.homeTeamId) {
      holdPitchers = this.infoMap.homeHolds
    }
    if (holdPitchers.length > idx) {
      holdPitchers.splice(idx, 1)
    }
  }

  @action updateHold(idx, playerId, teamId) {
    let holdPitchers = []
    if (teamId == this.awayTeamId) {
      holdPitchers = this.infoMap.awayHolds
    } else if (teamId == this.homeTeamId) {
      holdPitchers = this.infoMap.homeHolds
    }
    if (holdPitchers.length > idx) {
      holdPitchers[idx] = playerId
    }
  }

  @action addHold(teamId) {
    let holdPitchers = []
    if (teamId == this.awayTeamId) {
      holdPitchers = this.infoMap.awayHolds
    } else if (teamId == this.homeTeamId) {
      holdPitchers = this.infoMap.homeHolds
    }
    holdPitchers.push('')
  }

  @action removeBlownSave(idx, teamId) {
    let blownSavePitchers = []
    if (teamId == this.awayTeamId) {
      blownSavePitchers = this.infoMap.awayBlownSaves
    } else if (teamId == this.homeTeamId) {
      blownSavePitchers = this.infoMap.homeBlownSaves
    }
    if (blownSavePitchers.length > idx) {
      blownSavePitchers.splice(idx, 1)
    }
  }

  @action updateBlownSave(idx, playerId, teamId) {
    let blownSavePitchers = []
    if (teamId == this.awayTeamId) {
      blownSavePitchers = this.infoMap.awayBlownSaves
    } else if (teamId == this.homeTeamId) {
      blownSavePitchers = this.infoMap.homeBlownSaves
    }
    if (blownSavePitchers.length > idx) {
      blownSavePitchers[idx] = playerId
    }
  }

  @action addBlownSave(teamId) {
    let blownSavePitchers = []
    if (teamId == this.awayTeamId) {
      blownSavePitchers = this.infoMap.awayBlownSaves
    } else if (teamId == this.homeTeamId) {
      blownSavePitchers = this.infoMap.homeBlownSaves
    }
    blownSavePitchers.push('')
  }

  // postgame info

  @computed get attendance() {
    return this.infoMap.attendance || 0
  }

  @computed get timeOfGame() {
    return this.infoMap.gameElapsedMins || 0
  }

  @computed get timeOfGameMinutes() {
    return this.timeOfGame >= 0 ? this.timeOfGame % 60 : 0
  }

  @computed get timeOfGameHours() {
    return this.timeOfGame >= 0 ? Math.floor(this.timeOfGame / 60) : 0
  }

  @computed get timeOfDelay() {
    return this.infoMap.gameDelayMins || 0
  }

  @computed get timeOfDelayMinutes() {
    return this.timeOfDelay >= 0 ? this.timeOfDelay % 60 : ''
  }

  @computed get timeOfDelayHours() {
    return this.timeOfDelay >= 0 ? Math.floor(this.timeOfDelay / 60) : 0
  }

  @computed get startTime() {
    return this.infoMap.startTime
  }

  @computed get startTimeIsValid() {
    const validation = false

    const splits = (this.startTime || '').trim().split(' ')
    if (splits.length != 2) {
      return validation
    }

    const a = splits[0]
    const b = splits[1]

    if (b != 'AM' && b != 'PM') {
      return validation
    }

    const timeSplits = a.split(':')

    if (timeSplits.length != 2) {
      return validation
    }

    const c = timeSplits[0]
    const d = timeSplits[1]
    if (c.length != 2 || d.length != 2) {
      return validation
    }

    const h = parseInt(c)
    const m = parseInt(d)

    if (isNaN(h) || isNaN(m)) {
      return validation
    }

    if (h < 1 || h > 12 || m < 0 || m > 60) {
      return validation
    }

    return true
  }

  @computed get canSavePostGameInfo() {
    return this.startTimeIsValid
  }

  @action savePostGameInfo() {
    const update = {
      atBatIdx: 0,
      playIdx: 0,
      action: 'update',
      type: 'postGameNotes',
      postGameNotes: {
        gameDelayMins: this.infoMap.gameDelayMins,
        gameElapsedMins: this.infoMap.gameElapsedMins,
        attendance: this.infoMap.attendance,
        startTime: this.infoMap.startTime,
        gameStatusInd: this.infoMap.gameStatusInd,
        status: this.infoMap.status,
        winTeamId: this.infoMap.winTeamId,
      },
    }

    this.store.game.nonEventUpdates.postGameNotes = {
      updates: {
        edit: update,
      },
    }
  }

  @action updatePostGameNote(field, value) {
    this.infoMap[field] = value
  }

  @action updateDecision(field, value) {
    this.infoMap[field] = value
  }

  @action saveDecisions() {
    const update = {
      atBatIdx: 0,
      playIdx: 0,
      action: 'update',
      type: 'decisions',
      decisions: {
        win: this.infoMap.win,
        loss: this.infoMap.loss,
        save: this.infoMap.save,
        awayHolds: this.infoMap.awayHolds,
        homeHolds: this.infoMap.homeHolds,
        awayBlownSaves: this.infoMap.awayBlownSaves,
        homeBlownSaves: this.infoMap.homeBlownSaves,
      },
    }

    this.store.game.nonEventUpdates.decisions = {
      updates: {
        edit: update,
      },
    }
  }

  @observable loading = false

  @computed get canSavePreGameInfo() {
    return Object.keys(this._preGameInfo).length ? true : false
  }

  @action updatePreGameInfo(key, value) {
    this._preGameInfo.set(key, value)
  }
  initialize() {}
}
