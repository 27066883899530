import * as React from 'react'
import { MobXProviderContext } from 'mobx-react'

/**
 * @returns {import('stores/AppStore').default} The AppStore
 */
export function useStore() {
  const { store } = React.useContext(MobXProviderContext)
  return store
}
