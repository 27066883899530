import { action, computed, observable } from 'mobx'

export default class StatusStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable gamePk = null
  @observable statusType = null

  @computed get game() {
    if (this.store.isGamePage) {
      return this.store.game.schedule
    }

    let game = this.store.schedule.gameMap[this.gamePk]

    if (!game) {
      game = this.store.schedule.eventMap[this.gamePk]
    }

    return game || null
  }

  @action trigger(gamePk, statusType) {
    if (!statusType || !gamePk) {
      return this.close()
    }

    if (statusType == this.statusType && gamePk == this.gamePk) {
      return this.close()
    }

    this.statusType = statusType
    this.gamePk = gamePk
  }

  @action close() {
    this.gamePk = null
    this.statusType = null
  }

  @computed get showing() {
    return this.game && this.statusType ? true : false
  }

  initialize() {}
}
