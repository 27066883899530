import React from 'react'

export default class ImageLoader extends React.Component {
  state = {
    src: '',
  }

  componentDidMount() {
    this._isMounted = true
    this.update()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src != this.props.src) {
      this.update()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _isMounted = false

  update() {
    this.setState({
      src: this.props.fallback,
    })

    const image = new Image()

    image.onload = () => {
      if (this._isMounted) {
        this.setState({
          src: image.src,
        })
      }
    }

    image.src = this.props.src
  }

  render() {
    return <img {...this.props} {...this.state} />
  }
}
