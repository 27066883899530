/* eslint-disable complexity */
/* eslint-disable no-empty */
import { find, get, range } from 'lodash'
import { action, computed, observable, toJS } from 'mobx'

import cn from 'classnames'
import OperatorChangeReason from 'components/OperatorChangeReason'
import HitLocationEditor from 'components/play/HitLocationEditor'
import PitchEditor from 'components/play/PitchEditor'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import {
  BaseRunningPlayTypeOptions,
  BaseRunningReasonOptions,
  BatterResultPlayTypeOptions,
  EditTypes,
  MovementReasons,
  PlayTypes,
  UNIFORM_ASSET_TYPES,
} from '../constants'

const baseRunningPlayTypes = {}
for (let i = 0; i < BaseRunningPlayTypeOptions.length; i++) {
  const baseRunningPlayType = BaseRunningPlayTypeOptions[i]
  baseRunningPlayTypes[baseRunningPlayType.value] = true
}

function getEventDisplay(displayEvent, primaryFielderCredits) {
  switch (displayEvent) {
    case PlayTypes.SINGLE:
      displayEvent = '1B'
      break
    case PlayTypes.DOUBLE:
      displayEvent = '2B'
      break
    case PlayTypes.TRIPLE:
      displayEvent = '3B'
      break
    case PlayTypes.HOME_RUN:
      displayEvent = 'HR'
      break
    case PlayTypes.FIELDERS_CHOICE:
      displayEvent = 'FC'
      break
    case PlayTypes.ERROR:
    case PlayTypes.ERROR_BASERUNNING:
      displayEvent = 'E'
      break
    case PlayTypes.HIT_BY_PITCH:
      displayEvent = 'HBP'
      break
    case PlayTypes.STRIKEOUT:
      displayEvent = 'K'
      break
    case PlayTypes.WALK:
      displayEvent = 'BB'
      break
    case PlayTypes.INTENTIONAL_WALK:
      displayEvent = 'IBB'
      break
    case PlayTypes.CATCHERS_INTERFERENCE:
      displayEvent = 'CINT'
      break
    case PlayTypes.BATTER_INTERFERENCE:
      displayEvent = 'BINT'
      break
    case PlayTypes.DOUBLE_PLAY:
      displayEvent = 'DP'
      break
    case PlayTypes.TRIPLE_PLAY:
      displayEvent = 'TP'
      break
    case PlayTypes.OS_PENDING:
    case PlayTypes.OS_PENDING_BASERUNNING:
      displayEvent = 'OS'
      break
    case PlayTypes.BATTER_OUT:
      displayEvent = 'OUT'
      break
    case PlayTypes.STOLEN_BASE:
      displayEvent = 'SB'
      break
    case PlayTypes.CAUGHT_STEALING:
      displayEvent = 'CS'
      break
    case PlayTypes.WILD_PITCH:
      displayEvent = 'WP'
      break
    case PlayTypes.PASSED_BALL:
      displayEvent = 'PB'
      break
    case PlayTypes.OTHER_ADVANCE:
      displayEvent = 'OA'
      break
    case PlayTypes.DEFENSIVE_INDIFFERENCE:
      displayEvent = 'DI'
      break
    case PlayTypes.PICKOFF:
      displayEvent = 'PO'
      break
    case PlayTypes.PICKOFF_ERROR:
      displayEvent = 'POE'
      break
    case PlayTypes.PICKOFF_CAUGHT_STEALING:
      displayEvent = 'POCS'
      break
    case PlayTypes.BALK:
      displayEvent = 'BK'
      break
    case PlayTypes.FORCED_BALK:
      displayEvent = 'BKF'
      break
    default:
      break
  }
  return displayEvent
}

function getFielderCreditDisplay(credit) {
  let creditDisplay = ''
  switch (credit) {
    case 'throwing_error':
      creditDisplay = 'TH'
      break
    case 'error':
      creditDisplay = 'E'
      break
    case 'dropped_throw':
      creditDisplay = 'DROP'
      break
    case 'interference':
      creditDisplay = 'INT'
      break
  }
  if (creditDisplay) {
    creditDisplay = ' (' + creditDisplay + ')'
  }
  return creditDisplay
}

function pitchResultPlayerOptions(play, reviewedBy, awayRoster, homeRoster) {
  const playerIds =
    (play.topInningSw === 'Y') === (reviewedBy === 'away_team')
      ? [play.gameState.batter]
      : [play.gameState.fielders[0], play.gameState.fielders[1]]

  return playerIds.map((playerId) => {
    const player = awayRoster
      .concat(homeRoster)
      .find((p) => p.id === Number(playerId))
    return {
      value: player.id,
      label: player.name,
    }
  })
}

export default class EditStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable target = null
  @observable type = null
  @observable _play = {}
  @observable play = {}
  @observable newPlay = {}
  @observable atBatActive = true

  @action toggleAtBat() {
    this.atBatActive = !this.atBatActive
  }

  @action showAtBat() {
    this.atBatActive = true
  }

  @action close() {
    this.target = null
    this.type = null

    setTimeout(() => {
      if (!this.store.updates.updates.length) {
        this.store.game.enterLiveMode()
      }
    }, 500)
  }

  @action addAtBatStartEvent(subsequentPlay) {
    this.play = {
      type: 'batter',
      atBatIdx: subsequentPlay.atBatIdx,
      playIdx: subsequentPlay.playIdx,
      _playIdx: subsequentPlay.playIdx,
      playByPlay: '',
      runnersGoing: false,
      reviews: [],
      violations: [],
    }
    this.type = EditTypes.ADD_AT_BAT_START
    this.confirm()
  }

  @action trigger(target, type, play) {
    if (this.target == target && this.type == type) {
      return this.close()
    }

    this.store.game.exitLiveMode()

    if (target) {
      this.target = target
    }

    if (type) {
      this.type = type
    }

    if (play) {
      const _play = toJS(play)
      _play.reviews = toJS(play.reviews)
      _play.violations = toJS(play.violations)
      _play.playEvent = toJS(play.playEvent)

      this._play = _play
      this.play = _play

      if (play.subType && type == EditTypes.ADD_SUB) {
        this.addSub(play.subType)
      } else if (play.subType && type == EditTypes.EDIT_SUB) {
        this.editSub()
      } else if (type == EditTypes.EDIT_STARTERS) {
        this.addSub(play.subType, play.lineup)
      }
    }
  }

  @action setPlay(play) {
    this.play = play
  }

  @computed get weatherConditions() {
    return this.store.game.stringerData.weather || []
  }

  @computed get windDirections() {
    return this.store.game.stringerData.windDirection || []
  }

  @computed get pitchCodes() {
    let codes = []
    if (this.store.game.stringerData) {
      codes = this.store.game.stringerData.pitchCodes.map((code) => {
        return {
          value: code.value,
          label: `${code.value} - ${code.label}`,
          pbp: code.label,
        }
      })
    }

    return codes
  }

  @computed get reviewReasons() {
    let reasons = []
    if (this.store.game.stringerData) {
      reasons = this.store.game.stringerData.reviewReason || []
    }

    return reasons
  }

  @computed get reviewedByOptions() {
    const awayAbbrev = get(
      this.store.game,
      'stringerData.gameInfo.awayAbbrev',
      'AWAY',
    )
    const homeAbbrev = get(
      this.store.game,
      'stringerData.gameInfo.homeAbbrev',
      'HOME',
    )
    return [
      {
        value: 'away_team',
        label: awayAbbrev,
      },
      {
        value: 'home_team',
        label: homeAbbrev,
      },
      {
        value: 'umpire',
        label: 'UMPIRE',
      },
    ]
  }

  @computed get reviewedByOptionsNoUmp() {
    const awayAbbrev = get(
      this.store.game,
      'stringerData.gameInfo.awayAbbrev',
      'AWAY',
    )
    const homeAbbrev = get(
      this.store.game,
      'stringerData.gameInfo.homeAbbrev',
      'HOME',
    )
    return [
      {
        value: 'away_team',
        label: awayAbbrev,
      },
      {
        value: 'home_team',
        label: homeAbbrev,
      },
    ]
  }

  @computed get playerOptions() {
    const roster =
      this.play.topInningSw === 'Y'
        ? get(this.store.game, 'stringerData.homeRoster')
        : get(this.store.game, 'stringerData.awayRoster')
    return roster.map((player) => {
      return {
        value: player.id,
        label: player.name,
      }
    })
  }

  runnerBases = [
    {
      value: 'B',
      label: 'Batter',
    },
    {
      value: '1',
      label: '1st Base',
    },
    {
      value: '2',
      label: '2nd Base',
    },
    {
      value: '3',
      label: '3rd Base',
    },
    {
      value: 'H',
      label: 'Home',
    },
  ]

  runnerPlacementBases = [
    {
      value: '1',
      label: '1st Base',
    },
    {
      value: '2',
      label: '2nd Base',
    },
    {
      value: '3',
      label: '3rd Base',
    },
  ]

  safeOutOption = [
    {
      value: false,
      label: 'Safe',
    },
    {
      value: true,
      label: 'Out',
    },
  ]

  rbiOption = [
    {
      value: false,
      label: 'RBI',
    },
    {
      value: true,
      label: 'No RBI',
    },
  ]

  earnedRunOption = [
    {
      value: '',
      label: 'Earned Run',
    },
    {
      value: 'UR',
      label: 'Unearned Run',
    },
    {
      value: 'TUR',
      label: 'Team Unearned Run',
    },
  ]

  hitLocationOption = [
    {
      value: '7',
      label: 'Left Field',
    },
    {
      value: '78',
      label: 'Left-Center Field',
    },
    {
      value: '8',
      label: 'Center Field',
    },
    {
      value: '89',
      label: 'Right-Center Field',
    },
    {
      value: '9',
      label: 'Right Field',
    },
  ]

  fielderCredits = [
    {
      value: 'assist',
      label: 'Assist',
    },
    {
      value: 'put_out',
      label: 'Put Out',
    },
    {
      value: 'throwing_error',
      label: 'Throwing Error',
    },
    {
      value: 'error',
      label: 'Fielding Error',
    },
    {
      value: 'droppped_throw',
      label: 'Dropped Throw',
    },
    {
      value: 'interference',
      label: 'Interference',
    },
    {
      value: 'deflection',
      label: 'Deflection',
    },
    {
      value: 'fielded_ball',
      label: 'Fielded Ball',
    },
  ]

  reviewStatuses = [
    {
      value: true,
      label: 'In Progress',
    },
    {
      value: false,
      label: 'Complete',
    },
  ]

  reviewDecisions = [
    {
      value: false,
      label: 'Stands',
    },
    {
      value: true,
      label: 'Overturned',
    },
  ]

  violationTypes = [
    {
      value: 6,
      label: 'Lateral Positioning Violation',
      id: 6,
    },
    {
      value: 7,
      label: 'Depth Violation',
      id: 7,
    },
    {
      value: 8,
      label: 'Switching Sides Violation',
      id: 8,
    },
  ]

  @computed get teams() {
    const { stringerData = {} } = this.store.game

    const { gameInfo = {} } = stringerData

    const { edit, awayAbbrev, awayNameAbbrev, homeAbbrev, homeNameAbbrev } =
      gameInfo

    const awayName = awayAbbrev || awayNameAbbrev || 'Away'
    const homeName = homeAbbrev || homeNameAbbrev || 'Home'

    return [
      {
        value: 0,
        label: awayName,
      },
      {
        value: 1,
        label: homeName,
      },
    ]
  }

  battingPositions = range(0, 10).map((n) => ({
    value: n.toString(),
    label: n.toString(),
  }))

  @computed get homeTeamId() {
    return get(this.store.game, 'stringerData.gameInfo.homeTeamId')
  }

  @computed get awayTeamId() {
    console.log(
      'awayTeamId',
      get(this.store.game, 'stringerData.gameInfo.awayTeamId'),
    )
    return get(this.store.game, 'stringerData.gameInfo.awayTeamId')
  }

  @computed get homePlayers() {
    return this.store.game.stringerData.homeRoster.map((p) => {
      return {
        ...p,
        value: p.id.toString(),
        label: this.store.dware.formatName(p.name, p.id),
      }
    })
  }

  @computed get awayPlayers() {
    return this.store.game.stringerData.awayRoster.map((p) => {
      return {
        ...p,
        value: p.id.toString(),
        label: this.store.dware.formatName(p.name, p.id),
      }
    })
  }

  @computed get players() {
    const team = this.play.team >= 0 ? this.play.team : this.play.subTeam

    return team == 0 ? this.awayPlayers : this.homePlayers
  }

  @computed get positions() {
    return this.store.game.stringerData.positions.map((pos) => {
      const { label, value } = pos

      return {
        label: `${value} - ${label}`,
        value,
      }
    })
  }

  @computed get fielderPositions() {
    return this.store.game.stringerData.positions
      .filter((position) => {
        const pos = parseInt(position.value)
        return pos <= 9
      })
      .map((pos) => {
        const { label, value } = pos

        return {
          label: `${value} - ${label}`,
          value,
        }
      })
  }

  @computed get stringers() {
    return (this.store.game.stringerData.stringers || []).map((p) => {
      return {
        id: p.id.toString(),
        name: this.store.dware.formatName(p.name, p.id),
      }
    })
  }

  @computed get scorers() {
    return (this.store.game.stringerData.scorers || []).map((p) => {
      return {
        id: p.id.toString(),
        name: this.store.dware.formatName(p.name, p.id),
      }
    })
  }

  @computed get defensePositions() {
    const defense = []
    const allPositions = this.store.game.stringerData.positions
    for (let i = 0; i < allPositions.length; i++) {
      const position = allPositions[i]
      if (parseInt(position.value) > 10 && parseInt(position.value) != 13) {
        continue
      }
      defense.push(position)
    }
    return defense.map((pos) => {
      const { label, value } = pos

      return {
        label: `${value} - ${label}`,
        value,
      }
    })
  }

  flags = [
    {
      value: 'FINT',
      label: 'Fan Interference',
    },
    {
      value: 'BINT',
      label: 'Batter Interference',
    },
    {
      value: 'GR',
      label: 'Ground-Rule Double',
    },
    {
      value: 'ITP',
      label: 'Inside-The-Park Home Run',
    },
    {
      value: 'SAC',
      label: 'Sacrifice',
    },
    {
      value: 'SF',
      label: 'Sac Fly',
    },
    {
      value: 'IF',
      label: 'Infield Fly',
    },
  ]

  batSideOptions = [
    {
      value: 'L',
      label: 'Left-Handed',
    },
    {
      value: 'R',
      label: 'Right-Handed',
    },
  ]

  pitchHandOptions = [
    {
      value: 'L',
      label: 'Left-Handed',
    },
    {
      value: 'R',
      label: 'Right-Handed',
    },
  ]

  moundVisitOptions = [
    {
      value: 'Generic',
      label: 'Generic',
    },
    {
      value: 'Coach',
      label: 'Coach',
    },
    {
      value: 'Player',
      label: 'Player',
    },
    {
      value: 'Manager',
      label: 'Manager',
    },
  ]

  @computed get commentaryOptions() {
    const sportId = this.store.game.homeTeamSportId
    return [
      'Coaching visit to mound.',
      'On-field Delay.',
      'Injury Delay.',
      [11, 12, 13, 14].includes(sportId) && 'Pitcher step-off.',
      sportId === 12 && 'Defensive shift violation.',
      sportId === 13 && 'Step-off violation.',
      sportId === 14 && 'Pickoffs violation.',
    ]
      .filter(Boolean)
      .map((n) => ({
        value: n,
        label: n,
      }))
  }

  // const rosters = this.store.rosters.map(r => toJS(r));
  // const coaches = this.store.coaches.map(r => toJS(r));
  // const umpires = this.store.umpires.map(r => toJS(r));
  // const gameUmpires = this.store.gameUmpires.map(r => toJS(r));
  // const injuries = this.store.injuries;
  // const umpirePositions = this.store.umpirePositions;
  // const runnerBases = this.store.runnerBases;

  @computed get umpires() {
    const { stringerData = {} } = this.store.game

    const { umpire = [] } = stringerData

    return umpire.map((p) => ({
      value: p.id,
      label: this.store.dware.formatName(p.name, p.id),
    }))
  }

  @computed get umpirePositions() {
    const positions = this.store.dware.getUmpirePositions()

    return positions
  }

  @computed get umpirePositionCodes() {
    const positions = this.store.dware.getUmpirePositionCodes()

    return positions
  }

  @computed get gameUmpires() {
    const { stringerData = {} } = this.store.game

    const umps = this.store.dware.getGameUmpires(stringerData)

    return umps.map((p) => ({
      value: p.id,
      label: p.fullName,
    }))
  }

  @computed get awayCoaches() {
    const { stringerData = {} } = this.store.game

    const { awayCoaches = [] } = stringerData

    return awayCoaches.map((p) => ({
      value: p.id,
      label: this.store.dware.formatName(p.name, p.id),
    }))
  }

  @computed get homeCoaches() {
    const { stringerData = {} } = this.store.game

    const { homeCoaches = [] } = stringerData

    return homeCoaches.map((p) => ({
      value: p.id,
      label: this.store.dware.formatName(p.name, p.id),
    }))
  }

  @computed get coaches() {
    return this.play.team == 0 ? this.awayCoaches : this.homeCoaches
  }

  @computed get injuries() {
    const injuries = this.store.dware.getInjury()

    return injuries
  }

  @computed get props() {
    return {
      preferPlace: 'row',
      tipSize: 0.01,
      refreshIntervalMs: 20,
      enterExitTransitionDurationMs: 100,
      onOuterAction: (e) => {},
      body: this.popover,
    }
  }

  @computed get title() {
    // return this.type ? this.type.replace(/_/g, ' ').toLowerCase() : null
    switch (this.type) {
      case EditTypes.MODIFY_PLAY:
        return 'Modify Event'
      case EditTypes.MODIFY_EVENT:
        return 'Modify Event'
      case EditTypes.ADD_EVENT:
        return 'Add Event Before'
      case EditTypes.EDIT_PLAY_TABLE:
        return 'Edit Play'
      case EditTypes.ADD_PLAY:
        return 'Add Play Before'
      case EditTypes.ADD_NON_PITCH_EVENT:
        return 'Add Non-pitch Before'
      case EditTypes.ADD_PITCH:
        return 'Add Pitch Before'
      case EditTypes.EDIT_PITCH:
        return 'Edit Pitch'
      case EditTypes.DELETE_EVENT:
        return 'Delete Event'
      case EditTypes.EDIT_REVIEWS:
        return 'Edit Reviews'
      case EditTypes.EDIT_VIOLATIONS:
        return 'Edit Violations'
      case EditTypes.MODIFY_DEFENSIVE_SHIFT_VIOLATION:
        return 'Modify Violation'
      case EditTypes.EDIT_PLAY:
        return 'Edit Play'
      case EditTypes.EDIT_FLAGS:
        return 'Edit Flags'
      case EditTypes.EDIT_TRAJECTORY:
        return 'Edit Trajectory'
      case EditTypes.EDIT_FIELDERS:
        return 'Edit Fielders'
      case EditTypes.EDIT_RUNNERS:
        return 'Edit Runner Movements'
      case EditTypes.EDIT_RUNNER_MOVEMENT:
        return 'Edit Runner Movement Reason'
      case EditTypes.MODIFY_SUB:
        return 'Modify Sub'
      case EditTypes.QUICK_SUB:
        return 'Substitution'
      case EditTypes.ADD_SUB:
        return 'Add Sub Before'
      case EditTypes.EDIT_STARTERS:
        return 'Edit Starting Lineup'
      case EditTypes.EDIT_UMPIRES:
        return 'Edit Starting Umpires'
      case EditTypes.EDIT_SCOREKEEPERS:
        return 'Edit Scorekeepers'
      case EditTypes.EDIT_WEATHER:
        return 'Edit Weather'
      case EditTypes.EDIT_SUB:
        return 'Edit Sub'
      case EditTypes.DELETE_SUB:
        return 'Delete Sub'
      case EditTypes.MODIFY_BADJ:
        return 'Modify Bat Side Switch'
      case EditTypes.MODIFY_COM:
        return 'Modify Commentary'
      case EditTypes.MODIFY_EJECT:
        return 'Modify Ejection'
      case EditTypes.MODIFY_INJURY:
        return 'Modify Injury'
      case EditTypes.MODIFY_PADJ:
        return 'Modify Pitch Hand Switch'
      case EditTypes.MODIFY_RUNNER:
        return 'Modify Runner Placement'
      case EditTypes.MODIFY_UMPSUB:
        return 'Modify Umpire Sub'
      case EditTypes.MODIFY_LINEUP_ADJUST:
        return 'Modify Batted Out Of Order'
      case EditTypes.ADD_BADJ:
        return 'Add Bat Side Switch'
      case EditTypes.ADD_COM:
        return 'Add Commentary'
      case EditTypes.ADD_EJECT:
        return 'Add Ejection'
      case EditTypes.ADD_INJURY:
        return 'Add Injury'
      case EditTypes.ADD_PADJ:
        return 'Add Pitch Hand Switch'
      case EditTypes.ADD_RUNNER:
        return 'Add Runner Placement'
      case EditTypes.ADD_UMPSUB:
        return 'Add Umpire Sub'
      case EditTypes.ADD_LINEUP_ADJUST:
        return 'Add Batted Out Of Order'
      case EditTypes.ADD_MOUND_VISIT:
        return 'Add Mound Visit'
      case EditTypes.EDIT_MOUND_VISIT:
        return 'Edit Mound Visit'
      case EditTypes.ADD_BATTER_TIMEOUT:
        return 'Add Batter Timeout'
      case EditTypes.MODIFY_MOUND_VISIT:
        return 'Modify Mound Visit'
      case EditTypes.MODIFY_BATTER_TIMEOUT:
        return 'Modify Batter Timeout'
      case EditTypes.EDIT_UNIFORMS:
        return 'Edit Uniforms'
      default:
        return ''
    }
  }

  @computed get content() {
    switch (this.type) {
      case EditTypes.DELETE_SUB:
      case EditTypes.DELETE_EVENT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            Are you sure?
          </div>
        )
      case EditTypes.MODIFY_PLAY:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.EDIT_PLAY)
              }}
            >
              Edit Play
            </button>
            {this.play.type != 'result' ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.trigger(null, EditTypes.DELETE_EVENT)
                }}
              >
                Delete Play
              </button>
            ) : null}
          </div>
        )
      case EditTypes.MODIFY_EVENT:
        var eventType = this.play.type

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            {eventType != 'batter' ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.trigger(null, EditTypes.ADD_EVENT)
                }}
              >
                Add
              </button>
            ) : null}
            {eventType == 'pitch' ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.trigger(null, EditTypes.EDIT_PITCH)
                }}
              >
                Edit
              </button>
            ) : null}
            {eventType == 'CV' ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.trigger(null, EditTypes.EDIT_MOUND_VISIT)
                }}
              >
                Edit
              </button>
            ) : null}
            {eventType != 'sub' ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.trigger(null, EditTypes.DELETE_EVENT)
                }}
              >
                Delete
              </button>
            ) : null}
          </div>
        )
      case EditTypes.ADD_NON_PITCH_EVENT:
        return (
          <div className='card-content'>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addBadj()
                this.trigger(null, EditTypes.ADD_BADJ)
              }}
            >
              Bat Side Switch
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addCom()
                this.trigger(null, EditTypes.ADD_COM)
              }}
            >
              Commentary
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addEject()
                this.trigger(null, EditTypes.ADD_EJECT)
              }}
            >
              Ejection
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addInjury()
                this.trigger(null, EditTypes.ADD_INJURY)
              }}
            >
              Injury
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addPadj()
                this.trigger(null, EditTypes.ADD_PADJ)
              }}
            >
              Pitch Hand Switch
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addRunner()
                this.trigger(null, EditTypes.ADD_RUNNER)
              }}
            >
              Runner Placement
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addUmpSub()
                this.trigger(null, EditTypes.ADD_UMPSUB)
              }}
            >
              Umpire Substitution
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addLineupAdjust()
                this.trigger(null, EditTypes.ADD_LINEUP_ADJUST)
              }}
            >
              Battted Out Of Order
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addMoundVisit()
                this.trigger(null, EditTypes.ADD_MOUND_VISIT)
              }}
            >
              Mound Visit
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addBatterTimeout()
                this.trigger(null, EditTypes.ADD_BATTER_TIMEOUT)
              }}
            >
              Batter Timeout
            </button>
          </div>
        )
      case EditTypes.ADD_BADJ:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Bat Side</label>
              <Select
                className='is-small'
                options={this.batSideOptions}
                value={this.play.batSide}
                onChange={(v) => {
                  this.updatePlay('batSide', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_MOUND_VISIT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Mound Visit Type</label>
              <Select
                className='is-small'
                options={this.moundVisitOptions}
                value={this.play.moundVisit}
                onChange={(v) => {
                  this.updatePlay('moundVisit', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.EDIT_MOUND_VISIT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Mound Visit Type</label>
              <Select
                className='is-small'
                options={this.moundVisitOptions}
                value={this.play.moundVisit}
                onChange={(v) => {
                  this.updatePlay('moundVisit', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_PADJ:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Pitch Hand</label>
              <Select
                className='is-small'
                options={this.batSideOptions}
                value={this.play.pitchHand}
                onChange={(v) => {
                  this.updatePlay('pitchHand', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_COM:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Commentary</label>
              <Select
                className='is-small'
                options={this.commentaryOptions}
                value={this.play.com}
                onChange={(v) => {
                  this.updatePlay('com', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_EJECT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Umpire</label>
              <Select
                className='is-small'
                options={this.gameUmpires}
                value={this.play.umpire}
                onChange={(v) => {
                  this.updatePlay('umpire', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Team</label>
              <Select
                className='is-small'
                options={this.teams}
                value={this.play.team}
                onChange={(v) => {
                  this.updatePlay('team', v)
                  this.updatePlay('coach', null)
                  this.updatePlay('player', null)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Player</label>
              <Select
                className='is-small'
                options={this.players}
                value={this.play.player}
                onChange={(v) => {
                  this.updatePlay('player', v)
                  this.updatePlay('coach', null)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Coach</label>
              <Select
                className='is-small'
                options={this.coaches}
                value={this.play.coach}
                onChange={(v) => {
                  this.updatePlay('coach', v)
                  this.updatePlay('player', null)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_INJURY:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Team</label>
              <Select
                className='is-small'
                options={this.teams}
                value={this.play.team}
                onChange={(v) => {
                  this.updatePlay('team', v)
                  this.updatePlay('player', null)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Player</label>
              <Select
                className='is-small'
                options={this.players}
                value={this.play.player}
                onChange={(v) => {
                  this.updatePlay('player', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Injury</label>
              <Select
                className='is-small'
                options={this.injuries}
                value={this.play.injury}
                onChange={(v) => {
                  this.updatePlay('injury', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_RUNNER:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Team</label>
              <Select
                className='is-small'
                options={this.teams}
                value={this.play.team}
                onChange={(v) => {
                  this.updatePlay('team', v)
                  this.updatePlay(
                    'player',
                    this.players.length ? this.players[0].id : null,
                  )
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Player</label>
              <Select
                className='is-small'
                options={this.players}
                value={this.play.player}
                onChange={(v) => {
                  this.updatePlay('player', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Base</label>
              <Select
                className='is-small'
                options={this.runnerPlacementBases}
                value={this.play.base}
                onChange={(v) => {
                  this.updatePlay('base', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_UMPSUB:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Umpire</label>
              <Select
                className='is-small'
                options={this.umpires}
                value={this.play.umpire}
                onChange={(v) => {
                  this.updatePlay('umpire', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
            <div className='field'>
              <label className='label'>Position</label>
              <Select
                className='is-small'
                options={this.umpirePositions}
                value={this.play.position}
                onChange={(v) => {
                  this.updatePlay('position', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.ADD_LINEUP_ADJUST:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field'>
              <label className='label'>Select Who Batted Out of Order</label>
              <Select
                className='is-small'
                options={this.play.lineupPlayers}
                value={this.play.batPos}
                onChange={(v) => {
                  this.updatePlay('batPos', v)
                }}
                simpleValue
                clearable={false}
              />
            </div>
          </div>
        )
      case EditTypes.MODIFY_BADJ:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Bat Side Switch
            </button>
          </div>
        )
      case EditTypes.MODIFY_COM:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Commentary
            </button>
          </div>
        )
      case EditTypes.MODIFY_BATTER_TIMEOUT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Batter Timeout
            </button>
          </div>
        )
      case EditTypes.MODIFY_MOUND_VISIT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Mound Visit
            </button>
          </div>
        )
      case EditTypes.MODIFY_EJECT:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Ejection
            </button>
          </div>
        )
      case EditTypes.MODIFY_INJURY:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Injury
            </button>
          </div>
        )
      case EditTypes.MODIFY_PADJ:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Pitch Hand Switch
            </button>
          </div>
        )
      case EditTypes.MODIFY_RUNNER:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Runner Placement
            </button>
          </div>
        )
      case EditTypes.MODIFY_UMPSUB:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Umpire Sub
            </button>
          </div>
        )

      case EditTypes.MODIFY_LINEUP_ADJUST:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Batted Out Of Order
            </button>
          </div>
        )

      case EditTypes.EDIT_PLAY_TABLE:
        var event = this.play
        var atBatNumber = event.atBatIdx + 1
        var numReviews = ''
        if (event.reviews && event.reviews.length > 0) {
          numReviews = ' (' + event.reviews.length + ')'
        }
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <h1>
              <b>
                At Bat {atBatNumber} | {event.playByPlay}
              </b>
              <br />
            </h1>
            <table className='table mb-0 is-bordered is-narrow is-fullwidth'>
              <thead>
                <tr
                  key={
                    'result-header-baserunner-' +
                    event.atBatIdx +
                    '-' +
                    event.playIdx
                  }
                >
                  <th>Pitches</th>
                  <th>Result</th>
                  <th>Traj.</th>
                  <th>Fielders</th>
                  <th>3rd</th>
                  <th>2nd</th>
                  <th>1st</th>
                  <th>Batter</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  key={
                    'result-row-baserunner-' +
                    event.atBatIdx +
                    '-' +
                    event.playIdx
                  }
                  className={cn({
                    'is-info': event.isInserted,
                    'is-success': event.isEdited,
                    'is-danger': event.isDeleted,
                  })}
                >
                  <td>{event.pitchSequence}</td>
                  <td>
                    <ResultButton event={event} trigger={this} />
                  </td>
                  <td>
                    <TrajectoryButton event={event} trigger={this} />
                  </td>
                  <td>
                    <FieldersButton event={event} trigger={this} />
                  </td>
                  <td>
                    <BaseButton base='3' event={event} trigger={this} />
                  </td>
                  <td>
                    <BaseButton base='2' event={event} trigger={this} />
                  </td>
                  <td>
                    <BaseButton base='1' event={event} trigger={this} />
                  </td>
                  <td>
                    <BaseButton base='B' event={event} trigger={this} />
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              className='button is-small mt-3'
              onClick={(e) => {
                this.trigger(null, EditTypes.EDIT_REVIEWS, null)
              }}
            >
              Reviews {numReviews}
            </button>
          </div>
        )

      case EditTypes.ADD_EVENT:
        var hasRunnersOn = false
        if (this.play.gameState && this.play.gameState.runners) {
          for (var i = 0; i < this.play.gameState.runners.length; i++) {
            if (this.play.gameState.runners[i]) {
              hasRunnersOn = true
              break
            }
          }
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              onClick={(e) => {
                this.addPitch()
                this.trigger(null, EditTypes.ADD_PITCH)
              }}
              className='button is-fullwidth mb-1'
            >
              Pitch
            </button>
            <button
              onClick={(e) => {
                this.addSub('defense')
                this.trigger(null, EditTypes.ADD_SUB)
              }}
              className='button is-fullwidth mb-1'
            >
              Defensive Sub
            </button>
            <button
              onClick={(e) => {
                this.addSub('pinchHitter')
                this.trigger(null, EditTypes.ADD_SUB)
              }}
              className='button is-fullwidth mb-1'
            >
              Pinch Hitter
            </button>
            {hasRunnersOn ? (
              <button
                onClick={(e) => {
                  this.addSub('pinchRunner')
                  this.trigger(null, EditTypes.ADD_SUB)
                }}
                className='button is-fullwidth mb-1'
              >
                Pinch Runner
              </button>
            ) : null}
            <button
              onClick={(e) => {
                this.addPlay()
                this.trigger(null, EditTypes.ADD_PLAY)
              }}
              className='button is-fullwidth mb-1'
            >
              Play
            </button>
            <button
              onClick={(e) => {
                this.trigger(null, EditTypes.ADD_NON_PITCH_EVENT)
              }}
              className='button is-fullwidth mb-1'
            >
              Non-Pitch Event
            </button>
          </div>
        )
      case EditTypes.ADD_PITCH:
      case EditTypes.EDIT_PITCH:
        var numReviews = ''
        if (this.play.reviews && this.play.reviews.length > 0) {
          numReviews = ' (' + this.play.reviews.length + ')'
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <Select
              className='is-small'
              clearable={false}
              autoFocus
              matchPos='start'
              options={this.pitchCodes.slice()}
              value={this.play.pitchCode}
              onChange={(option) => {
                this.play.playByPlay = option.pbp
                this.play.pitchCode = option.value
              }}
            />
            <div style={{ minWidth: 400 }}>
              <label className='checkbox mt-3'>
                <input
                  type='checkbox'
                  checked={this.play.runnersGoing}
                  onChange={(e) => {
                    this.play.runnersGoing = !this.play.runnersGoing
                  }}
                />{' '}
                Runners Going
              </label>
            </div>
            {this.type == EditTypes.EDIT_PITCH ? (
              <button
                className='button is-small mt-3'
                onClick={(e) => {
                  this.trigger(null, EditTypes.EDIT_REVIEWS, null)
                }}
              >
                Reviews {numReviews}
              </button>
            ) : null}
            <div>
              <PitchEditor play={this.play} />
            </div>
            <div className='mt-3'>
              <OperatorChangeReason operatorChangeType={this.type} />
            </div>
          </div>
        )

      case EditTypes.EDIT_REVIEWS:
        return (
          <table
            className='table mb-0 is-narrow is-bordered is-fullwidth'
            style={{ width: 1000 }}
          >
            <thead>
              <tr>
                <th
                  style={{ width: 40, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  <button
                    className='button is-small is-success'
                    onClick={(e) => this.addReview()}
                  >
                    <span className='icon'>
                      <i className='fa fa-plus' />
                    </span>
                  </button>
                </th>
                <th
                  style={{ width: 160, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Reviewed By
                </th>
                <th
                  style={{ width: 250, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Reason
                </th>
                <th
                  style={{ width: 250, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Player
                </th>
                <th
                  style={{ width: 160, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Status
                </th>
                <th
                  style={{ width: 160, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Decision
                </th>
                <th
                  style={{ width: 170, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Overturned Code
                </th>
                <th
                  style={{ width: 60, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.play.reviews.map((review, i) => {
                let {
                  inProgress = false,
                  playerId,
                  overturned,
                  reviewedBy,
                } = review

                if (!overturned) {
                  overturned = false
                }

                const reason = review.reviewStatus
                  .replace(/^M/, '')
                  .replace(/^N/, '')

                let playerOptions
                if (reason === 'J') {
                  const awayRoster = get(
                    this.store.game,
                    'stringerData.awayRoster',
                  )
                  const homeRoster = get(
                    this.store.game,
                    'stringerData.homeRoster',
                  )
                  playerOptions = pitchResultPlayerOptions(
                    this.play,
                    reviewedBy,
                    awayRoster,
                    homeRoster,
                  )
                }

                return (
                  <tr key={i}>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <span className='icon'>
                        <i className='fa fa-arrows' />
                      </span>
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={
                          reason !== 'J'
                            ? this.reviewedByOptions.slice()
                            : this.reviewedByOptionsNoUmp.slice()
                        }
                        value={reviewedBy}
                        onChange={(v) => {
                          this.updateReview(i, 'reviewedBy', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.reviewReasons.slice()}
                        value={reason}
                        onChange={(v) => {
                          this.updateReview(i, 'reviewStatus', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={playerOptions}
                        value={playerId}
                        onChange={(v) => {
                          this.updateReview(i, 'playerId', v)
                        }}
                        simpleValue
                        clearable={false}
                        disabled={!playerOptions}
                      />
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.reviewStatuses}
                        value={inProgress}
                        onChange={(v) => {
                          this.updateReview(i, 'inProgress', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.reviewDecisions}
                        value={overturned}
                        onChange={(v) => {
                          this.updateReview(i, 'overturned', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td>
                      <input
                        className='input'
                        value={review.overturnedCode}
                        onChange={(e) => {
                          const val = e.target.value.trim().toUpperCase()
                          this.updateReview(i, 'overturnedCode', val)
                        }}
                      />
                    </td>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button
                        className='button is-danger is-small'
                        onClick={(e) => this.deleteReview(review.idx)}
                      >
                        <span className='icon'>
                          <i className='fa fa-times' />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      case EditTypes.EDIT_VIOLATIONS:
        return (
          <table
            className='table mb-0 is-narrow is-bordered is-fullwidth'
            style={{ width: 1000 }}
          >
            <thead>
              <tr>
                <th
                  style={{ width: 40, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  <button
                    className='button is-small is-success'
                    onClick={(e) => this.addViolation()}
                  >
                    <span className='icon'>
                      <i className='fa fa-plus' />
                    </span>
                  </button>
                </th>
                <th
                  style={{ width: 160, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Team
                </th>
                <th
                  style={{ width: 250, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Player
                </th>
                <th
                  style={{ width: 160, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Violation Type
                </th>
                <th
                  style={{ width: 60, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.play.violations.map((violation, i) => {
                const { playerId, violationTypeId } = violation

                return (
                  <tr key={i}>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <span className='icon'>
                        <i className='fa fa-arrows' />
                      </span>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <span>
                        {get(
                          this.store.game,
                          this.play.topInningSw === 'Y'
                            ? 'stringerData.gameInfo.homeAbbrev'
                            : 'stringerData.gameInfo.awayAbbrev',
                        )}
                      </span>
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.playerOptions}
                        value={playerId}
                        onChange={(v) => {
                          this.updateViolation(i, 'playerId', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.violationTypes}
                        value={violationTypeId}
                        onChange={(v) => {
                          this.updateViolation(i, 'violationType', v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button
                        className='button is-danger is-small'
                        onClick={(e) => this.deleteViolation(violation.idx)}
                      >
                        <span className='icon'>
                          <i className='fa fa-times' />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      case EditTypes.MODIFY_DEFENSIVE_SHIFT_VIOLATION:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_EVENT)
              }}
            >
              Delete Violation
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.EDIT_VIOLATIONS)
              }}
            >
              Edit Violation
            </button>
          </div>
        )
      case EditTypes.ADD_PLAY:
        var playType = this.play.playEvent.playType

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <label>
              <u>Play Type</u>
            </label>
            {BaseRunningPlayTypeOptions.map((eventType, i) => {
              return (
                <div key={i} style={{ width: '100%', height: '100%' }}>
                  <span
                    onClick={(e) =>
                      (this.play.playEvent.playType = eventType.value)
                    }
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': playType == eventType.value,
                          'fa-circle': playType != eventType.value,
                        })}
                      />
                    </span>
                    <label>{eventType.label}</label>
                  </span>
                </div>
              )
            })}
          </div>
        )
      case EditTypes.EDIT_PLAY:
        var flagMap = {}
        if (this.play.playEvent.flags && this.play.playEvent.flags.length > 0) {
          for (var i = 0; i < this.play.playEvent.flags.length; i++) {
            flagMap[this.play.playEvent.flags[i]] = true
          }
        }
        var isSacBunt = flagMap['SAC']
        var isSacFly = flagMap['SF']
        var isInfieldFly = flagMap['IF']
        var isInsideThePark = flagMap['ITP']
        var isGroundRule = flagMap['GR']
        var isNonDoublePlay = flagMap['NDP']

        var isBaseRunningPlay = baseRunningPlayTypes[
          this.play.playEvent.playType
        ]
          ? true
          : false

        var eventTypeOptions = isBaseRunningPlay
          ? BaseRunningPlayTypeOptions
          : BatterResultPlayTypeOptions
        var playType = this.play.playEvent.playType

        var numReviews = ''
        if (this.play.reviews && this.play.reviews.length > 0) {
          numReviews = ' (' + this.play.reviews.length + ')'
        }

        var numViolations = ''
        if (this.play.violations && this.play.violations.length > 0) {
          numViolations = ' (' + this.play.violations.length + ')'
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <label>
              <u>Event Type</u>
            </label>

            {eventTypeOptions.map((eventType, i) => {
              return (
                <div key={i} style={{ width: '100%', height: '100%' }}>
                  <span
                    onClick={(e) =>
                      (this.play.playEvent.playType = eventType.value)
                    }
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': playType == eventType.value,
                          'fa-circle': playType != eventType.value,
                        })}
                      />
                    </span>
                    <label>{eventType.label}</label>
                  </span>
                </div>
              )
            })}

            <div
              style={{ width: '100%', height: '100%' }}
              onClick={(e) => this.updateFlag('NDP', !isNonDoublePlay)}
            >
              <span className='icon'>
                <i
                  className={cn('far', 'fa-lg', {
                    'fa-check-square': isNonDoublePlay,
                    'fa-square': !isNonDoublePlay,
                  })}
                />
              </span>
              <label>Non Double/Triple Play</label>
            </div>

            {isBaseRunningPlay ? null : (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={(e) => this.updateFlag('SAC', !isSacBunt)}
              >
                <span className='icon'>
                  <i
                    className={cn('far', 'fa-lg', {
                      'fa-check-square': isSacBunt,
                      'fa-square': !isSacBunt,
                    })}
                  />
                </span>
                <label>Sac Bunt</label>
              </div>
            )}

            {isBaseRunningPlay ? null : (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={(e) => this.updateFlag('SF', !isSacFly)}
              >
                <span className='icon'>
                  <i
                    className={cn('far', 'fa-lg', {
                      'fa-check-square': isSacFly,
                      'fa-square': !isSacFly,
                    })}
                  />
                </span>
                <label>Sac Fly</label>
              </div>
            )}

            {this.play.playEvent.playType == PlayTypes.HOME_RUN &&
            !isBaseRunningPlay ? (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={(e) => this.updateFlag('ITP', !isInsideThePark)}
              >
                <span className='icon'>
                  <i
                    className={cn('far', 'fa-lg', {
                      'fa-check-square': isInsideThePark,
                      'fa-square': !isInsideThePark,
                    })}
                  />
                </span>
                <label>Inside The Park</label>
              </div>
            ) : null}

            {this.play.playEvent.playType == PlayTypes.DOUBLE &&
            !isBaseRunningPlay ? (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={(e) => this.updateFlag('GR', !isGroundRule)}
              >
                <span className='icon'>
                  <i
                    className={cn('far', 'fa-lg', {
                      'fa-check-square': isGroundRule,
                      'fa-square': !isGroundRule,
                    })}
                  />
                </span>
                <label>Ground Rule</label>
              </div>
            ) : null}

            {this.play.playEvent.playType == PlayTypes.BATTER_OUT &&
            !isBaseRunningPlay ? (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={(e) => this.updateFlag('IF', !isInfieldFly)}
              >
                <span className='icon'>
                  <i
                    className={cn('far', 'fa-lg', {
                      'fa-check-square': isInfieldFly,
                      'fa-square': !isInfieldFly,
                    })}
                  />
                </span>
                <label>Infield Fly</label>
              </div>
            ) : null}

            <button
              className='button is-small mt-3'
              onClick={(e) => {
                this.trigger(null, EditTypes.EDIT_REVIEWS, null)
              }}
            >
              Reviews {numReviews}
            </button>
            <div className='mt-3'>
              <OperatorChangeReason operatorChangeType={this.type} />
            </div>
          </div>
        )
      case EditTypes.EDIT_TRAJECTORY:
        var showHitLocation =
          this.play.playEvent.playType == PlayTypes.HOME_RUN ||
          (this.play.playEvent.playType == PlayTypes.DOUBLE &&
            this.play.playEvent.flags &&
            this.play.playEvent.flags.indexOf('GR') != -1)
        if (showHitLocation && !this.play.playEvent.hitLocation) {
          this.play.playEvent.hitLocation = {
            fieldLocation: '',
          }
        }
        var isFoulBall = this.play.playEvent.isFoulBall
        var isHardHit = this.play.playEvent.hitHardness == '+'
        var isSoftHit = this.play.playEvent.hitHardness == '-'

        var trajectory = this.play.playEvent.hitTrajectory

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='columns is-gapless'>
              <div className='column'>
                <label>
                  <u>Hit Trajectory</u>
                </label>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => {
                      this.play.playEvent.hitTrajectory = ''
                      this.play.playEvent.hitHardness = ''
                    }}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == '',
                          'fa-circle': trajectory != '',
                        })}
                      />
                    </span>
                    <label>N/A - None</label>
                  </span>
                </div>

                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'G')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'G',
                          'fa-circle': trajectory != 'G',
                        })}
                      />
                    </span>
                    <label>G - Ground Ball</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'L')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'L',
                          'fa-circle': trajectory != 'L',
                        })}
                      />
                    </span>
                    <label>L - Line Drive</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'F')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'F',
                          'fa-circle': trajectory != 'F',
                        })}
                      />
                    </span>
                    <label>F - Fly Ball</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'P')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'P',
                          'fa-circle': trajectory != 'P',
                        })}
                      />
                    </span>
                    <label>P - Pop-Up</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'BG')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'BG',
                          'fa-circle': trajectory != 'BG',
                        })}
                      />
                    </span>
                    <label>BG - Bunt Ground Ball</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'BL')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'BL',
                          'fa-circle': trajectory != 'BL',
                        })}
                      />
                    </span>
                    <label>BL - Bunt Line Drive</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <span
                    onClick={(e) => (this.play.playEvent.hitTrajectory = 'BP')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': trajectory == 'BP',
                          'fa-circle': trajectory != 'BP',
                        })}
                      />
                    </span>
                    <label>BP - Bunt Pop-Up</label>
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <br />
                  <label>
                    <u>Hit Hardness</u>
                  </label>
                  <br />

                  <span onClick={(e) => (this.play.playEvent.hitHardness = '')}>
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': !isHardHit && !isSoftHit,
                          'fa-circle': isHardHit || isSoftHit,
                        })}
                      />
                    </span>
                    <label>N/A</label>
                  </span>

                  <span
                    onClick={(e) => (this.play.playEvent.hitHardness = '+')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': isHardHit,
                          'fa-circle': !isHardHit,
                        })}
                      />
                    </span>
                    <label>Hard (+)</label>
                  </span>

                  <span
                    onClick={(e) => (this.play.playEvent.hitHardness = '-')}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': isSoftHit,
                          'fa-circle': !isSoftHit,
                        })}
                      />
                    </span>
                    <label>Soft (-)</label>
                  </span>
                </div>

                <br />
                <div
                  style={{ width: '100%' }}
                  onClick={(e) =>
                    (this.play.playEvent.isFoulBall = !isFoulBall)
                  }
                >
                  <span className='icon'>
                    <i
                      className={cn('far', 'fa-lg', {
                        'fa-check-square': isFoulBall,
                        'fa-square': !isFoulBall,
                      })}
                    />
                  </span>
                  <label>Foul Ball</label>
                </div>

                {showHitLocation ? (
                  <div>
                    <br />
                    <label>Hit Location</label>
                    <Select
                      className='is-small'
                      value={this.play.playEvent.hitLocation.fieldLocation}
                      onChange={(option) => {
                        this.play.playEvent.hitLocation.fieldLocation =
                          option.value
                      }}
                      options={this.hitLocationOption}
                      clearable={false}
                      autoFocus
                      matchPos='start'
                    />
                  </div>
                ) : null}
              </div>
              <div className='column'>
                <div className='mt-2'>
                  <label>
                    <u>Hit Location</u>
                  </label>
                  <br />
                </div>
                <div>
                  <HitLocationEditor />
                </div>
              </div>
            </div>
          </div>
        )

      case EditTypes.EDIT_FLAGS:
        return (
          <table
            className='table mb-0 is-narrow is-bordered is-fullwidth'
            style={{ width: 1000 }}
          >
            <thead>
              <tr>
                <th
                  style={{ width: 40, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  <button
                    className='button is-small is-success'
                    onClick={(e) => this.addFlag()}
                  >
                    <span className='icon'>
                      <i className='fa fa-plus' />
                    </span>
                  </button>
                </th>
                <th style={{ width: 250 }}>Flags</th>
                <th
                  style={{ width: 60, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {(this.play.playEvent.flags || []).map((flag, i) => {
                return (
                  <tr key={i}>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <span className='icon'>
                        <i className='fa fa-arrows' />
                      </span>
                    </td>
                    <td>
                      <Select
                        className='is-small'
                        options={this.flags}
                        value={flag}
                        onChange={(v) => {
                          this.updateFlag(i, v)
                        }}
                        simpleValue
                        clearable={false}
                      />
                    </td>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button
                        className='button is-danger is-small'
                        onClick={(e) => this.deleteFlag(i)}
                      >
                        <span className='icon'>
                          <i className='fa fa-times' />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )

      case EditTypes.EDIT_FIELDERS:
        return (
          <table
            className='table mb-0 is-narrow is-bordered is-fullwidth'
            style={{ width: 1000 }}
          >
            <thead>
              <tr>
                <th colSpan='2'>
                  <OperatorChangeReason operatorChangeType={this.type} />
                </th>
              </tr>

              <tr>
                <th
                  style={{ width: 40, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  <button
                    className='button is-small is-success'
                    onClick={(e) => this.addFielder(0)}
                  >
                    <span className='icon fa'>
                      <i className='fa fa-plus' />
                    </span>
                  </button>
                </th>
                <th style={{ width: 250 }}>Fielders</th>
                <th style={{ width: 250 }}>Credits</th>
                <th
                  style={{ width: 60, verticalAlign: 'middle' }}
                  className='has-text-centered'
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.play.selectedFielders.map((fielder, i) => {
                const position = fielder.position

                let credit = fielder.credit || ''

                let runnerIsOut = false
                if (this.play.selectedFielderMovementIndex !== null) {
                  const runner =
                    this.play.playEvent.runnerMovements[this.play.selectedBase]
                  if (runner) {
                    const movement =
                      runner.movements[this.play.selectedFielderMovementIndex]
                    if (movement) {
                      runnerIsOut = true
                    }
                  }
                }

                // for out types, show the default as assists/putouts
                if (
                  runnerIsOut ||
                  this.play.playEvent.playType == 'batter_out' ||
                  this.play.playEvent.playType == 'double_play' ||
                  this.play.playEvent.playType == 'triple_play'
                ) {
                  credit = credit || 'assist'
                  if (
                    credit == 'assist' &&
                    this.play.selectedFielders.length > 0 &&
                    this.play.selectedFielders.length - 1 == i
                  ) {
                    credit = 'put_out'
                  }
                }

                return (
                  <tr key={i}>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button
                        className='button is-small is-success'
                        onClick={(e) => this.addFielder(i + 1)}
                      >
                        <span className='icon fa'>
                          <i className='fa fa-plus' />
                        </span>
                      </button>
                    </td>
                    <td>
                      <table className='fielder-select-table'>
                        <thead>
                          <tr>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                          </tr>
                          <tr>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 1)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 1,
                                      'fa-circle': position != 1,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 2)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 2,
                                      'fa-circle': position != 2,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 3)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 3,
                                      'fa-circle': position != 3,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 4)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 4,
                                      'fa-circle': position != 4,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 5)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 5,
                                      'fa-circle': position != 5,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 6)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 6,
                                      'fa-circle': position != 6,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 7)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 7,
                                      'fa-circle': position != 7,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 8)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 8,
                                      'fa-circle': position != 8,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'position', 9)
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': position == 9,
                                      'fa-circle': position != 9,
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                    <td>
                      <table className='fielder-select-table'>
                        <thead>
                          <tr>
                            <th>A</th>
                            <th>PO</th>
                            <th>E</th>
                            <th>ETH</th>
                            <th>DROP</th>
                            <th>INT</th>
                            <th>DEF</th>
                            <th>N/A</th>
                          </tr>
                          <tr>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'credit', 'assist')
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': credit == 'assist',
                                      'fa-circle': credit != 'assist',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'credit', 'put_out')
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': credit == 'put_out',
                                      'fa-circle': credit != 'put_out',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'credit', 'error')
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': credit == 'error',
                                      'fa-circle': credit != 'error',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(
                                    i,
                                    'credit',
                                    'throwing_error',
                                  )
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle':
                                        credit == 'throwing_error',
                                      'fa-circle': credit != 'throwing_error',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(
                                    i,
                                    'credit',
                                    'dropped_throw',
                                  )
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle':
                                        credit == 'dropped_throw',
                                      'fa-circle': credit != 'dropped_throw',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(
                                    i,
                                    'credit',
                                    'interference',
                                  )
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle':
                                        credit == 'interference',
                                      'fa-circle': credit != 'interference',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(i, 'credit', 'deflection')
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle': credit == 'deflection',
                                      'fa-circle': credit != 'deflection',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                            <th>
                              <span
                                onClick={(e) =>
                                  this.updateFielder(
                                    i,
                                    'credit',
                                    'fielded_ball',
                                  )
                                }
                              >
                                <span className='icon'>
                                  <i
                                    className={cn('far', 'fa-lg', {
                                      'fa-check-circle':
                                        credit == 'fielded_ball',
                                      'fa-circle': credit != 'fielded_ball',
                                    })}
                                  />
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                    <td
                      className='has-text-centered'
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button
                        className='button is-danger is-small'
                        onClick={(e) => this.deleteFielder(i)}
                      >
                        <span className='icon'>
                          <i className='fa fa-times' />
                        </span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )

      case EditTypes.EDIT_RUNNERS:
        var runnerSequence =
          this.play.playEvent.runnerMovements[this.play.selectedBase].sequence
        var runnerHasScored = false
        return (
          <div>
            <table
              className='table mb-0 is-narrow is-bordered is-fullwidth'
              style={{ width: 1000 }}
            >
              <thead>
                <tr>
                  <th
                    style={{ width: 40, verticalAlign: 'middle' }}
                    className='has-text-centered'
                  />
                  <th colSpan='8'>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'normal' }}>
                            Runner Start Base
                          </td>
                          <td>
                            <b>{this.play.selectedBase}</b>
                          </td>
                          <td style={{ fontWeight: 'normal' }}>
                            Runner End Base
                          </td>
                          <td>
                            <b>
                              {
                                this.play.playEvent.runnerMovements[
                                  this.play.selectedBase
                                ].end
                              }
                            </b>
                          </td>
                          <td style={{ fontWeight: 'normal' }}>
                            Runner Sequence
                          </td>
                          <td>
                            <span
                              onClick={(e) => this.updateRunner('sequence', 1)}
                            >
                              <span className='icon'>
                                <i
                                  className={cn('far', 'fa-lg', {
                                    'fa-check-circle': runnerSequence == 1,
                                    'fa-circle': runnerSequence != 1,
                                  })}
                                />
                              </span>
                              <span>1</span>
                            </span>
                            <span
                              onClick={(e) => this.updateRunner('sequence', 2)}
                            >
                              <span className='icon'>
                                <i
                                  className={cn('far', 'fa-lg', {
                                    'fa-check-circle': runnerSequence == 2,
                                    'fa-circle': runnerSequence != 2,
                                  })}
                                />
                              </span>
                              <span>2</span>
                            </span>
                            <span
                              onClick={(e) => this.updateRunner('sequence', 3)}
                            >
                              <span className='icon'>
                                <i
                                  className={cn('far', 'fa-lg', {
                                    'fa-check-circle': runnerSequence == 3,
                                    'fa-circle': runnerSequence != 3,
                                  })}
                                />
                              </span>
                              <span>3</span>
                            </span>
                            <span
                              onClick={(e) => this.updateRunner('sequence', 4)}
                            >
                              <span className='icon'>
                                <i
                                  className={cn('far', 'fa-lg', {
                                    'fa-check-circle': runnerSequence == 4,
                                    'fa-circle': runnerSequence != 4,
                                  })}
                                />
                              </span>
                              <span>4</span>
                            </span>
                            <span
                              onClick={(e) =>
                                this.updateRunner('sequence', null)
                              }
                            >
                              <span className='icon'>
                                <i
                                  className={cn('far', 'fa-lg', {
                                    'fa-check-circle': !runnerSequence,
                                    'fa-circle': runnerSequence,
                                  })}
                                />
                              </span>
                              <span>N/A</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                </tr>
                <tr>
                  <th
                    style={{ width: 40, verticalAlign: 'middle' }}
                    className='has-text-centered'
                  >
                    <button
                      className='button is-small is-success'
                      onClick={(e) => this.addRunnerMovement(0)}
                    >
                      <span className='icon fa'>
                        <i className='fa fa-plus' />
                      </span>
                    </button>
                  </th>
                  <th style={{ width: 150 }}>Movement Start Base</th>
                  <th style={{ width: 150 }}>Movement End Base</th>
                  <th style={{ width: 50 }}>Safe/Out</th>
                  <th style={{ width: 100 }}>RBI</th>
                  <th style={{ width: 100 }}>ER</th>
                  <th style={{ width: 100 }}>Fielders</th>
                  <th style={{ width: 200 }}>Reason</th>
                  <th
                    style={{ width: 60, verticalAlign: 'middle' }}
                    className='has-text-centered'
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {(
                  this.play.playEvent.runnerMovements[this.play.selectedBase]
                    .movements || []
                ).map((movement, i) => {
                  const runner =
                    this.play.playEvent.runnerMovements[this.play.selectedBase]
                  const filteredFielders = []
                  for (
                    var j = 0;
                    j < (movement.fielderCredits || []).length;
                    j++
                  ) {
                    const fielder = movement.fielderCredits[j]
                    let fielderText = fielder.position
                    if (fielder.credit) {
                      fielderText += getFielderCreditDisplay(fielder.credit)
                    }
                    filteredFielders.push(fielderText)
                  }
                  let movementReason = filteredFielders.join('-')
                  if (filteredFielders.length == 0) {
                    movementReason = '+'
                  }

                  let noRbi = false
                  let earnedRun = ''
                  if (movement.runRulings) {
                    for (var j = 0; j < movement.runRulings.length; j++) {
                      const ruling = movement.runRulings[j]
                      if (ruling == 'NR') {
                        noRbi = true
                      } else if (ruling == 'UR' || ruling == 'TUR') {
                        earnedRun = ruling
                      }
                    }
                  }
                  let isScore = false
                  if (
                    (movement.end == 'H' || movement.end == 4) &&
                    !movement.out
                  ) {
                    isScore = true
                    runnerHasScored = true
                  }

                  const startBase = movement.start || runner.start
                  const endBase = movement.end || runner.end

                  const isOut = movement.out || false

                  // this is adding a new movement, if the runner already scored, keep that
                  if (
                    i ==
                      this.play.playEvent.runnerMovements[
                        this.play.selectedBase
                      ].movements.length -
                        1 &&
                    !movement.start &&
                    !movement.end &&
                    !movement.out &&
                    !movement.scored &&
                    runnerHasScored &&
                    (endBase == 4 || endBase == 'H')
                  ) {
                    movement.scored = true
                    movement.end = endBase
                    isScore = true
                  }

                  let movementLabel = 'Advanced On Play'
                  if (isOut) {
                    movementLabel = 'Out On Play'
                  }
                  BaseRunningReasonOptions.forEach((reason) => {
                    if (reason.value && reason.value === movement.reason) {
                      movementLabel = reason.label
                    }
                  })

                  return (
                    <tr key={i}>
                      <td
                        className='has-text-centered'
                        style={{ verticalAlign: 'middle' }}
                      >
                        <button
                          className='button is-small is-success'
                          onClick={(e) => this.addRunnerMovement(i + 1)}
                        >
                          <span className='icon fa'>
                            <i className='fa fa-plus' />
                          </span>
                        </button>
                      </td>
                      <td>
                        <table className='fielder-select-table'>
                          <thead>
                            <tr>
                              <th>B</th>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                            </tr>
                            <tr>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'start', 'B')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': startBase == 'B',
                                        'fa-circle': startBase != 'B',
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'start', '1')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': startBase == 1,
                                        'fa-circle': startBase != 1,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'start', '2')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': startBase == 2,
                                        'fa-circle': startBase != 2,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'start', '3')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': startBase == 3,
                                        'fa-circle': startBase != 3,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td>
                        <table className='fielder-select-table'>
                          <thead>
                            <tr>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>H</th>
                            </tr>
                            <tr>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'end', '1')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': endBase == '1',
                                        'fa-circle': endBase != '1',
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'end', '2')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': endBase == 2,
                                        'fa-circle': endBase != 2,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'end', '3')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': endBase == 3,
                                        'fa-circle': endBase != 3,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'end', 'H')
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': endBase == 'H',
                                        'fa-circle': endBase != 'H',
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td>
                        <table className='fielder-select-table'>
                          <thead>
                            <tr>
                              <th>Safe</th>
                              <th>Out</th>
                            </tr>
                            <tr>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'out', false)
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': !isOut,
                                        'fa-circle': isOut,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                              <th>
                                <span
                                  onClick={(e) =>
                                    this.updateRunnerMovement(i, 'out', true)
                                  }
                                >
                                  <span className='icon'>
                                    <i
                                      className={cn('far', 'fa-lg', {
                                        'fa-check-circle': isOut,
                                        'fa-circle': !isOut,
                                      })}
                                    />
                                  </span>
                                </span>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td>
                        {isScore ? (
                          <table className='fielder-select-table'>
                            <thead>
                              <tr>
                                <th>RBI</th>
                              </tr>
                              <tr>
                                <th>
                                  <div
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={(e) =>
                                      this.updateRunnerMovement(
                                        i,
                                        'noRbi',
                                        !noRbi,
                                      )
                                    }
                                  >
                                    <span className='icon'>
                                      <i
                                        className={cn('far', 'fa-lg', {
                                          'fa-check-square': !noRbi,
                                          'fa-square': noRbi,
                                        })}
                                      />
                                    </span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                          </table>
                        ) : null}
                      </td>
                      <td>
                        {isScore ? (
                          <table className='fielder-select-table'>
                            <thead>
                              <tr>
                                <th>ER</th>
                                <th>UR</th>
                                <th>TUR</th>
                              </tr>
                              <tr>
                                <th>
                                  <span
                                    onClick={(e) =>
                                      this.updateRunnerMovement(
                                        i,
                                        'earnedRun',
                                        '',
                                      )
                                    }
                                  >
                                    <span className='icon'>
                                      <i
                                        className={cn('far', 'fa-lg', {
                                          'fa-check-circle': earnedRun == '',
                                          'fa-circle': earnedRun != '',
                                        })}
                                      />
                                    </span>
                                  </span>
                                </th>
                                <th>
                                  <span
                                    onClick={(e) =>
                                      this.updateRunnerMovement(
                                        i,
                                        'earnedRun',
                                        'UR',
                                      )
                                    }
                                  >
                                    <span className='icon'>
                                      <i
                                        className={cn('far', 'fa-lg', {
                                          'fa-check-circle': earnedRun == 'UR',
                                          'fa-circle': earnedRun != 'UR',
                                        })}
                                      />
                                    </span>
                                  </span>
                                </th>
                                <th>
                                  <span
                                    onClick={(e) =>
                                      this.updateRunnerMovement(
                                        i,
                                        'earnedRun',
                                        'TUR',
                                      )
                                    }
                                  >
                                    <span className='icon'>
                                      <i
                                        className={cn('far', 'fa-lg', {
                                          'fa-check-circle': earnedRun == 'TUR',
                                          'fa-circle': earnedRun != 'TUR',
                                        })}
                                      />
                                    </span>
                                  </span>
                                </th>
                              </tr>
                            </thead>
                          </table>
                        ) : null}
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        <button
                          className='button'
                          onClick={(e) => {
                            e.stopPropagation()
                            this.play.selectedFielderMovementIndex = i
                            this.play.selectedFielders =
                              movement.fielderCredits || []
                            this.trigger(
                              null,
                              EditTypes.EDIT_FIELDERS,
                              this.play,
                            )
                          }}
                        >
                          {movementReason}
                        </button>
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        <button
                          className='button'
                          onClick={(e) => {
                            e.stopPropagation()
                            this.play.selectedFielderMovementIndex = i
                            this.trigger(
                              null,
                              EditTypes.EDIT_RUNNER_MOVEMENT,
                              this.play,
                            )
                          }}
                        >
                          {movementLabel}
                        </button>
                      </td>
                      <td
                        className='has-text-centered'
                        style={{ verticalAlign: 'middle' }}
                      >
                        <button
                          className='button is-danger is-small'
                          onClick={(e) => this.deleteRunnerMovement(i)}
                        >
                          <span className='icon'>
                            <i className='fa fa-times' />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='mt-3'>
              <OperatorChangeReason operatorChangeType={this.type} />
            </div>
          </div>
        )

      case EditTypes.EDIT_RUNNER_MOVEMENT:
        var runner =
          this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
            this.play.selectedFielderMovementIndex
          ]
        var isBaseRunningPlay = baseRunningPlayTypes[
          this.play.playEvent.playType
        ]
          ? true
          : false
        var isInclusiveBaseRunningPlay = false
        if (
          this.play.playEvent.playType == PlayTypes.STRIKEOUT ||
          this.play.playEvent.playType == PlayTypes.WALK ||
          this.play.playEvent.playType == PlayTypes.INTENTIONAL_WALK
        ) {
          isInclusiveBaseRunningPlay = true // need to allow for the baserunning types here
        }
        var isOut = runner.out || false
        var reasonOptions = BaseRunningReasonOptions.filter((reasonOption) => {
          if (!isOut && reasonOption.label == 'Out On Play') {
            return false
          }
          if (isOut && reasonOption.label == 'Advanced On Play') {
            return false
          }
          if (!isBaseRunningPlay && !isInclusiveBaseRunningPlay) {
            switch (reasonOption.value) {
              case MovementReasons.OS_PENDING_BASERUNNING:
              case MovementReasons.STOLEN_BASE:
              case MovementReasons.DEFENSIVE_INDIFFERENCE:
              case MovementReasons.CAUGHT_STEALING:
              case MovementReasons.WILD_PITCH:
              case MovementReasons.PASSED_BALL:
              case MovementReasons.PICKOFF:
              case MovementReasons.PICKOFF_ERROR:
              case MovementReasons.PICKOFF_CAUGHT_STEALING:
                return false
            }
          } else if (isBaseRunningPlay) {
            switch (reasonOption.value) {
              case MovementReasons.FORCED_OUT:
              case MovementReasons.HIT_BY_BATTED_BALL:
              case MovementReasons.DOUBLED_OFF_BAG:
              case MovementReasons.OUT_STRETCHING:
              case MovementReasons.BATTER_INTERFERENCE:
                return false
            }
          }
          return true
        })
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <label>
              <u>Runner Movement Reason</u>
            </label>

            {reasonOptions.map((reason) => {
              return (
                <div
                  key={reason.value}
                  style={{ width: '100%', height: '100%' }}
                >
                  <span
                    onClick={(e) => {
                      this.updateRunnerMovement(
                        this.play.selectedFielderMovementIndex,
                        'reason',
                        reason.value,
                      )
                    }}
                  >
                    <span className='icon'>
                      <i
                        className={cn('far', 'fa-lg', {
                          'fa-check-circle': reason.value === runner.reason,
                          'fa-circle': reason.value !== runner.reason,
                        })}
                      />
                    </span>
                    <label>{reason.label}</label>
                  </span>
                </div>
              )
            })}
            <div className='mt-3'>
              <OperatorChangeReason operatorChangeType={this.type} />
            </div>
          </div>
        )

      case EditTypes.MODIFY_SUB:
        var hasRunnersOn = false
        if (this.play.gameState && this.play.gameState.runners) {
          for (var i = 0; i < this.play.gameState.runners.length; i++) {
            if (this.play.gameState.runners[i]) {
              hasRunnersOn = true
              break
            }
          }
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addSub('defense')
                this.trigger(null, EditTypes.ADD_SUB, null)
              }}
            >
              Add Defensive Sub Before
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addSub('pinchHitter')
                this.trigger(null, EditTypes.ADD_SUB, null)
              }}
            >
              Add Pinch Hitter Before
            </button>
            {hasRunnersOn ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.addSub('pinchRunner')
                  this.trigger(null, EditTypes.ADD_SUB, null)
                }}
              >
                Add Pinch Runner Before
              </button>
            ) : null}
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.editSub()
                this.trigger(null, EditTypes.EDIT_SUB, null)
              }}
            >
              Edit Sub
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.trigger(null, EditTypes.DELETE_SUB, null)
              }}
            >
              Delete Sub
            </button>
          </div>
        )

      case EditTypes.QUICK_SUB:
        var hasRunnersOn = false
        if (this.play.gameState && this.play.gameState.runners) {
          for (var i = 0; i < this.play.gameState.runners.length; i++) {
            if (this.play.gameState.runners[i]) {
              hasRunnersOn = true
              break
            }
          }
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addSub('defense')
                this.trigger(null, EditTypes.ADD_SUB, null)
              }}
            >
              Defensive Sub
            </button>
            <button
              className='button is-fullwidth mb-1'
              onClick={(e) => {
                this.addSub('pinchHitter')
                this.trigger(null, EditTypes.ADD_SUB, null)
              }}
            >
              Pinch Hitter
            </button>
            {hasRunnersOn ? (
              <button
                className='button is-fullwidth mb-1'
                onClick={(e) => {
                  this.addSub('pinchRunner')
                  this.trigger(null, EditTypes.ADD_SUB, null)
                }}
              >
                Pinch Runner
              </button>
            ) : null}
          </div>
        )

      case EditTypes.EDIT_WEATHER:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>Weather</div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <Select
                      className='is-small'
                      options={this.weatherConditions.slice()}
                      value={this.play.weather.sky}
                      onChange={(v) => {
                        this.updateWeatherInfo('sky', v)
                      }}
                      simpleValue
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>Wind Direction</div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <Select
                      className='is-small'
                      options={this.windDirections.slice()}
                      value={this.play.weather.windDirection}
                      onChange={(v) => {
                        this.updateWeatherInfo('windDirection', v)
                      }}
                      simpleValue
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>Wind Speed</div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <input
                      className='input is-small'
                      value={this.play.weather.windSpeed}
                      onChange={(e) => {
                        let num = parseInt(e.target.value)
                        if (isNaN(num)) {
                          num = 0
                        }

                        if (num < 0) {
                          num = 0
                        } else if (num > 50) {
                          num = 50
                        }

                        this.updateWeatherInfo('windSpeed', num)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>Temperature</div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <input
                      className='input is-small'
                      value={this.play.weather.temperature}
                      onChange={(e) => {
                        let num = parseInt(e.target.value)
                        if (isNaN(num)) {
                          num = 0
                        }
                        if (num < -50) {
                          num = -50
                        } else if (num > 150) {
                          num = 150
                        }

                        this.updateWeatherInfo('temperature', num)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      case EditTypes.EDIT_SCOREKEEPERS:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>
                  <p
                    className={cn({
                      'is-success':
                        this.play.scoreKeepers.primaryStringer.hasChange,
                      'is-danger':
                        this.play.scoreKeepers.primaryStringer.isInvalid,
                    })}
                  >
                    Primary Stringer
                  </p>
                </div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <p
                      className={cn({
                        'is-success':
                          this.play.scoreKeepers.primaryStringer.hasChange,
                        'is-danger':
                          this.play.scoreKeepers.primaryStringer.isInvalid,
                      })}
                    >
                      <Select
                        className='is-small'
                        options={this.stringers.slice()}
                        value={this.play.scoreKeepers.primaryStringer.id}
                        valueKey='id'
                        labelKey='name'
                        onChange={(v) => {
                          this.updateScorekeeper('primaryStringer', v)
                        }}
                        simpleValue
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>
                  <p
                    className={cn({
                      'is-success':
                        this.play.scoreKeepers.secondaryStringer.hasChange,
                      'is-danger':
                        this.play.scoreKeepers.secondaryStringer.isInvalid,
                    })}
                  >
                    Secondary Stringer
                  </p>
                </div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <p
                      className={cn({
                        'is-success':
                          this.play.scoreKeepers.secondaryStringer.hasChange,
                        'is-danger':
                          this.play.scoreKeepers.secondaryStringer.isInvalid,
                      })}
                    >
                      <Select
                        className='is-small'
                        options={this.stringers.slice()}
                        value={this.play.scoreKeepers.secondaryStringer.id}
                        valueKey='id'
                        labelKey='name'
                        onChange={(v) => {
                          this.updateScorekeeper('secondaryStringer', v)
                        }}
                        simpleValue
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='field is-horizontal'>
              <div
                className='field-label is-normal'
                style={{ minWidth: '30%' }}
              >
                <div className='label'>
                  <p
                    className={cn({
                      'is-success':
                        this.play.scoreKeepers.officialScorer.hasChange,
                      'is-danger':
                        this.play.scoreKeepers.officialScorer.isInvalid,
                    })}
                  >
                    Official Scorer
                  </p>
                </div>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <p
                      className={cn({
                        'is-success':
                          this.play.scoreKeepers.officialScorer.hasChange,
                        'is-danger':
                          this.play.scoreKeepers.officialScorer.isInvalid,
                      })}
                    >
                      <Select
                        className='is-small'
                        options={this.scorers.slice()}
                        value={this.play.scoreKeepers.officialScorer.id}
                        valueKey='id'
                        labelKey='name'
                        onChange={(v) => {
                          this.updateScorekeeper('officialScorer', v)
                        }}
                        simpleValue
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      case EditTypes.EDIT_UMPIRES:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div>
              {this.play.errorMessage ? (
                <h1
                  className={cn('help', {
                    'is-danger': true,
                  })}
                >
                  {this.play.errorMessage}
                </h1>
              ) : null}

              <table
                className='table mb-0 is-narrow is-bordered is-fullwidth'
                style={{ width: 900 }}
              >
                <thead>
                  <tr>
                    <th style={{ width: 400 }}>Player</th>
                    <th style={{ width: 400 }}>Position</th>
                  </tr>
                </thead>
                <tbody>
                  {this.play.umpires.map((umpire, i) => {
                    return (
                      <tr
                        key={i}
                        className={cn({
                          'is-success': umpire.hasChange,
                          'is-danger': umpire.isInvalid,
                        })}
                      >
                        <td>
                          <Select
                            className='is-small'
                            options={this.umpires}
                            value={umpire.player}
                            onChange={(v) => {
                              this.updateUmpire(i, v, umpire.position)
                            }}
                            simpleValue
                          />
                        </td>
                        <td>
                          <Select
                            className='is-small'
                            options={this.umpirePositionCodes}
                            value={umpire.position}
                            simpleValue
                            onChange={(v) => {
                              this.updateUmpire(i, umpire.player, v)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )

      case EditTypes.ADD_SUB:
      case EditTypes.EDIT_SUB:
      case EditTypes.EDIT_STARTERS:
        var showDHOptions = false
        var usingDh = false
        if (this.play.subPlayers.length == 10) {
          showDHOptions = true
          usingDh = true
        } else if (this.type == EditTypes.EDIT_STARTERS) {
          showDHOptions = true
        }
        if (this.store.game.isFlexibleRules) {
          showDHOptions = false
        }

        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <div>
              {this.play.errorMessage ? (
                <h1
                  className={cn('help', {
                    'is-danger': true,
                  })}
                >
                  {this.play.errorMessage}
                </h1>
              ) : null}

              {showDHOptions ? (
                <div
                  style={{ width: '100%', height: '100%' }}
                  onClick={(e) => this.toggleDH()}
                >
                  <span className='icon'>
                    <i
                      className={cn('far', 'fa-lg', {
                        'fa-check-square': usingDh,
                        'fa-square': !usingDh,
                      })}
                    />
                  </span>
                  <label>Using DH</label>
                </div>
              ) : null}

              <table
                className='table mb-0 is-narrow is-bordered is-fullwidth'
                style={{ width: 900 }}
              >
                <thead>
                  <tr>
                    <th style={{ width: 50 }}>
                      {this.play.subType == 'pinchRunner' ? 'Base' : 'Order'}
                    </th>
                    <th style={{ width: 400 }}>Player</th>
                    <th style={{ width: 400 }}>Position</th>
                    <th style={{ width: 50 }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {this.play.subPlayers.map((player, i) => {
                    return (
                      <tr
                        key={i}
                        className={cn({
                          'is-success': player.hasChange,
                          'is-danger': player.isInvalid || player.hasDelete,
                        })}
                      >
                        <td>
                          {this.play.subType == 'pinchRunner'
                            ? player.baseNum
                            : player.batOrder ||
                              (this.store.game.isFlexibleRules
                                ? player.isInLineup
                                  ? i + 1
                                  : '-'
                                : i < 9
                                  ? i + 1
                                  : '-')}
                        </td>
                        <td>
                          <Select
                            className='is-small'
                            options={this.players}
                            value={player.player}
                            onChange={(v) => {
                              this.updateSubEntry(i, v, player.position)
                            }}
                            simpleValue
                            clearable={false}
                          />
                        </td>
                        <td>
                          <Select
                            className='is-small'
                            options={
                              this.play.subType == 'defense'
                                ? this.defensePositions
                                : this.positions
                            }
                            value={player.position}
                            onChange={(v) => {
                              this.updateSubEntry(i, player.player, v)
                            }}
                            simpleValue
                            disabled={
                              this.play.subType != 'defense' &&
                              this.play.subType != 'starters'
                            }
                            clearable={false}
                          />
                        </td>
                        <td
                          className='has-text-centered'
                          style={{ verticalAlign: 'middle' }}
                        >
                          {player.hasChange ? (
                            <button
                              className='button is-danger is-small'
                              onClick={(e) => this.deleteSubEntry(i)}
                            >
                              <span className='icon'>
                                <i className='fa fa-times' />
                              </span>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className='mt-3'>
                <OperatorChangeReason operatorChangeType={this.type} />
              </div>
            </div>
          </div>
        )
      case EditTypes.EDIT_UNIFORMS:
        return (
          <div className='card-content' style={{ fontSize: '.9rem' }}>
            <p className='has-text-weight-bold'>Caps</p>
            <Select
              className='is-small'
              options={this.play.caps}
              value={this.play.gameUniforms[UNIFORM_ASSET_TYPES.CAP]}
              onChange={(v) => {
                this.updateUniformInfo(UNIFORM_ASSET_TYPES.CAP, v)
              }}
            />
            <p className='has-text-weight-bold'>Jerseys</p>
            <Select
              className='is-small'
              options={this.play.jerseys}
              value={this.play.gameUniforms[UNIFORM_ASSET_TYPES.JERSEY]}
              onChange={(v) => {
                this.updateUniformInfo(UNIFORM_ASSET_TYPES.JERSEY, v)
              }}
            />
            <p className='has-text-weight-bold'>Pants</p>
            <Select
              className='is-small'
              options={this.play.pants}
              value={this.play.gameUniforms[UNIFORM_ASSET_TYPES.PANTS]}
              onChange={(v) => {
                this.updateUniformInfo(UNIFORM_ASSET_TYPES.PANTS, v)
              }}
            />
          </div>
        )
      default:
        return null
    }
  }

  @computed get footer() {
    switch (this.type) {
      case EditTypes.MODIFY_PLAY:
      case EditTypes.MODIFY_BADJ:
      case EditTypes.MODIFY_COM:
      case EditTypes.MODIFY_EJECT:
      case EditTypes.MODIFY_INJURY:
      case EditTypes.MODIFY_PADJ:
      case EditTypes.MODIFY_RUNNER:
      case EditTypes.MODIFY_SUB:
      case EditTypes.QUICK_SUB:
      case EditTypes.MODIFY_UMPSUB:
      case EditTypes.MODIFY_LINEUP_ADJUST:
      case EditTypes.MODIFY_EVENT:
      case EditTypes.ADD_EVENT:
        return (
          <footer className='card-footer'>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.close()
              }}
            >
              Cancel
            </a>
          </footer>
        )

      case EditTypes.ADD_BADJ:
      case EditTypes.ADD_COM:
      case EditTypes.ADD_EJECT:
      case EditTypes.ADD_INJURY:
      case EditTypes.ADD_PADJ:
      case EditTypes.ADD_RUNNER:
      case EditTypes.ADD_UMPSUB:
      case EditTypes.ADD_LINEUP_ADJUST:
      case EditTypes.ADD_SUB:
      case EditTypes.EDIT_SUB:
      case EditTypes.EDIT_STARTERS:
      case EditTypes.EDIT_UMPIRES:
      case EditTypes.EDIT_SCOREKEEPERS:
      case EditTypes.EDIT_WEATHER:
      case EditTypes.ADD_PLAY:
      case EditTypes.EDIT_PLAY:
      case EditTypes.EDIT_FLAGS:
      case EditTypes.EDIT_TRAJECTORY:
      case EditTypes.EDIT_FIELDERS:
      case EditTypes.EDIT_RUNNER_MOVEMENT:
      case EditTypes.EDIT_RUNNERS:
      case EditTypes.DELETE_SUB:
      case EditTypes.DELETE_EVENT:
      case EditTypes.ADD_PITCH:
      case EditTypes.EDIT_PITCH:
      case EditTypes.EDIT_REVIEWS:
      case EditTypes.EDIT_VIOLATIONS:
      case EditTypes.ADD_MOUND_VISIT:
      case EditTypes.EDIT_MOUND_VISIT:
      case EditTypes.ADD_BATTER_TIMEOUT:
        return (
          <footer className='card-footer'>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.close()
              }}
            >
              Cancel
            </a>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.confirm()
              }}
              disabled={this.disabled}
            >
              Confirm
            </a>
          </footer>
        )

      case EditTypes.EDIT_UNIFORMS:
        return (
          <footer className='card-footer'>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.close()
              }}
            >
              Cancel
            </a>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.confirm()
              }}
              disabled={this.play.loadingUniforms}
            >
              {this.play.loadingUniforms ? (
                <span className='icon has-text-grey'>
                  <i className='fas fa-2x fa-spinner fa-pulse' />
                </span>
              ) : (
                'Confirm'
              )}
            </a>
          </footer>
        )

      case EditTypes.EDIT_PLAY_TABLE:
        return (
          <footer className='card-footer'>
            <a
              href='#'
              className='card-footer-item has-text-black'
              onClick={(e) => {
                e.preventDefault()
                this.close()
              }}
            >
              Confirm
            </a>
          </footer>
        )

      default:
        return null
    }
  }

  @action updateReview(idx, key, value) {
    const reviews = toJS(this.play.reviews)
    const review = reviews[idx]

    if (key == 'reviewedBy') {
      const nOrM = value == 'umpire' ? 'N' : 'M'
      review.reviewStatus = nOrM + review.reviewStatus.slice(1)
    }

    if (key == 'reviewStatus') {
      const nOrM = review.reviewedBy == 'umpire' ? 'N' : 'M'
      value = nOrM + value
    }

    review[key] = value

    this.play.reviews = reviews
  }

  @action updateViolation(idx, key, value) {
    const violations = toJS(this.play.violations)
    const violation = violations[idx]
    violation[key] = value
    violation['teamId'] =
      this.play.topInningSw === 'Y' ? this.homeTeamId : this.awayTeamId

    if (key === 'violationType') {
      const violationType = find(this.violationTypes, { value: value })
      violation['violationTypeId'] = violationType.value
      violation['violationType'] = violationType.label
    }

    this.play.violations = violations
  }

  @action updateScorekeeper(field, id) {
    const scoreKeepers = toJS(this.play.scoreKeepers)
    const scoreKeeper = scoreKeepers[field]
    scoreKeeper.id = id
    scoreKeeper.hasChange = true
    this.play.scoreKeepers[field] = scoreKeeper
  }

  @action updateWeatherInfo(field, val) {
    this.play.weather[field] = val
    this.play.weather.hasChange = true
  }

  @action updateUniformInfo(field, val) {
    this.play.gameUniforms[field] = val
  }

  @action updateUmpire(idx, player, position) {
    const umpires = toJS(this.play.umpires)
    const umpire = umpires[idx]
    umpire.player = player
    umpire.position = position
    umpire.hasChange = true
    this.play.umpires[idx] = umpire

    const positionsSeen = {}
    const playerSeen = {}

    for (var i = 0; i < this.play.umpires.length; i++) {
      this.play.umpires[i].isInvalid = false
    }

    this.play.errorMessage = null

    for (var i = 0; i < umpires.length; i++) {
      const sub = umpires[i]
      if (sub.position && positionsSeen[sub.position]) {
        positionsSeen[sub.position].isInvalid = true
        sub.isInvalid = true
        this.play.umpires[i] = sub
        this.play.errorMessage =
          sub.position.toUpperCase() + ' has duplicate entries'
      } else {
        positionsSeen[sub.position] = this.play.umpires[i]
      }

      if (sub.player && playerSeen[sub.player]) {
        playerSeen[sub.player].isInvalid = true
        sub.isInvalid = true
        this.play.errorMessage =
          this.store.dware.getPlayerName(sub.player) + ' has duplicate entries'
        this.play.umpires[i] = sub
      } else {
        playerSeen[sub.player] = this.play.umpires[i]
      }
    }
  }

  @action deleteSubEntry(idx) {
    const subPlayers = toJS(this.play.subPlayers)
    const subEntry = subPlayers[idx]
    subEntry.restoreOriginalEntry = true
    subEntry.hasChange = true
    subEntry.hasDelete = true
    this.play.subPlayers[idx] = subEntry
  }

  @action toggleDH() {
    const subPlayers = toJS(this.play.subPlayers)
    if (subPlayers.length <= 9) {
      subPlayers.push({})
    } else {
      subPlayers.pop()
    }
    this.play.subPlayers = subPlayers
  }

  @action updateSubEntry(idx, player, position) {
    const subPlayers = toJS(this.play.subPlayers)
    const subEntry = subPlayers[idx]
    subEntry.hasChange = true
    subEntry.hasDelete = false
    if (this.play.subType == 'pinchHitter') {
      subEntry.position = 11 // pinch hitter
    } else if (this.play.subType == 'pinchRunner') {
      subEntry.position = 12 // pinch runner
    } else {
      subEntry.position = position
    }
    subEntry.player = player

    const batOrderMap = {}
    let numTeamPlayers = this.play.teamPlayers.length
    if (this.store.game.isFlexibleRules) {
      // subEntry.batPos = idx + 1
    } else {
      if (subPlayers.length == 9 && numTeamPlayers == 10) {
        // they are removing the DH, so don't check the 10th spot
        numTeamPlayers = 9
      }
    }
    for (var i = 0; i < numTeamPlayers; i++) {
      const teamPlayer = this.play.teamPlayers[i]
      batOrderMap[parseInt(teamPlayer.player)] = teamPlayer
    }

    let playerAlreadyInLineup = batOrderMap[parseInt(subEntry.player)]
    if (
      playerAlreadyInLineup &&
      playerAlreadyInLineup.batPos == subEntry.batPos &&
      playerAlreadyInLineup.player == subEntry.player
    ) {
      if (
        (this.play.subType != 'defense' && this.play.subType != 'starters') ||
        playerAlreadyInLineup.position == subEntry.position
      ) {
        subEntry.hasChange = false
      }
    }

    if (playerAlreadyInLineup && this.type == EditTypes.EDIT_SUB) {
      if (this.play.subType == 'pinchHitter') {
        if (this.play.gameState.batter == playerAlreadyInLineup.player) {
          subEntry.restoreOriginalEntry = true
          subEntry.hasChange = true
        }
      } else if (this.play.subType == 'pinchRunner') {
        if (
          this.play.gameState.runners[subEntry.baseNum] ==
          playerAlreadyInLineup.player
        ) {
          subEntry.restoreOriginalEntry = true
          subEntry.hasChange = true
        }
      } else {
        if (
          playerAlreadyInLineup.batPos == subEntry.batPos &&
          playerAlreadyInLineup.defPos == subEntry.position &&
          playerAlreadyInLineup.player == subEntry.player
        ) {
          subEntry.restoreOriginalEntry = true
          subEntry.hasChange = true
        }
      }
    }

    this.play.subPlayers[idx] = subEntry

    const positionsSeen = {}
    const playerSeen = {}

    for (var i = 0; i < this.play.subPlayers.length; i++) {
      this.play.subPlayers[i].isInvalid = false
    }

    this.play.errorMessage = null

    for (var i = 0; i < subPlayers.length; i++) {
      const sub = subPlayers[i]
      if (this.play.subType == 'defense' || this.play.subType == 'starters') {
        if (
          parseInt(sub.position) <= 10 &&
          parseInt(sub.position) != 13 &&
          positionsSeen[sub.position]
        ) {
          positionsSeen[sub.position].isInvalid = true
          sub.isInvalid = true
          this.play.subPlayers[i] = sub
          this.play.errorMessage =
            this.store.dware.getPosition(sub.position) +
            ' has duplicate entries'
        } else {
          positionsSeen[sub.position] = this.play.subPlayers[i]
        }
      }
      playerAlreadyInLineup = batOrderMap[parseInt(sub.player)]
      if (
        this.play.subType != 'starters' &&
        playerAlreadyInLineup &&
        playerAlreadyInLineup.batPos != sub.batPos &&
        !sub.restoreOriginalEntry
      ) {
        let isDhAndPositionPlayer = false
        if (
          (playerAlreadyInLineup.position == 10 &&
            parseInt(sub.position) >= 1 &&
            parseInt(sub.position) <= 9) ||
          (sub.position == 10 &&
            parseInt(playerAlreadyInLineup.position) >= 1 &&
            parseInt(playerAlreadyInLineup.position) <= 9)
        ) {
          isDhAndPositionPlayer = true
        }
        if (!isDhAndPositionPlayer) {
          sub.isInvalid = true
          this.play.subPlayers[i] = sub
          this.play.errorMessage =
            this.store.dware.getPlayerFullName(sub.player) +
            ' already in lineup'
        }
      }

      if (!playerSeen[sub.player]) {
        playerSeen[sub.player] = []
      }
      playerSeen[sub.player].push(this.play.subPlayers[i])

      if (playerSeen[sub.player].length > 1) {
        let numDhEntries = 0
        let numNonDhEntries = 0
        for (let j = 0; j < playerSeen[sub.player].length; j++) {
          const existingPlayerEntry = playerSeen[sub.player][j]
          if (existingPlayerEntry.position == 10) {
            numDhEntries++
          } else if (
            parseInt(existingPlayerEntry.position) >= 1 &&
            parseInt(existingPlayerEntry.position) <= 9
          ) {
            numNonDhEntries++
          }
        }

        // allow a player to be at a position and the DH at the same time
        if (
          playerSeen[sub.player].length != 2 ||
          numDhEntries != 1 ||
          numNonDhEntries != 1
        ) {
          playerSeen[sub.player].isInvalid = true
          sub.isInvalid = true
          this.play.errorMessage =
            this.store.dware.getPlayerFullName(sub.player) +
            ' has duplicate entries'
          this.play.subPlayers[i] = sub
        }
      }
    }
  }

  @action resetGameState(updatedPlay) {
    let gameRunners = {}
    let numGameOuts = 0

    const atBats = this.store.game.stringerData.atBats

    let useInning
    let useTopInning
    if (updatedPlay && atBats[updatedPlay.atBatIdx]) {
      useInning = atBats[updatedPlay.atBatIdx].inning
      useTopInning = atBats[updatedPlay.atBatIdx].topInningSw
    }

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      if (useInning) {
        if (atBat.inning != useInning || atBat.topInningSw != useTopInning) {
          numGameOuts = 0
          gameRunners = {}
          continue
        }
      }

      const plays = atBat.plays ? atBat.plays : []

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]

        if (
          (play.type == 'play' || play.type == 'result') &&
          play.playEvent &&
          play.playEvent.runnerMovements
        ) {
          const runnerMovements = toJS(play.playEvent.runnerMovements)
          const originalRunnerPlacement = {}
          if (runnerMovements[1]) {
            originalRunnerPlacement[1] = true
          }
          if (runnerMovements[2]) {
            originalRunnerPlacement[2] = true
          }
          if (runnerMovements[3]) {
            originalRunnerPlacement[3] = true
          }

          if (
            play.playEvent.playType == PlayTypes.STRIKEOUT &&
            !runnerMovements['B']
          ) {
            numGameOuts++
          }

          // put in runner movements that are missing
          for (var k = 1; k <= 3; k++) {
            if (gameRunners[k] && !runnerMovements[k + '']) {
              runnerMovements[k + ''] = {
                start: k + '',
                end: k + '',
                movements: [],
              }
            } else if (!gameRunners[k] && runnerMovements[k + '']) {
              delete runnerMovements[k + '']
            }
          }

          const newRunners = {}
          for (const movementBase in runnerMovements) {
            const runner = runnerMovements[movementBase]
            let runnerIsOut = false
            let runnerScored = false
            if (runner.movements) {
              for (var k = 0; k < runner.movements.length; k++) {
                const movement = runner.movements[k]
                if (movement.out) {
                  runnerIsOut = true
                  numGameOuts++
                } else if (movement.scored) {
                  runnerScored = true
                }
              }
            }
            if (
              !runnerIsOut &&
              !runnerScored &&
              (runner.end == 1 || runner.end == 2 || runner.end == 3)
            ) {
              newRunners[runner.end] = true
            }
          }

          if (runnerMovements[1] && !originalRunnerPlacement[1]) {
            runnerMovements[1].hasChange = true
          }
          if (runnerMovements[2] && !originalRunnerPlacement[2]) {
            runnerMovements[2].hasChange = true
          }
          if (runnerMovements[3] && !originalRunnerPlacement[3]) {
            runnerMovements[3].hasChange = true
          }
          play.playEvent.runnerMovements = runnerMovements

          gameRunners = newRunners
          if (numGameOuts >= 3) {
            numGameOuts = 0
            gameRunners = {}
          }
        } else if (play.type == 'runner' && play.base) {
          gameRunners[play.base] = true
        }
      }
    }
  }

  @action updateRunner(key, value) {
    const runner = toJS(
      this.play.playEvent.runnerMovements[this.play.selectedBase],
    )
    runner[key] = value
    this.play.playEvent.runnerMovements[this.play.selectedBase] = runner
  }

  @action updateOperatorChangeReason(key, value) {
    if (!this.play.operatorChangeReasons) {
      this.play.operatorChangeReasons = {}
    }
    this.play.operatorChangeReasons[key] = value
  }

  @action updateRunnerMovement(idx, key, value) {
    const runner = toJS(
      this.play.playEvent.runnerMovements[this.play.selectedBase],
    )
    const movement = runner.movements[idx]
    if (
      key === 'reason' &&
      value === 'E' &&
      movement.end === 'H' &&
      movement.scored === true
    ) {
      // By rule, RBIs should not be credited when the reason is due to error.
      // this flow defaults to NR a.k.a. noRbi, but still allows
      // the operator to credit an RBI if they need data to be in an
      // intermediate state, or if rules change in the future, etc.
      movement.runRulings?.push('NR')
    }
    // @TODO - we should probably change runRulings to be attributes instead of an array
    if (key == 'noRbi' || key == 'earnedRun' || key == 'reason') {
      const newRunRulings = []
      if (key == 'noRbi' && value == true) {
        newRunRulings.push('NR')
      } else if (key == 'earnedRun' && value) {
        newRunRulings.push(value)
      } else if (key === 'reason') {
        // By rule, RBIs should not be credited when the reason is due to error.
        // this flow defaults to NR a.k.a. noRbi, but still allows
        // the operator to credit an RBI if they need data to be in an
        // intermediate state, or if rules change in the future, etc.
        movement[key] = value
        if (value === 'E') {
          newRunRulings.push('NR')
        }
      }
      if (movement.runRulings) {
        for (let i = 0; i < movement.runRulings.length; i++) {
          const ruling = movement.runRulings[i]
          if (key == 'noRbi') {
            if (ruling != 'NR' && ruling) {
              newRunRulings.push(ruling)
            }
          } else if (key == 'earnedRun') {
            if (ruling != 'UR' && ruling != 'TUR' && ruling) {
              newRunRulings.push(ruling)
            }
          }
        }
      }
      movement.runRulings = newRunRulings
    } else {
      movement[key] = value
    }
    let endBase = runner.end
    let startBase = runner.start
    if (runner.movements.length > 0) {
      const startMovement = runner.movements[0]
      if (startMovement.start || startMovement.start == 0) {
        startBase = startMovement.start
      }
      const endMovement = runner.movements[runner.movements.length - 1]
      if (endMovement.end || endMovement.end == 0) {
        endBase = endMovement.end
      }
    }
    if (endBase == 0) {
      endBase = 'B'
    }
    if (endBase == 4) {
      endBase = 'H'
    }
    if (startBase == 0) {
      startBase = 'B'
    }
    runner.end = endBase
    // runner.start = startBase; don't let them update start base!
    if ((movement.end == 'H' || movement.end == 4) && !movement.out) {
      movement.scored = true
      movement.runRulings = movement.runRulings || []
    } else {
      movement.scored = false
      movement.runRulings = []
    }

    this.play.playEvent.runnerMovements[this.play.selectedBase] = runner
  }

  @action updateFielder(idx, key, value) {
    // bad hack to distinguish if we are editing the primary fielder sequence, or a fielder sequence within a runner movement
    if (this.play.selectedFielderMovementIndex === null) {
      const fielders = toJS(this.play.playEvent.primaryFielderCredits)
      const fielder = fielders[idx]

      fielder[key] = value

      this.play.playEvent.primaryFielderCredits = fielders
      this.play.selectedFielders = fielders
    } else {
      const runnerMovement = toJS(
        this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
          this.play.selectedFielderMovementIndex
        ],
      )
      const fielders = runnerMovement.fielderCredits
      const fielder = fielders[idx]

      fielder[key] = value
      if (!runnerMovement.reason && key == 'credit') {
        switch (value) {
          case 'error':
          case 'throwing_error':
          case 'dropped_throw':
          case 'interference':
            runnerMovement.reason = MovementReasons.ERROR
            break
        }
      }

      this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
        this.play.selectedFielderMovementIndex
      ] = runnerMovement
      this.play.selectedFielders = fielders
    }
  }

  @action updateFlag(flagType, enabled) {
    let flags = toJS(this.play.playEvent.flags)
    console.log(flags)
    console.log(flagType)
    console.log(enabled)

    const flagMap = {}
    for (let i = 0; i < flags.length; i++) {
      flagMap[flags[i]] = true
    }

    if (enabled) {
      flagMap[flagType] = true
    } else {
      delete flagMap[flagType]
    }

    flags = []
    for (const flag in flagMap) {
      flags.push(flag)
    }

    console.log(flags)
    this.play.playEvent.flags = flags
  }

  @action updatePlay(key, value) {
    this.play[key] = value
  }

  @action addPitch() {
    this.play = {
      type: 'pitch',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      pitchCode: this.pitchCodes[0].value,
      playByPlay: this.pitchCodes[0].pbp,
      runnersGoing: false,
      reviews: [],
      violations: [],
    }
  }

  @action addSubGameState() {
    let subPlayers = []
    const teamPlayers = []
    const subType = this.play.subType
    let subTeam =
      subType == 'starters' ? this.play.subTeam : this.play.gameState.team // assign to batting team

    let lineup = []
    let hasDh = false
    if (subType == 'defense') {
      // flip for defensive sub
      if (this.play.gameState.team == 1) {
        subTeam = 0
        lineup = this.play.gameState.awayLineup
        hasDh = this.play.gameState.awayDH
      } else {
        subTeam = 1
        lineup = this.play.gameState.homeLineup
        hasDh = this.play.gameState.homeDH
      }
    } else if (subType == 'starters') {
      lineup = this.play.lineup
      if (this.store.game.isFlexibleRules) {
        for (var i = 0; i < lineup.length; i++) {
          var originalPlayer = lineup[i]
          if (originalPlayer.position == 10) {
            hasDh = true
            break
          }
        }
      } else {
        hasDh = lineup.length == 10
      }
    } else {
      if (this.play.gameState.team == 1) {
        lineup = this.play.gameState.homeLineup
        hasDh = this.play.gameState.homeDH
      } else {
        lineup = this.play.gameState.awayLineup
        hasDh = this.play.gameState.awayDH
      }
    }

    // do not mutate orignal game state
    for (var i = 0; i < lineup.length; i++) {
      var originalPlayer = lineup[i]
      var player = {
        player: originalPlayer.player,
        position: originalPlayer.position,
        isEnabled: originalPlayer.isEnabled,
        isInLineup: originalPlayer.isInLineup,
      }

      if (this.store.game.isFlexibleRules) {
        if (!player.isEnabled && !player.isInLineup) {
          continue
        }
        if (player.isInLineup) {
          player.batPos = i + 1
        }
      } else {
        if (i < 9) {
          player.batPos = i + 1
        } else {
          if (hasDh) {
            player.batPos = 0
          } else {
            continue
          }
        }
      }
      subPlayers.push(player)
      const teamPlayer = {
        player: player.player,
        position: player.position,
        batPos: player.batPos,
        isEnabled: player.isEnabled,
        isInLineup: player.isInLineup,
      }
      teamPlayers.push(teamPlayer)
    }

    if (subType == 'defense') {
      if (this.play.subs) {
        for (var i = 0; i < this.play.subs.length; i++) {
          var sub = this.play.subs[i]
          var subPlayer
          var batPos = parseInt(sub.batPos)
          if (this.store.game.isFlexibleRules) {
            subPlayer = subPlayers[batPos - 1]
          } else {
            if (batPos == 0) {
              subPlayer = subPlayers[subPlayers.length - 1]
            } else {
              subPlayer = subPlayers[batPos - 1]
            }
          }
          if (subPlayer) {
            subPlayer.position = sub.defPos
            subPlayer.player = sub.subPlayer
            subPlayer.hasChange = true
          }
        }
      }
    } else if (subType == 'pinchHitter') {
      var usedSub = false
      if (this.play.subs) {
        for (var i = 0; i < this.play.subs.length; i++) {
          var sub = this.play.subs[i]
          if (sub.defPos == 11) {
            var batPos = parseInt(sub.batPos)
            subPlayer = subPlayers[batPos - 1]
            subPlayer.position = sub.defPos
            subPlayer.player = sub.subPlayer
            subPlayer.hasChange = true
            usedSub = true
            subPlayers = [subPlayer]
            break
          }
        }
      }
      if (!usedSub) {
        for (var i = 0; i < subPlayers.length; i++) {
          var player = subPlayers[i]
          if (player.player == this.play.gameState.batter) {
            player.batOrder = i + 1
            subPlayers = [player]
            break
          }
        }
      }
    } else if (subType == 'pinchRunner') {
      var usedSub = false
      let runnerSubPlayers = []
      if (this.play.subs) {
        for (var i = 0; i < this.play.subs.length; i++) {
          var sub = this.play.subs[i]
          if (sub.defPos == 12) {
            var batPos = parseInt(sub.batPos)
            var subPlayer = subPlayers[batPos - 1]
            if (subPlayer) {
              subPlayer.position = sub.defPos
              subPlayer.player = sub.subPlayer
              subPlayer.hasChange = true
              usedSub = true
              runnerSubPlayers.push(subPlayer)
            }
          }
        }
      }

      if (!usedSub) {
        runnerSubPlayers = []
        for (var i = 1; i < this.play.gameState.runners.length; i++) {
          const runner = this.play.gameState.runners[i]
          if (runner) {
            for (let j = 0; j < subPlayers.length; j++) {
              var player = subPlayers[j]
              if (player.player == runner) {
                player.baseNum = i
                runnerSubPlayers.push(player)
              }
            }
          }
        }
      }
      subPlayers = runnerSubPlayers
    }

    this.play.subPlayers = subPlayers
    this.play.teamPlayers = teamPlayers
    this.play.subTeam = subTeam
  }

  @action editSub() {
    if (this.play.defPos == 11) {
      this.play.subType = 'pinchHitter'
    } else if (this.play.defPos == 12) {
      this.play.subType = 'pinchRunner'
    } else {
      this.play.subType = 'defense'
    }
    this.addSubGameState()
  }

  @action addSub(subType, lineup) {
    this.play = {
      subType: subType,
      lineup: lineup || [],
      type: 'sub',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      gameState: this.play.gameState,
      subPlayers: [],
      teamPlayers: [],
      subTeam: this.play.subTeam || 0,
    }

    this.addSubGameState()
  }

  @action addEject() {
    this.play = {
      type: 'eject',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      team: 0,
      umpire: this.gameUmpires.length ? this.gameUmpires[0].value : null,
      coach: null,
      player: null,
    }
  }

  @action addInjury() {
    this.play = {
      type: 'injury',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      team: 0,
      player: this.awayPlayers.length ? this.awayPlayers[0].id : null,
      injury: this.injuries.length ? this.injuries[0].value : null,
    }
  }

  @action addUmpSub() {
    this.play = {
      type: 'umpsub',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      umpire: this.umpires.length ? this.umpires[0].value : null,
      position: this.umpirePositions.length
        ? this.umpirePositions[0].value
        : null,
    }
  }

  @action addLineupAdjust() {
    let batTeam = 0
    let lineup = []
    if (this.play.gameState) {
      batTeam = this.play.gameState.team
      if (this.play.gameState.team == 1) {
        lineup = this.play.gameState.homeLineup
      } else {
        lineup = this.play.gameState.awayLineup
      }
    }

    const lineupPlayers = []

    // do not mutate orignal game state
    for (let i = 0; i < lineup.length; i++) {
      const originalPlayer = lineup[i]
      const player = {
        player: originalPlayer.player,
        position: originalPlayer.position,
        isInLineup: originalPlayer.isInLineup,
        isEnabled: originalPlayer.isEnabled,
      }
      if (this.store.game.isFlexibleRules) {
        if (!originalPlayer.isEnabled && !originalPlayer.isInLineup) {
          continue
        }
        player.batPos = i + 1
      } else {
        if (i < 9) {
          player.batPos = i + 1
        } else {
          continue
        }
      }
      player.value = player.batPos
      player.label = this.store.dware.getPlayerName(originalPlayer.player)
      player.legend = player.label
      lineupPlayers.push(player)
    }

    this.play = {
      type: 'ladj',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      team: this.play.gameState ? this.play.gameState.team : 0,
      batPos: 1,
      lineupPlayers: lineupPlayers,
    }
  }

  @action addBadj() {
    this.play = {
      type: 'badj',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      batSide: 'L',
      player: this.awayPlayers.length ? this.awayPlayers[0].id : null,
      team: 0,
    }
  }

  @action addPadj() {
    this.play = {
      type: 'padj',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      pitchHand: 'L',
      player: this.awayPlayers.length ? this.awayPlayers[0].id : null,
      team: 0,
    }
  }

  @action addMoundVisit() {
    this.play = {
      type: 'CV',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      moundVisit: 'Generic',
    }
  }

  @action addBatterTimeout() {
    this.play = {
      type: 'BT',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
    }
  }

  @action addRunner() {
    this.play = {
      type: 'runner',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      base: 1,
      team: 0,
      player: this.awayPlayers.length ? this.awayPlayers[0].id : null,
    }
  }

  @action addCom() {
    this.play = {
      type: 'com',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      com: this.commentaryOptions.length
        ? this.commentaryOptions[0].value
        : null,
    }
  }

  @action addPlay() {
    const { runners } = this.play.gameState

    const runnerMovements = {}
    for (let i = 1; i < 4; i++) {
      const runnerId = runners[i]
      if (runnerId) {
        runnerMovements[i] = {
          start: i,
          end: i,
        }
      }
    }

    this.play = {
      type: 'play',
      atBatIdx: this.play.atBatIdx,
      playIdx: this.play.playIdx,
      _playIdx: this.play._playIdx,
      eventCode: '',
      playString: '',
      reviews: [],
      violations: [],
      gameState: {},
      playEvent: {
        playType: '',
        runnerMovements: runnerMovements,
        primaryFielderCredits: [],
        flags: [],
        hitTrajectory: '',
        hitHardness: '',
      },
    }
  }

  @action addReview() {
    const reviews = toJS(this.play.reviews)
    const review = {
      idx: reviews.length,
      code: '',
      overturned: false,
      inProgress: true,
      overturnedCode: '',
      reviewStatus: 'MF',
      reviewedBy: 'away_team',
      playerId: '',
      playString: '',
    }

    reviews.push(review)
    this.play.reviews = reviews
  }

  @action deleteReview(idx) {
    const reviews = toJS(this.play.reviews)
    reviews.splice(idx, 1)

    for (let i = 0; i < reviews.length; i++) {
      const r = reviews[i]
      r.idx = i
    }

    this.play.reviews = reviews
  }

  @action addViolation() {
    const violations = toJS(this.play.violations)
    const violation = {
      idx: violations.length,
      playerId: '',
      violationType: 'Lateral Positioning Violation',
    }

    violations.push(violation)
    this.play.violations = violations
  }

  @action deleteViolation(idx) {
    const violations = toJS(this.play.violations)
    violations.splice(idx, 1)

    for (let i = 0; i < violations.length; i++) {
      const r = violations[i]
      r.idx = i
    }

    this.play.violations = violations
  }

  @action addRunnerMovement(shiftIndex) {
    const runner = toJS(
      this.play.playEvent.runnerMovements[this.play.selectedBase],
    )
    if (!runner.movements) {
      runner.movements = []
    }
    runner.movements.splice(shiftIndex, 0, {})
    this.play.playEvent.runnerMovements[this.play.selectedBase] = runner
  }

  @action deleteRunnerMovement(idx) {
    const runner = toJS(
      this.play.playEvent.runnerMovements[this.play.selectedBase],
    )
    runner.movements.splice(idx, 1)

    this.play.playEvent.runnerMovements[this.play.selectedBase] = runner
  }

  @action addFielder(shiftIndex) {
    // bad hack to distinguish if we are editing the primary fielder sequence, or a fielder sequence within a runner movement
    if (this.play.selectedFielderMovementIndex === null) {
      const fielders = toJS(this.play.playEvent.primaryFielderCredits)

      fielders.splice(shiftIndex, 0, {})
      this.play.playEvent.primaryFielderCredits = fielders
      this.play.selectedFielders = fielders
    } else {
      let fielders = toJS(
        this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
          this.play.selectedFielderMovementIndex
        ].fielderCredits,
      )
      fielders = fielders || []
      fielders.splice(shiftIndex, 0, {})
      this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
        this.play.selectedFielderMovementIndex
      ].fielderCredits = fielders
      this.play.selectedFielders = fielders
    }
  }

  @action deleteFielder(idx) {
    // bad hack to distinguish if we are editing the primary fielder sequence, or a fielder sequence within a runner movement
    if (this.play.selectedFielderMovementIndex === null) {
      const fielders = toJS(this.play.playEvent.primaryFielderCredits)

      fielders.splice(idx, 1)
      this.play.playEvent.primaryFielderCredits = fielders
      this.play.selectedFielders = fielders
    } else {
      const fielders = toJS(
        this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
          this.play.selectedFielderMovementIndex
        ].fielderCredits,
      )
      fielders.splice(idx, 1)

      this.play.playEvent.runnerMovements[this.play.selectedBase].movements[
        this.play.selectedFielderMovementIndex
      ].fielderCredits = fielders
      this.play.selectedFielders = fielders
    }
  }

  @action addFlag() {
    const flags = toJS(this.play.playEvent.flags)

    flags.push('')
    this.play.playEvent.flags = flags
  }

  @action deleteFlag(idx) {
    const flags = toJS(this.play.playEvent.flags)
    flags.splice(idx, 1)

    this.play.playEvent.flags = flags
  }

  @computed get disabled() {
    if (
      [
        EditTypes.EDIT_PLAY,
        EditTypes.EDIT_RESULT,
        EditTypes.ADD_PLAY,
        EditTypes.EDIT_FLAGS,
        EditTypes.EDIT_TRAJECTORY,
        EditTypes.EDIT_RUNNERS,
        EditTypes.EDIT_RUNNER_MOVEMENT,
        EditTypes.EDIT_FIELDERS,
      ].includes(this.type)
    ) {
      return false
    }

    if (this.type == EditTypes.ADD_EJECT) {
      if (!this.play.umpire) {
        return true
      }

      if (!this.play.player && !this.play.coach) {
        return true
      }
    }

    if (this.type == EditTypes.ADD_PITCH) {
      return false
    }

    if (
      this.type == EditTypes.DELETE_EVENT ||
      this.type == EditTypes.DELETE_SUB
    ) {
      return false
    }

    // subs are handled differently; they are treated now as a batch of edits, with each indiviudal edit representing a different substitution
    if (
      this.type == EditTypes.ADD_SUB ||
      this.type == EditTypes.EDIT_SUB ||
      this.type == EditTypes.EDIT_STARTERS
    ) {
      var changeSeen = false
      for (var i = 0; i < this.play.subPlayers.length; i++) {
        var subPlayer = this.play.subPlayers[i]
        if (subPlayer.isInvalid) {
          return true
        } else if (subPlayer.hasChange) {
          changeSeen = true
        }
      }
      if (!changeSeen) {
        return true
      }

      return false
    }
    if (this.type == EditTypes.EDIT_UMPIRES) {
      var changeSeen = false
      for (var i = 0; i < this.play.umpires.length; i++) {
        var subPlayer = this.play.umpires[i]
        if (subPlayer.isInvalid) {
          return true
        } else if (subPlayer.hasChange) {
          changeSeen = true
        }
      }
      if (!changeSeen) {
        return true
      }

      return false
    }

    if (this.type == EditTypes.EDIT_SCOREKEEPERS) {
      var changeSeen = false
      for (const key in this.play.scoreKeepers) {
        const scoreKeeper = this.play.scoreKeepers[key]
        if (scoreKeeper && scoreKeeper.isInvalid) {
          return true
        } else if (scoreKeeper && scoreKeeper.hasChange) {
          changeSeen = true
        }
      }
      if (!changeSeen) {
        return true
      }

      return false
    }

    if (this.type == EditTypes.EDIT_WEATHER) {
      return !this.play.weather.hasChange
    }

    for (const key in this.play) {
      if (this.play.hasOwnProperty(key) && typeof this.play[key] !== 'object') {
        if (this.play[key] != this._play[key]) {
          return false
        }
      }
    }

    if (this.type == EditTypes.EDIT_REVIEWS) {
      if (this.play.reviews.length != this._play.reviews.length) {
        return false
      }

      for (var i = 0; i < this.play.reviews.length; i++) {
        const review = this.play.reviews[i]
        const _review = this._play.reviews[i]

        for (const key in review) {
          if (review.hasOwnProperty(key) && typeof review[key] !== 'object') {
            if (review[key] != _review[key]) {
              return false
            }
          }
        }
      }
    }

    if (this.type == EditTypes.EDIT_VIOLATIONS) {
      if (this.play.violations.length != this._play.violations.length) {
        return false
      }

      for (var i = 0; i < this.play.violations.length; i++) {
        const violation = this.play.violations[i]
        const _violation = this._play.violations[i]

        for (const key in violation) {
          if (
            violation.hasOwnProperty(key) &&
            typeof violation[key] !== 'object'
          ) {
            if (violation[key] != _violation[key]) {
              return false
            }
          }
        }
      }
    }

    return true
  }

  @action confirm() {
    if (!this.play.updates) {
      this.play.updates = {}
    }

    const updates = this.play.updates

    const update = {
      originalPlayIdx: this.play._playIdx,
      atBatIdx: this.play.atBatIdx,
      playIdx:
        this.play._playIdx || this.play._playIdx == 0
          ? this.play._playIdx
          : this.play.playIdx,
    }

    const isDelete = this.type == EditTypes.DELETE_EVENT
    const isInsert = [].includes(this.type)
    const isUpdate = [].includes(this.type)

    let player
    let position
    let batSide
    let pitchHand

    switch (this.type) {
      case EditTypes.ADD_AT_BAT_START:
        Object.assign(update, {
          action: 'insert',
          keyName: 'batter',
          type: 'batter',
          timecode: this.play.timecode,
          playByPlay: '',
        })

        this.play.updates.edit = update

        break
      case EditTypes.ADD_PITCH:
        Object.assign(update, {
          action: 'insert',
          keyName: 'pitchCode',
          runnersGoing: this.play.runnersGoing,
          type: 'pitch',
          value: this.play.pitchCode,
          playByPlay: this.play.playByPlay,
        })

        this.play.updates.edit = update

        break

      case EditTypes.DELETE_SUB:
      case EditTypes.DELETE_EVENT:
        Object.assign(update, {
          action: 'delete',
          type: 'delete',
          operatorChangeReason:
            this.play.operatorChangeReasons?.[this.type] || null,
        })

        this.play.updates.edit = update

        break

      case EditTypes.EDIT_PITCH:
        Object.assign(update, {
          action: 'update',
          type: 'pitch',
          atBatIdx: this.play.atBatIdx,
          playIdx: this.play.playIdx,
          keyName: 'pitchCode',
          runnersGoing: this.play.runnersGoing,
          value: this.play.pitchCode,
          coordX: this.play.coordX,
          coordY: this.play.coordY,
          operatorChangeReason:
            this.play.operatorChangeReasons?.[this.type] || null,
        })

        if (this.play.updates.edit) {
          // this play already has an update

          if (this.play.updates.edit.action == 'insert') {
            update.action = 'insert'
          }
        }

        this.play.updates.edit = update

        break

      case EditTypes.EDIT_PLAY:
      case EditTypes.EDIT_TRAJECTORY:
      case EditTypes.EDIT_FLAGS:
      case EditTypes.EDIT_FIELDERS:
      case EditTypes.EDIT_RUNNER_MOVEMENT:
      case EditTypes.EDIT_RUNNERS:
        Object.assign(update, {
          action: 'update',
          type: this.play.type,
          keyName: 'eventCode',
          value: this.play.eventCode,
          gameState: this.play.gameState,
          playEvent: this.play.playEvent,
          operatorChangeReason:
            this.play.operatorChangeReasons?.[this.type] || null,
        })

        if (this.play.playEvent && this.play.playEvent.runnerMovements) {
          for (const runnerBase in this.play.playEvent.runnerMovements) {
            var runner = this.play.playEvent.runnerMovements[runnerBase]
            delete runner.hasChange
          }
        }

        if (this.play.updates.edit) {
          // this play already has an update
          if (this.play.updates.edit.action == 'insert') {
            update.action = 'insert'
          }
        }

        // if we insert a baserunning play, then edit it, we need to keep the insert action
        if (this.play.isInserted) {
          update.action = 'insert'
        }

        this.play.updates.edit = update

        break

      case EditTypes.ADD_PLAY:
        var atBats = this.store.game.stringerData.atBats
        var previousPlay = null
        for (var i = update.atBatIdx; i >= 0; i--) {
          const atBat = atBats[i]
          if (atBat) {
            for (let j = update.playIdx; j >= 0; j--) {
              const atBatPlay = atBat.plays[j]
              if (
                atBatPlay &&
                (atBatPlay.type == 'play' || atBatPlay.type == 'result')
              ) {
                previousPlay = atBatPlay
                break
              }
            }
          }
          if (previousPlay) {
            break
          }
        }
        if (
          previousPlay &&
          previousPlay.playEvent &&
          previousPlay.playEvent.runnerMovements
        ) {
          for (const base in previousPlay.playEvent.runnerMovements) {
            var runner = previousPlay.playEvent.runnerMovements[base]
            if (runner.end != 'H') {
              let isOut = false
              if (runner.movements) {
                for (let k = 0; k < runner.movements.length; k++) {
                  if (runner.movements[k].out) {
                    isOut = true
                    break
                  }
                }
              }
              if (!isOut) {
                this.play.playEvent.runnerMovements[runner.end] = {
                  start: runner.end,
                  end: runner.end,
                  movements: [],
                }
              }
            }
          }
        }

        Object.assign(update, {
          action: 'insert',
          type: this.play.type,
          keyName: 'eventCode',
          value: this.play.eventCode,
          gameState: this.play.gameState,
          playEvent: this.play.playEvent,
        })
        this.play.updates.edit = update
        const id =
          'ab-table-' +
          update.atBatIdx +
          '-' +
          update.playIdx +
          '-play-edit-button'
        // this.trigger(id, EditTypes.EDIT_PLAY_TABLE, null)

        break

      case EditTypes.EDIT_REVIEWS:
        Object.assign(update, {
          action: 'update',
          type: 'reviewBatch',
          atBatIdx: this.play.atBatIdx,
          playIdx: this.play.playIdx,
          reviews: this.play.reviews,
        })

        this.play.updates.review = update

        break

      case EditTypes.EDIT_VIOLATIONS:
        Object.assign(update, {
          action: 'update',
          type: 'violationBatch',
          atBatIdx: this.play.atBatIdx,
          playIdx: this.play.playIdx,
          violations: this.play.violations,
        })

        this.play.updates.violation = update

        break

      // for starters, just send the whole list over
      case EditTypes.EDIT_STARTERS:
        Object.assign(update, {
          action: 'update',
          type: 'starters',
          team: this.play.subTeam,
          lineup: this.play.subPlayers,
        })

        this.play.updates.edit = update
        break

      // for starting umpires, just send the whole list over
      case EditTypes.EDIT_UMPIRES:
        Object.assign(update, {
          action: 'update',
          type: 'umpires',
          umpires: this.play.umpires,
        })

        this.play.updates.edit = update
        break

      // for scorekeepeers
      case EditTypes.EDIT_SCOREKEEPERS:
        Object.assign(update, {
          action: 'update',
          type: 'scorekeepers',
          scoreKeepers: this.play.scoreKeepers,
        })

        this.play.updates.edit = update
        break

      // weather info
      case EditTypes.EDIT_WEATHER:
        Object.assign(update, {
          action: 'update',
          type: 'weather',
          weather: this.play.weather,
        })

        this.play.updates.edit = update
        break

      // for subs, we are treating them as a batch of edits for simplicity; we will delete all the sub entries in the old batch and replace them with new entries
      // this will help us ensure we don't have situations where there are 2 right fielders or no left fielders, etc... Additionally, it prevents us from having
      // duplicate sub entries as well
      case EditTypes.EDIT_SUB:
      case EditTypes.ADD_SUB:
        this.play.updates.edits = []

        // for editing batch subs we will do a delete/insert
        if (this.type == EditTypes.EDIT_SUB) {
          if (this.play.subs) {
            // these deletes simply are removing a sub, not adding a new one
            const restoreOriginalBatOrders = {}
            for (var i = 0; i < this.play.subPlayers.length; i++) {
              var subPlayer = this.play.subPlayers[i]
              if (subPlayer.restoreOriginalEntry) {
                restoreOriginalBatOrders[subPlayer.batPos] = subPlayer
              }
            }

            for (var i = 0; i < this.play.subs.length; i++) {
              const previousSub = this.play.subs[i]

              const subDelete = {
                atBatIdx: previousSub.atBatIdx,
                playIdx: previousSub.playIdx,
                playByPlay: previousSub.playByPlay,
                batPos: previousSub.batPos,
                defPos: previousSub.defPos,
                type: previousSub.type,
                team: Number(this.play.subTeam),
                position: String(previousSub.position),
                keyName: 'playString',
              }

              if (restoreOriginalBatOrders[parseInt(previousSub.batPos)]) {
                subDelete.restoreOriginalEntry = true
              }

              Object.assign(subDelete, {
                action: 'delete',
                type: 'delete',
                operatorChangeReason:
                  this.play.operatorChangeReasons?.[this.type] || null,
              })

              this.play.updates.edits.push(subDelete)
            }
          }
        }

        this.play.updates.edit = update

        // remove duplicates for a bat order slot
        var filteredSubPlayers = []
        var filteredSubPlayerBatOrderMap = {}
        for (var i = 0; i < this.play.subPlayers.length; i++) {
          var subPlayer = this.play.subPlayers[i]
          if (subPlayer.restoreOriginalEntry) {
            continue // dont add this to the edits, as it will be fixed with the preceding delete
          }
          if (subPlayer.hasChange) {
            if (filteredSubPlayerBatOrderMap[subPlayer.batPos]) {
              filteredSubPlayerBatOrderMap[subPlayer.batPos] = subPlayer
              continue
            }
            filteredSubPlayers.push(subPlayer)
            filteredSubPlayerBatOrderMap[subPlayer.batPos] =
              filteredSubPlayers.length
          }
        }

        for (var i = 0; i < filteredSubPlayers.length; i++) {
          var subPlayer = filteredSubPlayers[i]
          if (subPlayer.restoreOriginalEntry) {
            continue // dont add this to the edits, as it will be fixed with the preceding delete
          }
          if (subPlayer.hasChange) {
            const subUpdate = {
              atBatIdx: update.atBatIdx,
              playIdx: update.playIdx,
            }
            player = this.store.dware.getPlayerFullName(subPlayer.player) || ''
            position = this.store.dware.getPosition(subPlayer.position)

            let subPlayByPlay = `${player} to ${position}`

            if (subPlayer.batPos > 0) {
              const batOrd = this.store.dware.numberWithOrdinal(
                subPlayer.batPos,
              )
              subPlayByPlay += `, batting ${batOrd}`
            }

            const subObject = {
              action: 'insert',
              type: 'sub',
              team: Number(this.play.subTeam),
              player: Number(subPlayer.player),
              position: String(subPlayer.position),
              batPos: subPlayer.batPos,
              defPos: subPlayer.position,
              keyName: 'playString',
              playByPlay: subPlayByPlay,
              operatorChangeReason:
                this.play.operatorChangeReasons?.[this.type] || null,
            }

            if (this.store.game.isFlexibleRules) {
              subObject.isInLineup = subPlayer.isInLineup
            }

            Object.assign(subUpdate, subObject)

            this.play.updates.edits.push(subUpdate)
          }
        }

        break

      case EditTypes.ADD_BADJ:
        player = this.getPersonNameById(this.play.player)
        batSide = this.getHandByCode(this.play.batSide)
        var badjPlayByPlay = `Now batting ${batSide}`

        Object.assign(update, {
          action: 'insert',
          type: 'badj',
          keyName: 'playString',
          player: this.play.player,
          team: this.play.team,
          batSide: this.play.batSide,
          playByPlay: badjPlayByPlay,
        })

        this.play.updates.edit = update

        this.play.playString = `badj,${this.play.player},${this.play.batSide}`
        update.playString = `Now batting ${batSide}`

        break

      case EditTypes.ADD_MOUND_VISIT:
        var moundVisitPlayByPlay =
          this.play.moundVisit !== 'Generic'
            ? `Mound Visit (${this.play.moundVisit})`
            : 'Mound Visit'

        Object.assign(update, {
          action: 'insert',
          type: 'CV',
          keyName: 'CV',
          moundVisit: this.play.moundVisit,
          playByPlay: moundVisitPlayByPlay,
        })

        this.play.updates.edit = update

        update.playString =
          this.play.moundVisit !== 'Generic'
            ? `Mound Visit (${this.play.moundVisit})`
            : 'Mound Visit'

        break

      case EditTypes.EDIT_MOUND_VISIT:
        Object.assign(update, {
          action: 'update',
          type: 'CV',
          keyName: 'CV',
          moundVisit: this.play.moundVisit,
        })

        this.play.playByPlay =
          this.play.moundVisit !== 'Generic'
            ? `Mound Visit (${this.play.moundVisit})`
            : 'Mound Visit'
        this.play.updates.edit = update
        break

      case EditTypes.ADD_BATTER_TIMEOUT:
        var batterTimeoutPlayByPlay = `Batter Timeout`

        Object.assign(update, {
          action: 'insert',
          type: 'BT',
          keyName: 'BT',
          batterTimeout: this.play.batterTimeout,
          playByPlay: batterTimeoutPlayByPlay,
        })

        this.play.updates.edit = update

        update.playString = `Batter Timeout`

        break

      case EditTypes.ADD_COM:
        var comPlayByPlay = this.play.com

        Object.assign(update, {
          action: 'insert',
          type: 'com',
          keyName: 'com',
          com: this.play.com,
          playByPlay: comPlayByPlay,
        })

        this.play.updates.edit = update

        break

      case EditTypes.ADD_EJECT:
        var ejectPlayByPlay = `${this.store.dware.getPlayerFullName(
          this.play.player || this.play.coach,
        )} ejected by ${this.store.dware.getPlayerFullName(this.play.umpire)}`

        Object.assign(update, {
          action: 'insert',
          type: 'eject',
          keyName: 'playString',
          team: Number(this.play.team),
          umpire: this.play.umpire ? Number(this.play.umpire) : null,
          player: this.play.player ? Number(this.play.player) : null,
          coach: this.play.coach ? Number(this.play.coach) : null,
          playByPlay: ejectPlayByPlay,
        })

        this.play.updates.edit = update

        this.play.playString = `eject,${this.play.player || this.play.coach},${
          this.play.umpire
        }`

        break

      case EditTypes.ADD_INJURY:
        var injuryPlayByPlay = `Injury: ${this.store.dware.getPlayerFullName(
          this.play.player,
        )} (${this.play.injury})`

        Object.assign(update, {
          action: 'insert',
          type: 'injury',
          keyName: 'playString',
          team: this.play.team,
          player: this.play.player,
          injury: this.play.injury,
          playByPlay: injuryPlayByPlay,
        })

        this.play.updates.edit = update

        this.play.playString = `injury,${this.play.player},"${this.play.injury}"`

        break

      case EditTypes.ADD_PADJ:
        player = this.getPersonNameById(this.play.player)
        pitchHand = this.getHandByCode(this.play.pitchHand)

        var padjPlayByPlay = `Now pitching ${pitchHand}`

        Object.assign(update, {
          action: 'insert',
          type: 'padj',
          keyName: 'playString',
          player: this.play.player,
          team: this.play.team,
          pitchHand: this.play.pitchHand,
          playByPlay: padjPlayByPlay,
        })

        this.play.updates.edit = update

        this.play.playString = `padj,${this.play.player},${this.play.pitchHand}`
        update.playString = `Now pitching ${pitchHand}`

        break

      case EditTypes.ADD_UMPSUB:
        var umpSubPlayByPlay = `Umpire ${this.store.dware.getPlayerFullName(
          this.play.umpire,
        )} to ${this.getUmpirePositionByCode(this.play.position)}`

        Object.assign(update, {
          action: 'insert',
          type: 'umpsub',
          keyName: 'playString',
          umpire: this.play.umpire ? Number(this.play.umpire) : null,
          position: this.play.position ? String(this.play.position) : null,
          playByPlay: umpSubPlayByPlay,
        })

        this.play.updates.edit = update

        break

      case EditTypes.ADD_LINEUP_ADJUST:
        var booPlayer = this.play.lineupPlayers[parseInt(this.play.batPos)]
        var booPlayerName = ''
        if (booPlayer != null) {
          booPlayerName = this.store.dware.getPlayerFullName(booPlayer.player)
        }
        var booText = ((booPlayerName || '') + ' batted out of order').trim()

        var battingOutOfOrderCommentary = {}
        Object.assign(battingOutOfOrderCommentary, update, {
          action: 'insert',
          type: 'com',
          keyName: 'com',
          playByPlay: booText,
          com: booText,
        })

        var booPlayByPlay =
          'Lineup adjust for ' +
          (this.play.team == 1 ? 'home' : 'away') +
          ' team. Batting order now at ' +
          this.play.batPos +
          ' slot'

        Object.assign(update, {
          action: 'insert',
          type: 'ladj',
          keyName: 'playString',
          team: this.play.team ? Number(this.play.team) : null,
          batPos: this.play.batPos ? Number(this.play.batPos) : null,
          playByPlay: booPlayByPlay,
        })

        this.play.updates.edits = []
        this.play.updates.edits.push(battingOutOfOrderCommentary)
        this.play.updates.edits.push(update)
        this.play.updates.edit = update

        break

      case EditTypes.ADD_RUNNER:
        var runnerPlayByPlay = `Runner Placement: ${this.store.dware.getPlayerFullName(
          this.play.player,
        )} to ${this.play.base}`

        Object.assign(update, {
          action: 'insert',
          type: 'runner',
          keyName: 'playString',
          player: this.play.player,
          team: this.play.team,
          base: this.play.base,
          playByPlay: runnerPlayByPlay,
        })

        this.play.updates.edit = update

        break

      // No updates being entered for uniform edits
      case EditTypes.EDIT_UNIFORMS:
        break
      default:
        throw new Error(`No flow configured for edit of type: ${this.type}`)
    }

    if (update && this.play.playByPlay) {
      update.playByPlay = this.play.playByPlay
    } else if (update && update.playByPlay) {
      this.play.playByPlay = update.playByPlay
    }

    this.apply()
  }

  @action apply() {
    // for pre-game info, we aren't actually updating an at bat or a play
    const editType = this.type

    if (
      [
        EditTypes.EDIT_STARTERS,
        EditTypes.EDIT_UMPIRES,
        EditTypes.EDIT_SCOREKEEPERS,
        EditTypes.EDIT_WEATHER,
      ].includes(editType)
    ) {
      if (this.play.updates && this.play.updates.edit) {
        switch (editType) {
          case EditTypes.EDIT_STARTERS:
            if (this.play.subTeam == 0) {
              this.store.game.nonEventUpdates.awayStarters = this.play
            } else if (this.play.subTeam == 1) {
              this.store.game.nonEventUpdates.homeStarters = this.play
            }
            break
          case EditTypes.EDIT_UMPIRES:
            this.store.game.nonEventUpdates.umpires = this.play
            break
          case EditTypes.EDIT_SCOREKEEPERS:
            this.store.game.nonEventUpdates.scoreKeepers = this.play
            break
          case EditTypes.EDIT_WEATHER:
            this.store.game.nonEventUpdates.weather = this.play
            break
        }
      }
      this.close()
      return
    }

    if (editType === EditTypes.EDIT_UNIFORMS) {
      this.play.loadingUniforms = true
      this.store.uniforms
        .saveUniforms(this.play.gameUniforms, this.play.isHomeTeam)
        .then(() => this.close())
      return
    }

    const _atBat = this.store.game.stringerData.atBats[this.play.atBatIdx]
    let _plays = toJS(_atBat.plays)
    const _play = _plays[this.play.playIdx]
    const play = toJS(this.play)

    if (
      [
        EditTypes.ADD_BADJ,
        EditTypes.ADD_COM,
        EditTypes.ADD_EJECT,
        EditTypes.ADD_INJURY,
        EditTypes.ADD_PADJ,
        EditTypes.ADD_PITCH,
        EditTypes.ADD_AT_BAT_START,
        EditTypes.ADD_PLAY,
        EditTypes.ADD_RUNNER,
        EditTypes.ADD_UMPSUB,
        EditTypes.ADD_MOUND_VISIT,
        EditTypes.ADD_BATTER_TIMEOUT,
      ].includes(this.type)
    ) {
      for (let i = 0; i < _plays.length; i++) {
        const play = _plays[i]
        if (!play._playIdx && play._playIdx != 0) {
          play._playIdx = play.playIdx
        }
      }

      _plays.splice(this.play.playIdx, 0, play)

      for (let i = 0; i < _plays.length; i++) {
        _plays[i].playIdx = i
      }
      // for batting out of order need to add a commentary line as well
    } else if ([EditTypes.ADD_LINEUP_ADJUST].includes(this.type)) {
      for (let i = 0; i < _plays.length; i++) {
        const play = _plays[i]
        if (!play._playIdx && play._playIdx != 0) {
          play._playIdx = play.playIdx
        }
      }

      if (this.play.updates && this.play.updates.edits) {
        for (var i = 0; i < this.play.updates.edits.length; i++) {
          var update = this.play.updates.edits[i]
          var playClone = {
            atBatIdx: update.atBatIdx,
            playIdx: update.playIdx,
            _playIdx: update.playIdx,
            gameState: play.gameState,
            playByPlay: update.playByPlay,
            batPos: update.batPos,
            team: update.team,
            type: update.type,
          }
          playClone.updates = {
            edit: update,
          }
          _plays.splice(playClone.playIdx, 0, playClone)
        }
      }

      for (let i = 0; i < _plays.length; i++) {
        _plays[i].playIdx = i
      }

      // for subs, we are treating them as a batch of edits for simplicity; we will delete all the sub entries in the old batch and replace them with new entries
      // this will help us ensure we don't have situations where there are 2 right fielders or no left fielders, etc... Additionally, it prevents us from having
      // duplicate sub entries as well
    } else if ([EditTypes.ADD_SUB, EditTypes.EDIT_SUB].includes(this.type)) {
      for (let i = 0; i < _plays.length; i++) {
        const play = _plays[i]
        if (!play._playIdx && play._playIdx != 0) {
          play._playIdx = play.playIdx
        }
      }

      if (this.type == EditTypes.EDIT_SUB && play.subs) {
        // remove the original subs
        const newPlays = []
        const deletePlays = {}

        // we are checking here to see if a substitution was removed, and the previous player is back in the game.
        // in these cases, we treat it as only a deletion, and we don't enter a new sub event. This prevents us from having
        // something like "Bryce Harper to RF, replacing Bryce Harper"
        const restoredPlayers = {}
        if (this.play.updates && this.play.updates.edits) {
          for (var i = 0; i < this.play.updates.edits.length; i++) {
            var update = this.play.updates.edits[i]
            if (update.restoreOriginalEntry) {
              restoredPlayers[update.playIdx] = update
            }
          }
        }

        for (var i = 0; i < play.subs.length; i++) {
          // remove the originals
          if (!restoredPlayers[play.subs[i].playIdx]) {
            deletePlays[play.subs[i].playIdx] = true
          }
        }
        for (var i = 0; i < _plays.length; i++) {
          if (!deletePlays[_plays[i].playIdx]) {
            const restoredPlayer = restoredPlayers[_plays[i].playIdx]
            if (restoredPlayer) {
              _plays[i].updates = {
                edit: restoredPlayer,
              }
            }
            newPlays.push(_plays[i])
          }
        }
        _plays = newPlays
      }

      // since we are treating subs as a batch, we can have multiple edits on a single entry
      if (this.play.updates && this.play.updates.edits) {
        for (var i = 0; i < this.play.updates.edits.length; i++) {
          var update = this.play.updates.edits[i]
          if (update.restoreOriginalEntry) {
            continue
          }
          var playClone = {
            atBatIdx: update.atBatIdx,
            playIdx: update.playIdx,
            _playIdx: update.playIdx,
            gameState: play.gameState,
            playByPlay: update.playByPlay,
            batPos: update.batPos,
            defPos: update.defPos,
            position: update.position,
            team: update.team,
            player: update.player,
            type: update.type,
          }
          playClone.updates = {
            edit: update,
          }
          _plays.splice(playClone.playIdx, 0, playClone)
        }
      }

      for (let i = 0; i < _plays.length; i++) {
        _plays[i].playIdx = i
      }
    } else {
      Object.assign(_play, {
        eventCode: play.eventCode,
        updates: play.updates,
        reviews: play.reviews,
        violations: play.violations,
        pitchCode: play.pitchCode,
        runnersGoing: play.runnersGoing,
        playByPlay: play.playByPlay,
        batPos: play.batPos,
        defPos: play.defPos,
        playString: play.playString,
        gameState: play.gameState,
        playEvent: play.playEvent,
        pitchClassfication: play.pitchClassfication,
        coordX: play.coordX,
        coordY: play.coordY,
      })
    }

    switch (editType) {
      case EditTypes.EDIT_PLAY:
      case EditTypes.EDIT_TRAJECTORY:
      case EditTypes.EDIT_FLAGS:
      case EditTypes.EDIT_FIELDERS:
      case EditTypes.EDIT_RUNNER_MOVEMENT:
      case EditTypes.EDIT_RUNNERS:
        if (this.store.game.page != 'plays') {
          this.trigger(null, EditTypes.EDIT_PLAY_TABLE, this.play)
        } else {
          if (
            editType === EditTypes.EDIT_RUNNER_MOVEMENT &&
            this.store.game.page == 'plays'
          ) {
          } else if (
            editType === EditTypes.EDIT_FIELDERS &&
            this.store.game.page == 'plays' &&
            this.play.selectedFielderMovementIndex !== null
          ) {
          } else {
            this.close()
          }
        }
        break
      default:
        this.close()
        break
    }

    setTimeout(() => {
      _atBat.plays = Object.assign([], _plays)
      switch (editType) {
        case EditTypes.ADD_PLAY:
        case EditTypes.EDIT_PLAY:
        case EditTypes.EDIT_TRAJECTORY:
        case EditTypes.EDIT_FLAGS:
        case EditTypes.EDIT_FIELDERS:
        case EditTypes.EDIT_RUNNER_MOVEMENT:
        case EditTypes.EDIT_RUNNERS:
        case EditTypes.ADD_RUNNER:
          this.resetGameState(this.play)
          break
      }

      switch (editType) {
        case EditTypes.ADD_SUB:
        case EditTypes.EDIT_SUB:
        case EditTypes.EDIT_STARTERS:
        case EditTypes.ADD_RUNNER:
          this.store.game.resetLineups()
          break
      }
      if (editType === EditTypes.ADD_PLAY && this.store.game.page != 'plays') {
        this.trigger(
          `ab-table-${this.play.atBatIdx}-${this.play.playIdx}-play-edit-button`,
          EditTypes.EDIT_PLAY_TABLE,
          this.play,
        )
      } else if (
        editType === EditTypes.EDIT_RUNNER_MOVEMENT &&
        this.store.game.page == 'plays'
      ) {
        this.trigger(null, EditTypes.EDIT_RUNNERS, this.play)
      } else if (
        editType === EditTypes.EDIT_FIELDERS &&
        this.store.game.page == 'plays' &&
        this.play.selectedFielderMovementIndex !== null
      ) {
        this.trigger(null, EditTypes.EDIT_RUNNERS, this.play)
      }
      this.store.game.setEventFilter('')
    }, 150)
  }

  @observable loading = false

  @computed get peopleMap() {
    const ppl = this.players.concat(this.umpires).concat(this.coaches)

    return ppl.reduce((map, person) => {
      if (person.value) {
        map[person.value] = person
      }
      return map
    }, {})
  }

  getPersonById(id) {
    return this.peopleMap[id] || null
  }

  getPersonNameById(id) {
    const person = this.getPersonById(id)
    return (person && person.label) || null
  }

  getHandByCode(code) {
    return code == 'R' ? 'right-handed' : 'left-handed'
  }

  @computed get umpirePositionMap() {
    return this.umpirePositions.reduce((map, pos) => {
      map[pos.value] = pos.label
      return map
    }, {})
  }

  getUmpirePositionByCode(code) {
    return this.umpirePositionMap[code]
  }

  @computed get eventCodeValidation() {
    let valid
    let help

    try {
      const pbp = window.Diamondware.getEventPlayByPlay(this.play.eventCode)
      valid = JSON.parse(pbp[1])

      if (valid) {
        help = 'Play string is valid.'
      } else {
        help = pbp[0]
      }
    } catch (e) {
      console.error(e)
      help = 'Error parsing play string.'
    }

    return {
      help,
      valid,
    }
  }

  @computed get popover() {
    return (
      <div className='card popover-card' onClick={(e) => e.stopPropagation()}>
        <header
          className='card-header'
          style={{
            backgroundColor: 'whitesmoke',
          }}
        >
          <p className='card-header-title is-capitalized'>{this.title}</p>
          <a
            href='#'
            className='card-header-icon'
            aria-label='close'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.close()
            }}
          >
            <span className='icon has-text-black'>
              <i className='fa fa-times' aria-hidden='true' />
            </span>
          </a>
        </header>
        {this.content}
        {this.footer}
      </div>
    )
  }

  initialize() {}
}

const ResultButton = inject('store')(
  observer((props) => {
    const { event = {}, trigger } = props

    const { gameState = {}, playEvent = {}, atBatIdx, playIdx } = event

    let { playType: displayEvent, primaryFielderCredits = [] } = playEvent

    if (!displayEvent) {
      return null
    }

    displayEvent = getEventDisplay(displayEvent, primaryFielderCredits)

    if (playEvent.flags && playEvent.flags.length > 0) {
      displayEvent += ' (' + playEvent.flags.join(',') + ')'
    }

    return (
      <button
        className='button is-small'
        onClick={(e) => {
          e.stopPropagation()
          trigger.trigger(null, EditTypes.EDIT_PLAY, event.result || event)
        }}
      >
        {displayEvent}
      </button>
    )
  }),
)

const TrajectoryButton = inject('store')(
  observer((props) => {
    const { event = {}, trigger } = props

    const { gameState = {}, playEvent = {}, atBatIdx, playIdx } = event

    let {
      primaryFielderCredits = [],
      hitTrajectory,
      hitHardness,
      hitLocation,
    } = playEvent

    if (!hitTrajectory) {
      hitTrajectory = '+'
    }

    let displayTrajectory = hitTrajectory
    if (hitHardness) {
      displayTrajectory += ` (${hitHardness})`
    }

    const flagMap = {}
    if (playEvent.flags) {
      for (let i = 0; i < playEvent.flags.length; i++) {
        flagMap[playEvent.flags[i]] = true
      }
    }

    if (
      ((playEvent.playType == PlayTypes.HOME_RUN && !flagMap['ITP']) ||
        (playEvent.playType == PlayTypes.DOUBLE && flagMap['GR'])) &&
      hitLocation &&
      hitLocation.fieldLocation
    ) {
      displayTrajectory += ' (' + hitLocation.fieldLocation + ')'
    }

    if (playEvent.isFoulBall) {
      displayTrajectory += ' (Foul)'
    }

    const id = `ab-table-${atBatIdx}-${playIdx}-trajectory`

    return (
      <button
        className='button is-small'
        onClick={(e) => {
          e.stopPropagation()
          trigger.trigger(
            null,
            EditTypes.EDIT_TRAJECTORY,
            event.result || event,
          )
        }}
      >
        {displayTrajectory}
      </button>
    )
  }),
)

const LocationButton = inject('store')(
  observer((props) => {
    const { event = {}, trigger } = props

    const { gameState = {}, playEvent = {}, atBatIdx, playIdx } = event

    const { primaryFielderCredits, hitLocation } = playEvent

    if (
      (primaryFielderCredits && primaryFielderCredits.length > 0) ||
      !hitLocation ||
      !hitLocation.fieldLocation
    ) {
      return null
    }

    const displayLocation = hitLocation.fieldLocation

    const id = `ab-table-${atBatIdx}-${playIdx}-location`

    return (
      <button
        className='button is-small'
        onClick={(e) => {
          e.stopPropagation()
          trigger.trigger(null, EditTypes.EDIT_LOCATION, event.result || event)
        }}
      >
        {displayLocation}
      </button>
    )
  }),
)

const FieldersButton = inject('store')(
  observer((props) => {
    const { event = {}, trigger } = props

    const { gameState = {}, playEvent = {}, atBatIdx, playIdx } = event

    const { primaryFielderCredits = [] } = playEvent

    let displayFielders = primaryFielderCredits
      .map((fc) => {
        let { position, credit } = fc

        if (credit) {
          position += getFielderCreditDisplay(credit)
        }

        return position
      })
      .join('-')

    if (!displayFielders) {
      displayFielders = '+'
    }

    const id = `ab-table-${atBatIdx}-${playIdx}-primary-fielders`

    return (
      <button
        className='button is-small'
        onClick={(e) => {
          e.stopPropagation()
          event.selectedFielderMovementIndex = null
          event.selectedFielders = primaryFielderCredits || []
          trigger.trigger(null, EditTypes.EDIT_FIELDERS, event)
        }}
      >
        {displayFielders}
      </button>
    )
  }),
)

const BaseButton = inject('store')(
  observer((props) => {
    const { event = {}, base, trigger } = props

    const { gameState = {}, playEvent = {}, atBatIdx, playIdx } = event

    const { runnerMovements = [] } = playEvent

    const runner = runnerMovements[base]

    if (!runner) {
      return null
    }

    const id = `ab-table-${atBatIdx}-${playIdx}-runner-${base}`

    let { end: runnerDisplay = '', movements = [] } = runner

    let runnerOut = false
    let runnerScored = false

    for (const movement of movements) {
      if (movement.out) {
        runnerOut = true
        if (movement.fielderCredits) {
          const positions = []
          for (var i = 0; i < movement.fielderCredits.length; i++) {
            const fielder = movement.fielderCredits[i]
            positions.push(fielder.position)
          }
          if (positions.length > 0) {
            runnerDisplay += ' (' + positions.join('-') + ')'
          }
        }
      } else {
        if (movement.reason) {
          runnerDisplay += ' (' + getEventDisplay(movement.reason, []) + ')'
        }

        if (movement.scored) {
          runnerScored = true

          if (movement.runRulings) {
            for (var i = 0; i < movement.runRulings.length; i++) {
              if (movement.runRulings[i]) {
                runnerDisplay += ' (' + movement.runRulings[i] + ')'
              }
            }
          }
        }
      }
    }

    return (
      <button
        className={cn('button is-small', {
          'is-danger': runnerOut,
          'is-success': runnerScored,
        })}
        onClick={(e) => {
          e.stopPropagation()
          event.selectedBase = base
          trigger.trigger(null, EditTypes.EDIT_RUNNERS, event)
        }}
      >
        {runnerDisplay}
      </button>
    )
  }),
)
