export const PlayEventTypes = [
  { value: '0', label: 'Begin of Play' },
  { value: '1', label: 'Pitcher First Movement' },
  { value: '2', label: 'Ball was Pitched' },
  { value: '3', label: 'Ball was Hit' },
  { value: '4', label: 'Ball was Caught' },
  { value: '5', label: 'Ball was Caught Out' },
  { value: '6', label: 'Ball was Released' },
  { value: '8', label: 'Tag was Applied' },
  { value: '7', label: 'Ball was Deflected' },
  { value: '10', label: 'End of Play' },
  { value: '17', label: 'Ball was Fielded' },
  { value: '19', label: 'Ball Bounce' },
  { value: '23', label: 'Off the Wall' },
  { value: '24', label: 'End of Play Clock' },
]
