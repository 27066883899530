import { useEffect, useState } from 'react'

function getWindowSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  }
}

export function useWindowSize() {
  const [size, setSize] = useState(getWindowSize())

  function updateSize() {
    setSize(getWindowSize())
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  })

  return size
}
