import { useStore } from 'hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { useQuery } from 'react-query'

export const OSCardContent = observer(() => {
  const { gamePk, play } = useStore()
  const { playId } = play
  const { data, isLoading } = useWildPitchProbability(gamePk, playId)
  const wpp = isLoading
    ? 'loading...'
    : data?.jsonData?.prediction
      ? (data?.jsonData?.prediction * 100).toFixed(2) + '%'
      : 'N/A'
  const pbp = wpp.includes('%')
    ? (100 - wpp.slice(0, -1)).toFixed(2) + '%'
    : isLoading
      ? 'loading...'
      : 'N/A'
  return (
    <table className='table mb-0 is-narrow is-bordered is-fullwidth has-text-centered'>
      <thead>
        <tr>
          <th>Wild Pitch Probability</th>
          <th>Passed Ball Probability</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{wpp}</td>
          <td>{pbp}</td>
        </tr>
      </tbody>
    </table>
  )
})

export function useWildPitchProbability(gamePk, playId) {
  return useQuery([`/api/plays/wildPitchProbability`, { gamePk, playId }], {
    enabled: !!gamePk && !!playId,
  })
}
