import { observer } from 'mobx-react'
import { useStore } from '../../../hooks'

export const PlayEventsToggle = observer((props) => {
  const { toggle, showRawEvents } = props
  const store = useStore()

  async function getRawPlayEvents() {
    store.play.fetchRawPlayEvents()
    toggle(!showRawEvents)
  }

  return (
    <button className='button is-fullwidth' onClick={() => getRawPlayEvents()}>
      {showRawEvents
        ? 'Hide Unfiltered Play Events'
        : 'Show Unfiltered Play Events'}
    </button>
  )
})
