export function validateTimeString(string) {
  const n = parseTimeString(string)

  return !isNaN(n) && isFinite(n)
}

export function parseTimeString(string) {
  let [m, s] = string.split(':')
  if (!s) {
    s = m
    m = 0
  }
  let n = parseFloat(s)
  if (m) {
    n += 60 * parseInt(m)
  }

  return n
}
