import Popover from 'react-tiny-popover'
import { useState } from 'react'
import { AddEditPlayEventPopover } from './AddEditPlayEventPopover'

export function AddPlayEventButton(props) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Popover
      isOpen={isOpen}
      content={
        <AddEditPlayEventPopover {...props} close={() => setOpen(false)} />
      }
    >
      <button
        className='button is-fullwidth is-info'
        onClick={() => setOpen(!isOpen)}
      >
        Add Play Event
      </button>
    </Popover>
  )
}
