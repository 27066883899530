import { observer } from 'mobx-react'
import { useState } from 'react'
import Popover from 'react-tiny-popover'
import { AddEditPlayEventPopover } from './AddEditPlayEventPopover'

export const EditPlayEventButton = observer((props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Popover
      position={['left', 'top']}
      isOpen={isOpen}
      content={
        <AddEditPlayEventPopover {...props} close={() => setOpen(false)} />
      }
    >
      <button
        className='button is-small py-0 is-link is-inverted'
        onClick={() => setOpen(!isOpen)}
      >
        <span className='icon'>
          <i className='fas fa-edit' aria-hidden='true' />
        </span>
      </button>
    </Popover>
  )
})
