import React, { Component } from 'react'
import PropTypes from 'prop-types'

const PitchData = (props) => {
  const { title = 'Pitch Data' } = props

  return (
    <div className='card mb-4'>
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title has-text-centered'>{title}</p>
      </header>
      <PitchDataTable {...props} />
    </div>
  )
}

export const PitchDataTable = (props) => {
  const { details = {}, pitchData = {}, title = 'Pitch Data' } = props

  let { pitchSpeed, extension, spinRate, plateTime, eventType, pitchType } =
    pitchData

  if (pitchSpeed) pitchSpeed = pitchSpeed.toFixed(2) + ' MPH'
  if (spinRate) spinRate = spinRate.toFixed(0) + ' RPM'
  if (extension) extension = extension.toFixed(2) + ' FT'

  return (
    <table className='table is-small is-fullwidth is-bordered'>
      <thead>
        <tr>
          <th>Event</th>
          <th>Type</th>
          <th>Speed</th>
          <th>Spin</th>
          <th>Extension</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: '20%' }}>{eventType || <span>&nbsp;</span>}</td>
          <td style={{ width: '20%' }}>{pitchType || <span>&nbsp;</span>}</td>
          <td style={{ width: '20%' }}>{pitchSpeed || <span>&nbsp;</span>}</td>
          <td style={{ width: '20%' }}>{spinRate || <span>&nbsp;</span>}</td>
          <td style={{ width: '20%' }}>{extension || <span>&nbsp;</span>}</td>
        </tr>
      </tbody>
    </table>
  )
}

PitchData.propTypes = {
  pitchData: PropTypes.object,
  details: PropTypes.object,
}

export default PitchData
