import { action, computed, observable } from 'mobx'

/*
 *   UI State
 */

export default class UIStore {
  constructor(store) {
    this.store = store

    this.initialize()
  }

  @observable height = 1080
  @observable width = 1920
  @observable navbarMenuIsOpen = false
  @observable bookmarkModalIsOpen = false

  @action toggleNavbarMenu() {
    this.navbarMenuIsOpen = !this.navbarMenuIsOpen
  }

  @action closeNavbarMenu() {
    this.navbarMenuIsOpen = false
  }

  @action toggleBookmarkModal() {
    this.bookmarkModalIsOpen = !this.bookmarkModalIsOpen
  }

  @action closeBookmarkModal() {
    this.bookmarkModalIsOpen = false
  }

  @action resize = () => {
    this.height = window.innerHeight
    this.width = window.innerWidth
  }

  @computed get isMobile() {
    return this.width <= 768
  }

  @computed get isTablet() {
    return 769 <= this.width && this.width <= 1087
  }

  @computed get isTouch() {
    return this.isMobile || this.isTablet
  }

  @computed get isDesktop() {
    return 1088 <= this.width && this.width <= 1279
  }

  @computed get isWidescreen() {
    return 1280 <= this.width && this.width <= 1471
  }

  @computed get isFullHd() {
    return 1472 <= this.width
  }

  @computed get hasDoubleNavbar() {
    return (
      !this.isMobile &&
      ((this.store.isSchedulePage && this.store.schedule.tabHasFilters) ||
        this.store.isGamePage)
    )
  }

  @computed get navbarHeight() {
    return this.hasDoubleNavbar ? '6.5rem' : '3.25rem'
  }

  @computed get sidebarStyle() {
    return {
      height: this.height,
    }
  }

  initialize() {}
}
