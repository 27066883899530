/**
 * EditReason.js
 *
 * This file was automatically generated and should not be updated manually.
 * See scripts/generateConstants.js for more.
 */

const EditReason = {
  BATTING_ORDER_ASSIGNED: 3,
  OFFICIAL_SCORING_CHANGE: 1,
  OPERATOR_ERROR: 2,
  REPLAY_REVIEW: 4,
  DETAIL_ADJUSTMENT: 5,
  __map: {
    1: {
      value: 1,
      label: 'Official Scoring Change',
      fullLabel: 'Edit Reason: Official Scoring Change',
      editReasonCode: 'OSCHG',
      editReasonNote: 'Official Scoring Change',
      editReasonDesc: 'Official Scoring Change',
      editReasonId: 1,
      sortOrder: 2,
      activeStatus: true,
      publicStatus: true,
    },
    2: {
      value: 2,
      label: 'Operator Error',
      fullLabel: 'Edit Reason: Operator Error',
      editReasonCode: 'OPERROR',
      editReasonNote: 'Operator Error',
      editReasonDesc: 'Operator Error',
      editReasonId: 2,
      sortOrder: 1,
      activeStatus: true,
      publicStatus: true,
    },
    3: {
      value: 3,
      label: 'Batting Order Assigned',
      fullLabel: 'Edit Reason: Batting Order Assigned',
      editReasonCode: 'BATORDER',
      editReasonNote: 'Batting Order Assigned',
      editReasonDesc: 'Batting Order Assigned',
      editReasonId: 3,
      sortOrder: 3,
      activeStatus: true,
      publicStatus: true,
    },
    4: {
      value: 4,
      label: 'Replay Review',
      fullLabel: 'Edit Reason: Replay Review',
      editReasonCode: 'REPREV',
      editReasonNote: 'Replay Review',
      editReasonDesc: 'Replay Review',
      editReasonId: 4,
      sortOrder: 4,
      activeStatus: true,
      publicStatus: true,
    },
    5: {
      value: 5,
      label: 'Detail Adjustment',
      fullLabel: 'Edit Reason: Detail Adjustment',
      editReasonCode: 'DETADJ',
      editReasonNote: 'Detail Adjustment',
      editReasonDesc: 'Detail Adjustment',
      editReasonId: 5,
      sortOrder: 5,
      activeStatus: true,
      publicStatus: true,
    },
  },
}

EditReason.keys = function () {
  return Object.keys(this.__map)
}.bind(EditReason)
EditReason.values = function () {
  return Object.values(this.__map).sort((a, b) =>
    a.label.localeCompare(b.label),
  )
}.bind(EditReason)
EditReason.get = function (id) {
  return this.__map[id]
}.bind(EditReason)
EditReason.getLabel = function (id) {
  return this.__map[id] ? this.__map[id].label : undefined
}.bind(EditReason)
EditReason.getFullLabel = function (id) {
  return this.__map[id] ? this.__map[id].fullLabel : undefined
}.bind(EditReason)

module.exports = Object.freeze(EditReason)
