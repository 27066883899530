import axios from 'axios'
import { NotificationTypes } from 'constants'
import { cloneDeep, omit } from 'lodash'
import { action, computed, observable, reaction, toJS } from 'mobx'
import uuid from 'uuid'
import { deepOmit } from '../util/deepOmit'
import { diffs } from '../util/summarizedAtBatDiffs'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)

export default class UpdateStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @computed get operatorChanges() {
    const operatorChanges = this.updates.map((update) => {
      const _update = cloneDeep(update)
      const changeTypeCodeMap = {
        I: 'C',
        A: 'C',
        C: 'C',
        U: 'U',
        D: 'D',
      }
      const _oldPlayData = omit(
        this.store.game._stringerData.atBats[_update.atBatIdx]?.plays?.[
          _update.playIdx
        ],
        ['gameState'],
      )

      const _oldGameStateInfoData = this.store.game._stringerData.gameStateInfo

      const _newPlayData = omit(
        this.store.game.stringerData.atBats[_update.atBatIdx]?.plays?.[
          _update.playIdx
        ],
        ['gameState', 'updates'],
      )

      const _newGameStateInfoData = this.store.game.stringerData.gameStateInfo

      // the EditStore play object only holds one edit's worth of data, so confusing data is generated when multiple edits are sent.
      // so, we rely solely on the updates attr in these situations and ignore the edit store's play object.
      const isPreOrPostGame = !_update.atBatIdx && !_update.playIdx
      const useEditStorePlayObject = isPreOrPostGame && this.updates.length == 1

      const operatorChange = {
        changeTypeCode:
          changeTypeCodeMap[_update.action?.toUpperCase().charAt(0) || 'U'],
        editReasonId: _update.operatorChangeReason,
        gameId: this.store.gamePk,
        oldData: {
          atBatPlay: _oldPlayData,
          gameStateInfo: _oldGameStateInfoData,
          playFromEditStore: useEditStorePlayObject
            ? this.store.edit._play
            : {},
        },
        newData: {
          atBatPlay: _newPlayData,
          gameStateInfo: _newGameStateInfoData,
          playFromEditStore: useEditStorePlayObject
            ? deepOmit(this.store.edit.play, ['updates'])
            : {},
          allUpdatesInBatch: this.updates,
        },
        diffData: [
          {
            atBatPlay: [...diffs(_oldPlayData, _newPlayData)],
            gameStateInfo: [
              ...diffs(
                this.store.game._stringerData.gameStateInfo,
                this.store.game.stringerData.gameStateInfo,
              ),
            ],
            playFromEditStore: [
              ...(useEditStorePlayObject
                ? diffs(this.store.edit._play, this.store.edit.play)
                : [{}]),
            ],
          },
        ],
        atBatNumber: _update.atBatIdx ? +_update.atBatIdx + 1 : 0,
        actionOfPa: _update.playIdx ? +_update.playIdx + 1 : 0,
        userEditedBy:
          this.store.auth.user.email || this.store.auth.user.username,
        appEditedBy: 'NORAD',
      }
      return operatorChange
    })

    return operatorChanges
  }

  @computed get updates() {
    const updates = []
    const atBats = this.store.game.stringerData
      ? this.store.game.stringerData.atBats || []
      : []

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const plays = atBat.plays ? atBat.plays : []

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]

        if (play.updates) {
          if (play.updates.edit) {
            updates.push(play.updates.edit)
          }

          if (play.updates.insert) {
            updates.push(play.updates.edit)
          }

          if (play.updates.audit) {
            updates.push(play.updates.audit)
          }

          if (play.updates.review) {
            updates.push(play.updates.review)
          }

          if (play.updates.violation) {
            updates.push(play.updates.violation)
          }
        }
      }
    }

    const nonEventUpdates = toJS(this.store.game.nonEventUpdates)
    for (const key in nonEventUpdates) {
      const update = nonEventUpdates[key]
      if (update.updates) {
        if (update.updates.edit) {
          updates.push(update.updates.edit)
        }

        if (update.updates.insert) {
          updates.push(update.updates.edit)
        }
      }
    }

    return updates
  }

  @computed get count() {
    return this.updates.length
  }

  @computed get hasUpdates() {
    return (this.count && true) || false
  }

  @action discard() {
    this.store.game.stringerData = this.store.game._stringerData
    this.store.game.nonEventUpdates = []
    this.store.notifications.close()
    this.store.game.update()
    this.store.game.setInt()
  }

  @observable uuid = null

  @action confirm(data) {
    if (data.uuid == this.uuid) {
      this.store.notifications.trigger(NotificationTypes.UPDATES_ACCEPTED, 2)

      this.store.game.update()
      this.store.game.enterLiveMode()
    }
  }

  @action send() {
    if (!this.count) {
      return
    }
    this.uuid = uuid()
    return this.resend()
  }

  @computed get data() {
    return {
      gamePk: this.store.gamePk,
      isFinal: this.store.game.stringerData.isFinal,
      message: {
        action: 'update',
        'Event Subject': this.store.game.stringerData.gameId,
        gamePk: this.store.gamePk,
        updates: this.updates,
        uuid: this.uuid,
      },
    }
  }

  @action resend() {
    const valid = this.store.dware.validate(this.store.game.stringerData)

    if (!valid) {
      return this.store.notifications.trigger(
        NotificationTypes.PLAY_STRING_ERROR,
      )
    }

    const isFinal = this.store.game.stringerData.isFinal

    if (isFinal) {
      this.store.notifications.trigger(NotificationTypes.FINALIZATION_SENT)
    }

    return fetch({
      method: 'POST',
      url: '/api/updates',
      data: this.data,
    })
      .then((data) => {
        if (!isFinal) {
          this.store.notifications.trigger(NotificationTypes.UPDATES_SENT)
        }
      })
      .then((data) => {
        if (isFinal) {
          return fetch({
            method: 'POST',
            url: '/api/updates/finalize',
            data: {
              gamePk: this.store.gamePk,
            },
          }).then((data) => {
            this.store.notifications.trigger(NotificationTypes.UPDATES_ACCEPTED)
            this.store.game.update(true)
            this.store.game.enterLiveMode()
          })
        }
      })
      .then((data) => {
        return fetch({
          method: 'POST',
          url: '/api/games/operatorChanges',
          data: this.operatorChanges,
        }).catch((error) => {
          console.error(
            `operator change log POST failed - do not interrupt user as this is for internal audit: ${error}`,
          )
        })
      })
      .catch((error) => {
        console.error(error)
        this.store.notifications.trigger(NotificationTypes.UPDATES_ERROR)
      })
  }

  initialize() {
    this.updatesReaction = reaction(
      () => this.hasUpdates,
      (hasUpdates) => {
        if (hasUpdates && !this.store.notifications.type) {
          this.store.notifications.trigger(NotificationTypes.UPDATES_PENDING)
        }
      },
    )
  }
}
