import axios from 'axios'
import { NotificationTypes } from 'constants'
import { action, computed, observable } from 'mobx'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)

export default class NotificationStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable type = null
  @observable message = ''

  @action setMessage(message) {
    this.message = message
  }

  @action trigger(type, timeout) {
    this.type = type

    if (timeout) {
      this.timeout = setTimeout(() => {
        this.type = null
      }, timeout * 1000)
    } else {
      clearTimeout(this.timeout)
    }
  }

  @action close() {
    this.trigger(null, '')
  }

  @action clearType(type) {
    if (this.type == type) {
      this.trigger(null, '')
    }
  }

  @computed get notification() {
    if (!this.type) {
      return null
    }

    if (this.type == NotificationTypes.DIRECT_UPDATE_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Updates Accepted!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Your updates have been accepted.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.DIRECT_UPDATE_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error posting updates. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.UPDATES_PENDING) {
      return (
        <article className='message is-info'>
          <div className='message-header'>
            <p>Updates!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            You have {this.store.updates.count} pending update
            {this.store.updates.count > 1 ? 's' : ''}.{' '}
            <a
              onClick={(e) => {
                this.store.updates.send()
                if (!this.store.dware.playStringError) {
                  this.store.edit.close()
                }
              }}
            >
              Send
            </a>{' '}
            or{' '}
            <a
              onClick={(e) => {
                this.store.updates.discard()
                this.store.edit.close()
              }}
            >
              discard
            </a>
            .
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.FINALIZATION_SENT) {
      return (
        <article className='message is-warning'>
          <div className='message-header'>
            <p>Finalizing changes...</p>
          </div>
          <div className='message-body'>
            Your updates are finalizing. Please wait for confirmation.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.UPDATES_SENT) {
      return (
        <article className='message is-warning'>
          <div className='message-header'>
            <p>Updates Sent!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            You sent {this.store.updates.count}{' '}
            {this.store.updates.count > 1 ? 's' : ''}.{' '}
            <a onClick={(e) => this.store.updates.resend()}>Resend</a> or{' '}
            <a onClick={(e) => this.store.updates.discard()}>dismiss</a>.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.UPDATES_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error sending updates. Please try again or contact engineering
            support.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.UPDATES_ACCEPTED) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Accepted!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Your updates were accepted.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.ROSTERS_REFRESHED) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Accepted!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Rosters Refreshed. Reloading Page...
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.ROSTERS_REFRESH_FAILED) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Roster Refresh. Please try again or contact engineering support.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.PITCH_META_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Pitch data updated successfully.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.PITCH_META_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error updating pitch data. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.AUDIT_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Your audits were accepted.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.AUDIT_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error assigning pitches. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.SCRUB_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Your message was sent to the scrubber.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.SCRUB_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error sending message to scrubber. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.RESOLVE_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Play(s) resolution success.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.RESOLVE_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error resolving metric warnings. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.REMOVE_STRINGER_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Stringer successfully disconnected.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.REMOVE_STRINGER_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error disconnecting Stringer. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.REMOVE_BOSS_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Boss successfully disconnected.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.REMOVE_BOSS_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error disconnecting Boss. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.PLAY_STRING_ERROR) {
      const error = this.store.dware.playStringError || 'Invalid Play(s)'

      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => {
                this.store.notifications.close()
                this.store.notifications.trigger(
                  NotificationTypes.UPDATES_PENDING,
                )
              }}
            />
          </div>
          <div className='message-body'>
            {error} <br /> Click{' '}
            <a
              onClick={(e) => {
                this.store.notifications.close()
                this.store.notifications.trigger(
                  NotificationTypes.UPDATES_PENDING,
                )
              }}
            >
              here
            </a>{' '}
            after fixing.
          </div>
        </article>
      )
    }

    // shows the invalid plays with stringer as soon as they happen rather than whenever
    // the user tries to edit something
    if (this.type == NotificationTypes.PLAY_STRING_WARNING) {
      const error = this.store.dware.playStringError || 'Invalid Play(s)'

      return (
        <article className='message is-warning'>
          <div className='message-header'>
            <p>Warning!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => {
                this.store.notifications.close()
              }}
            />
          </div>
          <div className='message-body'>
            {error} <br /> Click{' '}
            <a
              onClick={(e) => {
                this.store.notifications.close()
                this.store.notifications.trigger(
                  NotificationTypes.UPDATES_PENDING,
                )
              }}
            >
              here
            </a>{' '}
            after fixing.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.ADD_BAD_PLAY_DATA_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Bad play added successfully.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.ADD_BAD_PLAY_DATA_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error adding bad play. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.DELETE_BAD_PLAY_DATA_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>Bad play successfully deleted.</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.DELETE_BAD_PLAY_DATA_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>
            Error deleting bad play. Please try again.
          </div>
        </article>
      )
    }

    if (this.type == NotificationTypes.GENERIC_SUCCESS) {
      return (
        <article className='message is-success'>
          <div className='message-header'>
            <p>Success!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>{this.message}</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.GENERIC_ERROR) {
      return (
        <article className='message is-danger'>
          <div className='message-header'>
            <p>Error!</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>{this.message}</div>
        </article>
      )
    }

    if (this.type == NotificationTypes.GENERIC_INFO) {
      return (
        <article className='message is-info'>
          <div className='message-header'>
            <p>Info</p>
            <button
              className='delete'
              aria-label='delete'
              onClick={(e) => this.store.updates.discard()}
            />
          </div>
          <div className='message-body'>{this.message}</div>
        </article>
      )
    }

    return null
  }

  initialize() {}
}
