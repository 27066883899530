import { StatcastPitch } from '@bdatasf/statcast-pitch'
import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import CursorPosition from './CursorPosition'
import { useStore } from 'hooks'

const PitchEditor = (props) => {
  return (
    <CursorPosition>
      <PitchContainer {...props} />
    </CursorPosition>
  )
}

export default PitchEditor

const PitchContainer = observer((props) => {
  const ref = useRef()
  const store = useStore()
  const { venueId, dayNight, stringerData = {} } = store.game
  const { play = {} } = props
  const {
    topInningSw,
    gameState = {},
    coordX: x,
    coordY: y,
    atBatNumber,
    pitchNum,
  } = play
  const isTopInning = topInningSw == 'Y'
  const { batter: batterId } = gameState
  const { awayRoster = [], homeRoster = [], gameInfo = {} } = stringerData
  const batter =
    awayRoster.find((p) => p.id == batterId) ||
    homeRoster.find((p) => p.id == batterId) ||
    {}
  const {
    lastName: batterName,
    szTop: strikeZoneTop = 3.359,
    szBot: strikeZoneBottom = 1.7,
  } = batter
  const { awayTeamId, homeTeamId } = gameInfo
  const teamId = isTopInning ? awayTeamId : homeTeamId
  const homeAway = isTopInning ? 'away' : 'home'
  const playId = play.playId || `${atBatNumber}-${pitchNum}`

  const pitches = [
    {
      pitchData: {
        coordinates: {
          x,
          y,
        },
        strikeZoneTop,
        strikeZoneBottom,
      },
      isPitch: true,
      details: {
        ballColor: 'white',
      },
    },
  ]

  const pitchProps = {
    venueId,
    dayNight,
    batter,
    teamId,
    homeAway,
    pitches,
    playId,
    strikeZoneTop,
    strikeZoneBottom,
  }

  function handleClick() {
    if (!ref.current) return

    const { x: mouseX, y: mouseY } = props
    const { width, height } = ref.current.getBoundingClientRect()

    const relativeX = mouseX / width

    // pfx min/max
    const maxX = 470
    const minX = -255

    // reverse horizontal orientation
    const coordX = maxX - relativeX * (maxX - minX)

    const relativeY = mouseY / height / 1.2

    // pfx min/max
    const maxY = 370
    const minY = -140

    const coordY = minY + relativeY * (maxY - minY)

    const play = toJS(store.edit.play)

    play.coordX = coordX
    play.coordY = coordY

    store.edit.setPlay(play)
  }

  return (
    <div
      className='pt-3'
      style={{ position: 'relative' }}
      ref={ref}
      onClick={handleClick}
    >
      <StatcastPitch {...pitchProps} />

      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          position: 'absolute',
          padding: '0 10px',
          zIndex: 50,
          bottom: 0,
          left: 0,
        }}
      >
        <p>{batterName}</p>
      </div>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          position: 'absolute',
          padding: '0 10px',
          zIndex: 50,
          bottom: 0,
          right: 0,
        }}
      >
        <p>{strikeZoneTop}</p>
        <p>{strikeZoneBottom}</p>
      </div>
    </div>
  )
})
