const PERSON_LAST_INIT_NAME = (module.exports.PERSON_LAST_INIT_NAME =
  'person.lastInitName')
const PERSON_FULL_NAME = (module.exports.PERSON_FULL_NAME = 'person.fullName')

const PLAYER_LAST_INIT_NAME = (module.exports.PLAYER_LAST_INIT_NAME =
  'player.lastInitName')
const PLAYER_FULL_NAME = (module.exports.PLAYER_FULL_NAME = 'player.fullName')

const BATTER_LAST_INIT_NAME = (module.exports.BATTER_LAST_INIT_NAME =
  'batter.lastInitName')
const BATTER_FULL_NAME = (module.exports.BATTER_FULL_NAME = 'batter.fullName')

const PITCHER_LAST_INIT_NAME = (module.exports.PITCHER_LAST_INIT_NAME =
  'pitcher.lastInitName')
const PITCHER_FULL_NAME = (module.exports.PITCHER_FULL_NAME =
  'pitcher.fullName')

const TEAM_ABBREVIATION = (module.exports.TEAM_ABBREVIATION =
  'team.abbreviation')
const TEAM_NAME = (module.exports.TEAM_NAME = 'team.name')
const TEAM_SPORT_ID = (module.exports.TEAM_SPORT_ID = 'team.sport.id')
