import axios from 'axios'
import { action, computed, observable, reaction } from 'mobx'
import { sortBy, omitBy } from 'lodash'
import EditReason from 'shared/constants/EditReason'

const fetch = (options) => axios(options).then((response) => response.data)

export default class OperatorChangeReasonStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable reasons = null

  @computed get _reasons() {
    return sortBy(
      omitBy(this.reasons, (reason) => !reason.publicStatus),
      ['sortOrder'],
    )
  }

  @computed get shouldFetch() {
    return this.store.isAuthenticated
  }

  @action fetch() {
    clearTimeout(this._timeout)

    fetch({
      url: '/api/picklesLookup/edit_reason',
    })
      .then((data) => {
        this.reasons = data
      })
      .catch((error) => {
        this.reasons = EditReason.values()
      })
  }

  initialize() {
    if (this.shouldFetch) {
      this.fetch()
    }

    this.reaction = reaction(
      () => ({
        params: this.params,
        shouldFetch: this.shouldFetch,
      }),
      ({ shouldFetch }) => {
        if (shouldFetch) {
          this.fetch()
        } else {
          // source.cancel()
        }
      },
    )
  }
}
