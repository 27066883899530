import React, { Component } from 'react'
import PropTypes from 'prop-types'

export const HitData = (props) => {
  const { hitData = {}, title = 'Hit Data' } = props

  return (
    <div className='card mb-4'>
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title has-text-centered'>{title}</p>
      </header>
      <HitDataTable {...props} />
    </div>
  )
}

export const HitDataTable = (props) => {
  const { hitData = {} } = props

  let { launchSpeed, launchAngle, totalDistance } = hitData

  if (launchAngle) launchAngle = launchAngle.toFixed(0) + '°'
  if (launchSpeed) launchSpeed = launchSpeed.toFixed(2) + ' MPH'
  if (totalDistance) totalDistance = totalDistance.toFixed(0) + ' FT'

  return (
    <table className='table is-small is-fullwidth is-bordered'>
      <thead>
        <tr>
          <th>Speed</th>
          <th>Angle</th>
          <th>Distance</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: '40%' }}>{launchSpeed || <span>&nbsp;</span>}</td>
          <td style={{ width: '30%' }}>{launchAngle || <span>&nbsp;</span>}</td>
          <td style={{ width: '30%' }}>
            {totalDistance || <span>&nbsp;</span>}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

HitData.propTypes = {
  hitData: PropTypes.object,
}

export default HitData
