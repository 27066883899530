import { datadogRum } from '@datadog/browser-rum'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/main.scss'

ReactDOM.render(<App />, document.getElementById('root'))

axios
  .get('/api/env')
  .then(({ data }) => {
    if (data.env !== 'local') {
      initDataDogRum({
        env: `${data.env}-${data.region}`,
        version: data.version,
        user: data.user,
      })
    }
  })
  .catch((error) => {
    console.log('Failed to get app info from /api/env: ', error)
    initDataDogRum({ env: 'unknown', version: 'unknown', user: '' })
  })

function initDataDogRum({ env, version, user }) {
  datadogRum.init({
    applicationId: 'd1bf811a-93f8-4b15-bbf7-d4d1de6c6e3f',
    clientToken: 'pubcb527b312c33274c090b93f92aea8fd2',
    site: 'us5.datadoghq.com',
    service: 'bdp---norad',
    env: env,
    version: version,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
  })
  if (user) {
    const datadogUser = {
      email: user.email,
      name: user.username,
      id: user.username,
    }
    datadogRum.setUser(datadogUser)
    datadogRum.startSessionReplayRecording()
  } else {
    try {
      datadogRum.stopSessionReplayRecording()
    } catch (err) {
      // no session recording happening
    }
  }
}
