import React, { useEffect, useState, useRef } from 'react'
import { Skeleton } from '@baseball-data/react-kit'
import cx from 'classnames'
import SkeletonFigureWrapper from './SkeletonFigureWrapper'

export default function SkeletonVideo(props) {
  const ref = useRef(null)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('loadedmetadata', () => setLoaded(true))
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('loadedmetadata', () => setLoaded(true))
      }
    }
  }, [props.src])

  return (
    <figure
      className={cx(props.className, { [props.loadingClassName]: !isLoaded })}
    >
      {!isLoaded && <Skeleton wrapper={SkeletonFigureWrapper} />}
      <video
        ref={ref}
        controls
        src={props.src}
        style={{ visibility: isLoaded ? 'inherit' : 'hidden' }}
      />
    </figure>
  )
}

SkeletonVideo.defaultProps = {
  className: 'image',
  loadingClassName: 'is-16by9',
}
