import cn from 'classnames'
import { useStore } from 'hooks'
import { useSubmitBadVideo, useUnblockVideo } from 'hooks/useSubmitBadVideo'
import React, { useState } from 'react'
import { Role } from '../../../shared/constants'
import { format, parseISO, intervalToDuration, formatDuration } from 'date-fns'
import { orderBy, camelCase, startCase } from 'lodash'
export function VideoSwitcher(props) {
  const [index, setIndex] = useState(0)
  const store = useStore()

  let { videos = [], blockedVideos = [] } = props

  blockedVideos = blockedVideos.map((bv) => ({ rank: bv.sortOrder, ...bv }))
  const videosCombined = orderBy(
    [...videos, ...blockedVideos],
    ['rank'],
    ['asc'],
  )

  if (videos.length === 0 && blockedVideos.length === 0) {
    return (
      <div style={{ width: '100%' }}>
        <p className='has-text-centered my-7'>Video Unavailable</p>
      </div>
    )
  }

  const video = videosCombined[index] || {}

  return (
    <>
      <div className='tabs is-small is-toggle is-fullwidth m-2'>
        <ul>
          {videosCombined.map((video, i) => {
            const setVideo = (e) => setIndex(i)

            if (video.mediaSourceType) {
              const { mediaSourceType, label } = video

              return (
                <li
                  key={mediaSourceType}
                  className={cn({
                    'is-active': index === i,
                  })}
                  onClick={setVideo}
                >
                  <a>{label}</a>
                </li>
              )
            } else {
              const { label } = video
              return (
                <li
                  key={label}
                  className={cn({
                    'is-active': index === i,
                  })}
                  onClick={setVideo}
                >
                  <a>{label}</a>
                </li>
              )
            }
          })}
        </ul>
      </div>

      {video.mediaSourceType != null && (
        <div style={{ width: '100%' }}>
          <video
            style={{ width: '100%' }}
            controls
            autoPlay
            muted
            loop
            type={`video/${video.mediaType}`}
            src={video.playbackUrl}
          />
        </div>
      )}
      {video.mediaSourceType != null && (
        <div>
          <BadVideo video={video} />
        </div>
      )}
      {video.videoSourceId != null &&
        [Role.ADMIN, Role.DATA_QUALITY, Role.SUPER_USER].includes(
          store.auth.role,
        ) && (
          <div>
            <UnblockVideo blockedVideo={video} />
          </div>
        )}
    </>
  )
}

const UnblockVideo = (props) => {
  const submit = useUnblockVideo()
  const { blockedVideo } = props
  const handleUnblockVideo = () => {
    submit.mutate({
      ...blockedVideo,
      gameId: blockedVideo.gamePk,
    })
  }

  const duration = intervalToDuration({
    start: parseISO(blockedVideo.createdTimestamp),
    end: new Date(),
  })

  const formatDurationOpts = duration?.days
    ? 'days'
    : duration?.hours
      ? 'hours'
      : duration?.minutes
        ? 'minutes'
        : duration.seconds
          ? 'seconds'
          : null

  return (
    <>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Blocked Clip</label>
        </div>
        <div className='field-body'>
          {startCase(camelCase(blockedVideo.videoSourceText))}
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>At</label>
        </div>
        <div className='field-body'>
          {format(
            parseISO(blockedVideo.createdTimestamp),
            'yyyy-MM-dd H:mm OOOO',
          )}
          &nbsp; ({formatDuration(duration, { format: [formatDurationOpts] })}{' '}
          ago)
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label'>Reason</label>
        </div>
        <div className='field-body'>{blockedVideo.blockedVideoDescription}</div>
      </div>

      <div className='is-fullwidth'>
        <button className='button m-3' onClick={() => handleUnblockVideo()}>
          Unblock Video
        </button>
      </div>
    </>
  )
}

// Expects a list of videos
const BadVideo = (props) => {
  const { video } = props

  const store = useStore()
  const [description, setDescription] = useState('')

  const submit = useSubmitBadVideo()
  const handleSubmit = () => {
    submit.mutate({
      body: {
        gamePk: store.gamePk,
        playId: store.play.playId,
        feed: video.mediaSourceType,
        description,
      },
    })
  }

  return (
    [Role.ADMIN, Role.DATA_QUALITY, Role.SUPER_USER].includes(
      store.auth.role,
    ) && (
      <div className='field mt-1'>
        <label className='label'>Block Bad Video</label>
        <div className='field is-grouped'>
          <p className='control is-expanded'>
            <input
              className='input'
              type='text'
              placeholder='Description'
              value={description}
              onChange={(e) => {
                setDescription(e.target?.value || '')
              }}
            />
          </p>
          <p className='control'>
            <button className='button' onClick={() => handleSubmit()}>
              Submit
            </button>
          </p>
        </div>
      </div>
    )
  )
}
