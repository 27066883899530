import axios from 'axios'
import { NotificationTypes } from 'constants'
import { action, computed, observable, reaction, toJS } from 'mobx'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)

export default class AuditStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable loading = false
  @observable active = false
  @observable showTimestamps = false
  @observable preserveFilter = false

  @action toggleTimestamps() {
    this.showTimestamps = !this.showTimestamps
  }

  @action togglePreserveFilter() {
    this.preserveFilter = !this.preserveFilter
  }

  @action toggle() {
    this.active = !this.active
  }

  @action show() {
    this.active = true
  }

  @action hide() {
    this.active = false
  }

  @observable filters = {
    atBatNumber: '',
    gameMode: '',
    type: '',
    status: '',
  }

  @computed get gameModes() {
    const thing = this.pitches
      .map((p) => p.gameMode)
      .filter((p, i, self) => self.find((_p) => p.id == _p.id) == self[i])
      .map((gameMode) => {
        return {
          value: gameMode.id,
          label: gameMode.name,
        }
      })

    return thing
  }

  @computed get types() {
    return this.pitches
      .map((p) => p.auditType)
      .filter((p, i, self) => self.indexOf(p) == i)
      .map((n) => {
        return {
          value: n,
          label: n,
        }
      })
  }

  statuses = [
    {
      value: 'true',
      label: 'Assigned',
    },
    {
      value: 'false',
      label: 'Unassigned',
    },
  ]

  @computed get atBatNumbers() {
    const atBatNumbers = this.pitches
      .map((p) => p.atBatNumber)
      .filter((p, i, self) => self.indexOf(p) == i)
      .map((n) => {
        return {
          value: n,
          label: n,
        }
      })
    if (this.filters.atBatNumber) {
      atBatNumbers.push({
        value: this.filters.atBatNumber,
        label: this.filters.atBatNumber,
      })
    }

    return atBatNumbers
  }

  @action resetFilters() {
    this.filters = {
      atBatNumber: `${this.store.game.atBatIdx + 1}`,
      gameMode: '2',
      type: '',
      status: '',
    }
  }

  @action clearFilters() {
    this.filters = {
      atBatNumber: '',
      gameMode: '',
      type: '',
      status: '',
    }
  }

  @observable filtersActive = true
  @action toggleFilters() {
    this.filtersActive = !this.filtersActive
  }

  @action setFilter(key, value) {
    this.store.game.clearInt()
    this.filters[key] = value
  }

  @computed get assignedAtBatMap() {
    const map = []

    const { atBats = [] } = this.store.game

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const atBatIdx = atBat.atBatIdx
      const plays = atBat.plays || []

      map[atBatIdx] = true

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]

        if (play.isAuditable) {
          if (!play.guid) {
            map[atBatIdx] = false
          }
        }
      }
    }

    return map
  }

  @computed get assignedPitchMap() {
    const map = {}

    if (this.store.game.stringerData) {
      const atBats = this.store.game.stringerData.atBats || []

      for (let i = 0; i < atBats.length; i++) {
        const atBat = atBats[i]

        const plays = atBat.plays || []

        for (let j = 0; j < plays.length; j++) {
          const play = plays[j]

          if (play.guid) {
            map[play.guid] = true
          }
        }
      }
    }

    return map
  }

  @observable selectedPlayMap = {}

  @computed get selectedPlayCount() {
    return Object.keys(this.selectedPlayMap).length
  }

  @computed get selectedPlays() {
    const atBats = this.store.game.atBats || []
    const _plays = []

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const plays = atBat.plays || []

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]

        if (this.selectedPlayMap[`${play.atBatIdx}-${play.playIdx}`]) {
          _plays.push(play)
        }
      }
    }

    return _plays
  }

  @computed get selectedAtBatMap() {
    const atBats = this.store.game.stringerData.atBats || []
    const map = []

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const keys = (atBat.plays || [])
        .map((play) => `${atBat.atBatIdx}-${play.playIdx}`)
        .filter((key) => this.auditablePlayMap[key])

      map[atBat.atBatIdx] = keys.every((key) => this.selectedPlayMap[key])
    }

    return map
  }

  @action unselectAll() {
    this.selectedPlayMap = {}
    this.selectedPitchMap = {}
  }

  @computed get auditablePlayMap() {
    return this.store.game.atBats.reduce((map, atBat) => {
      const plays = atBat.plays || []
      for (let i = 0; i < plays.length; i++) {
        const play = plays[i]
        if (play.isAuditable) {
          const key = `${play.atBatIdx}-${play.playIdx}`

          map[key] = true
        }
      }

      return map
    }, {})
  }

  @action selectAtBat(atBatIdx) {
    this.store.game.exitLiveMode()

    const atBat = this.store.game.stringerData.atBats[atBatIdx]
    const plays = atBat.plays || []

    const map = {}

    for (const key in this.selectedPlayMap) {
      if (this.selectedPlayMap.hasOwnProperty(key)) {
        map[key] = this.selectedPlayMap[key]
      }
    }

    for (let i = 0; i < plays.length; i++) {
      const play = plays[i]
      const key = `${atBat.atBatIdx}-${play.playIdx}`

      if (this.auditablePlayMap[key]) {
        if (this.selectedAtBatMap[atBatIdx]) {
          delete map[key]
        } else {
          map[key] = true
        }
      }
    }

    this.selectedPlayMap = map
  }

  @action selectPlay(atBatIdx, playIdx) {
    this.store.game.exitLiveMode()

    const map = {}

    for (const key in this.selectedPlayMap) {
      if (this.selectedPlayMap.hasOwnProperty(key)) {
        map[key] = this.selectedPlayMap[key]
      }
    }

    const key = `${atBatIdx}-${playIdx}`

    if (map[key]) {
      delete map[key]
    } else {
      map[key] = true
    }

    this.selectedPlayMap = map
  }

  @computed get audits() {
    const events = this.store.game.atBats.reduce((array, atBat) => {
      const plays = atBat.plays || []

      for (let i = 0; i < plays.length; i++) {
        const play = plays[i]
        if (this.selectedPlayMap[`${play.atBatIdx}-${play.playIdx}`]) {
          array.push(play)
        }
      }

      return array
    }, [])

    const pitches = this.store.game.pitches.filter(
      (pitch) => this.selectedPitchMap[pitch.playId],
    )

    const audits = []

    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      const pitch = pitches[i]

      const audit = {
        // atBatIdx: event.atBatIdx,
        // playIdx: event.playIdx,
        isLiveGame: !this.store.game.isFinal,
        atBatNumber: event.atBatIdx + 1,
        playId: pitch.playId,
        mlbGameMode: 2, // if being merged to a stringer pitch, it must be a "live" pitch
        inning: event.inning,
        topInning: event.topInningSw == 'Y' ? true : false,
        isPickoff: event.isPickoff ? true : false,
        isPitch: event.type == 'pitch' && !event.isPickoff ? true : false,
        pickoffNumber: event.pickoffNumber || 0,
        pitchNumber: event.realPitchNum || 0,
        pitchClassfication: event.pitchClassfication,
      }

      audits.push(audit)
    }

    return audits
  }

  @action assignAudits() {
    const atBats = this.store.game.stringerData.atBats
    const events = this.selectedPlays
    const pitches = this.selectedPitches

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const plays = atBat.plays || []
      let _plays

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]

        if (this.selectedPitchMap[play.guid]) {
          if (!_plays) {
            _plays = toJS(plays)
          }

          delete _plays[j].guid
        }
      }

      if (_plays) {
        atBats[i].plays.replace(_plays)
      }
    }

    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      const pitch = pitches[i]
      const guid = pitch.playId

      const idx = this.store.game.guids.findIndex(
        (_pitch) => _pitch.guid == guid,
      )
      let _pitch = this.store.game.guids[idx]

      if (_pitch) {
        _pitch = toJS(_pitch)
        _pitch.atBatNumber = event.atBatNumber
        _pitch.pitchNumber = event.realPitchNum
        if (_pitch.gameMode) {
          _pitch.gameMode.id = 2
          _pitch.gameMode.name = 'Live'
        }
        _pitch.inning = event.inning
        _pitch.topInning = event.topInningSw == 'Y' ? true : false

        const _pitches = this.store.game.guids
          .slice(0, idx)
          .concat([_pitch])
          .concat(this.store.game.guids.slice(idx + 1))
        this.store.game.guids.replace(_pitches)
      }

      const atBat = atBats[event.atBatIdx]

      const plays = toJS(atBat.plays)
      const play = toJS(plays[event.playIdx])
      play.guid = guid
      atBat.plays.replace(plays)
    }
  }

  @action postAudits() {
    if (!this.validation.valid) {
      return
    }

    this.assignAudits()
    this.loading = true

    fetch({
      method: 'post',
      url: '/api/audit',
      data: {
        gamePk: this.store.gamePk,
        audits: this.audits,
      },
    })
      .then((data) => {
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.AUDIT_SUCCESS, 2)
        this.store.game.enterLiveMode()
        this.unselectAll()
      })
      .catch((error) => {
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.AUDIT_ERROR, 2)
        this.store.game.enterLiveMode()
        console.error(error)
      })
  }

  @observable selectedPitchMap = {}

  @computed get selectedPitchCount() {
    const count = Object.keys(this.selectedPitchMap).length

    return count
  }

  @computed get selectedPitches() {
    return this.store.game.pitches.filter(
      (pitch) => this.selectedPitchMap[pitch.playId],
    )
  }

  @computed get allPitchesSelected() {
    for (let i = 0; i < this.filteredPitches.length; i++) {
      const pitch = this.filteredPitches[i]

      if (!this.selectedPitchMap[pitch.playId]) {
        return false
      }
    }

    return true
  }

  @action selectPitch(playId) {
    this.store.game.exitLiveMode()

    const map = {}

    for (const key in this.selectedPitchMap) {
      if (this.selectedPitchMap.hasOwnProperty(key)) {
        map[key] = this.selectedPitchMap[key]
      }
    }

    if (map[playId]) {
      delete map[playId]
    } else {
      map[playId] = true
    }

    this.selectedPitchMap = map
  }

  @action selectAllPitches() {
    const map = {}

    if (this.allPitchesSelected) {
      return (this.selectedPitchMap = map)
    }

    for (let i = 0; i < this.filteredPitches.length; i++) {
      const pitch = this.filteredPitches[i]

      map[pitch.playId] = true
    }

    this.selectedPitchMap = map
  }

  @computed get pitches() {
    return this.store.game.pitches
  }

  @computed get filteredPitches() {
    const t = new Date()
    let pitches = this.pitches

    if (this.filters.atBatNumber) {
      const atBatNumbers = this.filters.atBatNumber
        .split(',')
        .map((n) => Number(n))
      const idxMap = {}

      for (let i = 0; i < atBatNumbers.length; i++) {
        const n = atBatNumbers[i]

        let firstIdx
        let lastIdx

        for (let j = 0; j < pitches.length; j++) {
          const p = pitches[j]

          if (p.atBatNumber == n) {
            if (!firstIdx && firstIdx != 0) {
              firstIdx = j
            }

            lastIdx = j
          }
        }

        if (firstIdx || firstIdx == 0) {
          while (firstIdx <= lastIdx) {
            idxMap[firstIdx] = true
            firstIdx += 1
          }
        }
      }

      pitches = pitches.filter((p, i, self) => idxMap[i])
    }

    if (this.filters.gameMode) {
      pitches = pitches.filter((p) => {
        return this.filters.gameMode.includes(p.gameMode.id)
      })
    }

    if (this.filters.type) {
      const types = this.filters.type.split(',')

      pitches = pitches.filter((p) => {
        return types.includes(p.auditType)
      })
    }

    if (this.filters.status) {
      pitches = pitches.filter((p) => {
        if (this.filters.status == 'false') {
          return !p.isAssigned
        } else {
          return p.isAssigned
        }
      })
    }

    return pitches
  }

  @computed get validation() {
    let help
    let valid = false
    let status = 'is-danger'

    if (!this.selectedPlayCount && !this.selectedPitchCount) {
      help = 'Select pitches and events to assign'
    } else if (this.selectedPlayCount && !this.selectedPitchCount) {
      help = 'Select pitches to assign'
    } else if (!this.selectedPlayCount && this.selectedPitchCount) {
      help = 'Select events to assign'
    } else if (this.selectedPlayCount != this.selectedPitchCount) {
      help = 'Select same number of pitches/events'
    } else {
      const events = this.selectedPlays
      const pitches = this.selectedPitches

      help = 'Selections are valid'
      status = 'is-success'
      valid = true

      for (let i = 0; i < events.length; i++) {
        const event = events[i]
        const pitch = pitches[i]

        if (pitch.isPickoff && !event.isPickoff) {
          help = "Pitches and pickoffs don't line up"
          status = 'is-warning'
          valid = false
        }

        if (!pitch.isPickoff && event.isPickoff) {
          help = "Pitches and pickoffs don't line up"
          valid = false
        }
      }
    }

    return {
      help,
      valid,
      status,
    }
  }

  @computed get pitchCount() {
    return this.pitches.length
  }

  @computed get playCount() {
    return this.store.game._atBats.reduce((sum, atBat) => {
      sum += (atBat.plays || []).length
      return sum
    }, 0)
  }

  initialize() {
    this.expandReaction = reaction(
      () => this.store.game.atBatIdx,
      (map) => {
        if (!this.preserveFilter) {
          this.resetFilters()
          this.selectedPitchMap = {}
        }
      },
    )

    this.epReaction = reaction(
      () => ({
        pCount: this.pitchCount,
        eCount: this.playCount,
      }),
      ({ pCount, eCount }) => {
        this.resetFilters()
      },
    )
  }
}
