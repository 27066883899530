import { StatcastPlay } from '@baseball-data/react-kit'
import { css } from '@emotion/react'
import cn from 'classnames'
import BadPlays from 'components/play/BadPlays'
import { Calibration } from 'components/play/Calibration'
import { DiagramCardContent } from 'components/play/DiagramCard'
import { GamedayCardContent } from 'components/play/GamedayCard'
import MetricErrors from 'components/play/MetricErrors'
import { OSCardContent } from 'components/play/OSCard'
import { StatsCardContent } from 'components/play/StatsCard'
import { TrackedEventsTable } from 'components/play/TrackedEvents'
import { VideoSwitcher } from 'components/play/VideoSwitcher'
import { useStore } from 'hooks/useStore'
import { startCase } from 'lodash'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Role } from 'shared/constants'

const Page = {
  Tracking: 'tracking',
  Events: 'plays',
  Plays: 'plays',
}

export const PlayTab = {
  Video: 'video',
  Simulator: 'sim',
  OS: 'os',
  Stats: 'stats',
  Diagram: 'diagram',
  Gameday: 'gameday',
  BadPlays: 'bad-plays',
  Calibration: 'calibration',
}

function getPlayTabs({ page, role }) {
  const tabs =
    page === Page.Tracking
      ? [
          PlayTab.Video,
          PlayTab.Gameday,
          PlayTab.Stats,
          PlayTab.Diagram,
          PlayTab.Simulator,
        ]
      : [
          PlayTab.Video,
          PlayTab.Stats,
          PlayTab.OS,
          PlayTab.Simulator,
          PlayTab.Diagram,
          PlayTab.Gameday,
        ]
  if (role !== Role.USER) {
    tabs.push(PlayTab.BadPlays)
    if (page === Page.Tracking) {
      tabs.push(PlayTab.Calibration)
    }
  }

  return tabs
}
const Play = observer(() => {
  const store = useStore()
  const setTab = (tab) => {
    store.play.setTab(tab)
  }

  const { role } = store.auth

  const { isLoading, page } = store.game

  const { tab: activeTab, playId, gamePk } = store.play

  const tabs = getPlayTabs({ page, role })

  if (isLoading) {
    return null
  }

  return (
    <div className='card my-3'>
      <div
        className='card-header'
        css={css`
          background-color: whitesmoke;
        `}
      >
        <div
          className='tabs is-fullwidth mt-3 has-text-weight-bold'
          css={css`
            width: 100%;
            font-size: 1em;
          `}
        >
          <ul>
            {tabs.map((tab) => {
              return (
                <li
                  key={tab}
                  className={cn('is-capitalized', {
                    'is-active': tab == activeTab,
                  })}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      setTab(tab)
                    }}
                  >
                    {tab.toUpperCase() === 'OS'
                      ? tab.toUpperCase()
                      : startCase(tab)}
                  </a>
                </li>
              )
            })}
            <li>
              <Link
                target='_blank'
                to={`/games/${gamePk}/plays/${playId}`}
                disabled={!playId}
              >
                <span className='icon'>
                  <i className='fas fa-external-link-alt' aria-hidden='true' />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='p-4'>
        <PlayContent />
      </div>
    </div>
  )
})

export default Play

const PlayContent = observer(() => {
  const store = useStore()
  const { page, isTracking = false } = store.game

  const {
    data = {},
    tab: activeTab,
    isLoaded,
    isLoading,
    videos = [],
    blockedVideos = [],
    playId,
    atBatIdx,
    analytics,
    backlog = [],
  } = store.play

  const { metricErrors = [], diagramSrc = '' } = data

  const NO_TRACKING_MESSAGE = 'No tracking data assigned to this play.'

  if ((!isTracking && page !== Page.Plays) || !playId) {
    return (
      <div className='notification is-warning has-text-centered'>
        {NO_TRACKING_MESSAGE}
      </div>
    )
  }

  let pitchData = {}
  let hitData = {}
  let pfxData = {}

  const guid = store.game.guids.find((guid) => guid.playId == playId)

  if (guid) {
    pitchData = guid.pitchData
    hitData = guid.hitData
    pfxData = guid.pfxData
  }

  switch (activeTab) {
    case PlayTab.Video:
      return <VideoSwitcher videos={videos} blockedVideos={blockedVideos} />
    case PlayTab.Gameday:
      return (
        <GamedayCardContent
          playId={playId}
          pfxData={pfxData}
          key={pfxData?.playId}
        />
      )
    case PlayTab.BadPlays:
      return <BadPlays />
    case PlayTab.Diagram:
      if (page !== Page.Tracking) {
        return <DiagramCardContent src={diagramSrc} />
      }

      return (
        <div className='columns is-multiline is-gapless'>
          <div className='column is-7 mb-4'>
            <DiagramCardContent src={diagramSrc} playId={playId} />
          </div>
          <div className='column is-5 mb-4'>
            <div className='mx-2'>
              <TrackedEventsTable />
            </div>
          </div>
          <div className='column is-12'>
            <MetricErrors
              atBatIdx={atBatIdx}
              playId={playId}
              metricErrors={metricErrors}
              backlog={backlog}
            />
          </div>
        </div>
      )
    case PlayTab.Stats:
      if (page !== Page.Tracking) {
        return (
          <StatsCardContent
            hitData={hitData}
            pitchData={pitchData}
            playId={playId}
          />
        )
      }

      return (
        <div>
          <StatsCardContent
            hitData={hitData}
            pitchData={pitchData}
            playId={playId}
          />

          <div>
            <p className='subtitle mb-2'>Tracked Events</p>
            <TrackedEventsTable />
            <MetricErrors
              atBatIdx={atBatIdx}
              playId={playId}
              metricErrors={metricErrors}
              backlog={backlog}
            />
          </div>
        </div>
      )
    case PlayTab.Simulator:
      if (page !== Page.Tracking) {
        return <StatcastPlay {...analytics} />
      }

      return (
        <div className='columns is-multiline is-gapless'>
          <div className='column is-7 mb-2'>
            <StatcastPlay {...analytics} />
          </div>
          <div className='column is-5'>
            <div className='mx-2'>
              <TrackedEventsTable />
            </div>
          </div>
          <div className='column is-12'>
            <MetricErrors
              atBatIdx={atBatIdx}
              playId={playId}
              metricErrors={metricErrors}
              backlog={backlog}
            />
          </div>
        </div>
      )
    case PlayTab.Calibration:
      return page === Page.Tracking ? <Calibration /> : null
    case PlayTab.OS:
      return page !== Page.Tracking ? <OSCardContent /> : null
    default:
      return null
  }
})
