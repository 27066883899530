import axios from 'axios'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'
import { DateFormat } from 'shared/constants'
import uuid from 'uuid'

const fetch = (options) =>
  axios({ ...options }).then((response) => response.data)
export default class ConfigStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable sports = []
  @observable leagues = []
  @observable metricOverrideTypes = []

  update() {
    if (!this.store.auth.isAuthenticated) {
      return
    }

    const id = uuid()

    this._requests[id] = true

    return fetch({
      url: `/api/config`,
      params: {
        activeStatus: 'Y',
      },
    })
      .then((data) => {
        this.merge(data)
      })
      .finally(
        action(() => {
          delete this._requests[id]
        }),
      )
  }

  @action merge(data) {
    if (data.sports) {
      this.sports.replace(data.sports)
    }
    if (data.leagues) {
      this.leagues.replace(data.leagues)
    }
    if (data.metricOverrideTypes) {
      this.metricOverrideTypes.replace(data.metricOverrideTypes)
    }
  }

  @computed get sportMap() {
    return this.sports.reduce((map, s) => {
      map[s.id] = s
      return map
    }, {})
  }

  @computed get leagueMap() {
    return this.leagues.reduce((map, s) => {
      map[s.id] = s
      return map
    }, {})
  }

  getSport(id) {
    return this.sportMap[id]
  }

  getSportCode(id) {
    const sport = this.getSport(id)
    return sport ? sport.code : ''
  }

  @computed get season() {
    return (
      this.store.hash.date
        ? moment(this.store.hash.date, DateFormat.URL_SAFE)
        : this.store.date
    ).year()
  }

  @computed get orgOptions() {
    const sportOptions = this.sports
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1
        }

        return 1
      })
      .map((s) => {
        return {
          label:
            s.code.toUpperCase().replace(/\s+/g, '') + ` (${s.id.toString()})`,
          value: s.id.toString(),
        }
      })

    const leagueOptions = this.leagues
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1
        }

        return 1
      })
      .map((s) => {
        const label = s.orgCode
          ? s.orgCode.toUpperCase().replace(/\s+/g, '') +
            ` (${s.id.toString()})`
          : `(${s.id.toString()})`
        return {
          label,
          value: s.id.toString(),
        }
      })

    return [
      {
        label: 'ALL',
        value: 'all',
      },
      ...sportOptions,
      ...leagueOptions,
    ]
  }

  @observable _requests = {}

  @computed get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  initialize() {
    this.authReaction = reaction(
      () => this.store.auth.isAuthenticated,
      (authenticated) => {
        if (authenticated) {
          this.update()
        }
      },
    )

    this.seasonReaction = reaction(
      () => this.season,
      (season) => {
        this.update()
      },
    )
  }
}
