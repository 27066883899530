import { action, computed, observable, reaction } from 'mobx'
export default class BossStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable isLiveMode = true

  @action exitLiveMode() {
    this.isLiveMode = false
  }

  @action toggleLiveMode() {
    this.isLiveMode = !this.isLiveMode
  }

  @computed get isFinal() {
    return this.store.game.schedule?.status?.abstractGameCode === 'F'
  }

  initialize() {
    this.statusReaction = reaction(
      () => this.isFinal,
      (isFinal) => {
        if (isFinal) {
          this.exitLiveMode()
        }
      },
    )
  }
}
