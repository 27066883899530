import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'

export default function BadPlays() {
  return (
    <>
      <BadPlayDataTable />
      <BadPlayDataForm />
    </>
  )
}

const DEFAULT_STATE = {
  metricOverrideTypeId: '',
  positionId: '',
  description: '',
  metricValue: '',
  metricData: '',
}

const validateState = (badPlayData, state) => {
  const props = ['positionId', 'metricOverrideTypeId', 'description']

  if (
    badPlayData.some((data) => props.every((prop) => data[prop] == state[prop]))
  ) {
    return [false, 'Duplicate bad play data']
  }

  for (const prop of props) {
    if (!state[prop] && state[prop] !== 0) {
      return [false, `Missing required field: ${prop}`]
    }
  }

  return [true]
}

const BadPlayDataForm = inject('store')(
  observer((props) => {
    const { metricOverrideTypes = [] } = props.store.config
    const { positions = [] } = props.store.play

    const [state, setState] = useState(DEFAULT_STATE)

    const updateState = (key, value) => {
      setState((prevState) => {
        return {
          ...prevState,
          [key]: value,
        }
      })
    }

    const [isValid, help] = validateState(props.store.play.badPlayData, state)

    const { description, positionId, metricOverrideTypeId } = state

    return (
      <form>
        <p className='subtitle is-capitalized'>Add bad play data</p>
        <div className='field'>
          <label className='label is-small'>Metric Type</label>
          <div className='control'>
            <Select
              className='is-capitalized is-small'
              options={metricOverrideTypes.map((type) => ({
                label: type.metricOverrideText,
                value: type.metricOverrideTypeId,
              }))}
              value={metricOverrideTypeId}
              placeholder='Metric Override Type...'
              onChange={(value) => {
                updateState('metricOverrideTypeId', value)
              }}
              simpleValue
            />
          </div>
          {!metricOverrideTypeId ? (
            <p className='help is-danger'>Metric type is required</p>
          ) : (
            <p className='help' />
          )}
        </div>

        <div className='field'>
          <label className='label is-small'>Position</label>
          <div className='control'>
            <Select
              className='is-small'
              options={positions}
              value={positionId}
              placeholder='Position...'
              labelKey='display'
              onChange={(value) => {
                updateState('positionId', value)
              }}
              simpleValue
            />
          </div>
          {!positionId && positionId !== 0 ? (
            <p className='help is-danger'>Position is required</p>
          ) : (
            <p className='help' />
          )}
        </div>

        <div className='field'>
          <label className='label is-small'>Description</label>
          <div className='control'>
            <textarea
              className='textarea'
              value={description}
              placeholder='Description...'
              onChange={(e) => {
                updateState('description', e.target.value)
              }}
            />
          </div>
          {!description ? (
            <p className='help is-danger'>Description is required</p>
          ) : (
            <p className='help' />
          )}
        </div>

        <div className='field'>
          <div className='control'>
            <button
              className='button is-small'
              disabled={!isValid}
              onClick={(e) => {
                e.preventDefault()
                props.store.play.addBadPlayData(state)
              }}
            >
              Add Bad Play Data
            </button>
          </div>
        </div>
      </form>
    )
  }),
)

const BadPlayDataTable = inject('store')(
  observer((props) => {
    const { metricOverrideTypes = [] } = props.store.config
    const { badPlayData = [], positions = [] } = props.store.play

    return (
      <>
        <p className='subtitle is-capitalized'>Bad play data</p>
        <table className='table is-hoverable is-narrow is-fullwidth mb-3'>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Position</th>
              <th style={{ width: '25%' }}>Type</th>
              <th style={{}}>Description</th>
              {/* <th style={{}} className='has-text-centered'><i className='fas fa-edit'></i></th> */}
              <th style={{}} className='has-text-centered'>
                <i className='fas fa-times' />
              </th>
            </tr>
          </thead>
          <tbody>
            {badPlayData.map((data, index) => {
              const { positionId, metricOverrideTypeId, description } = data

              const position =
                positions.find((pos) => {
                  return pos.value === positionId
                }) || {}

              const type =
                metricOverrideTypes.find((type) => {
                  return type.metricOverrideTypeId == metricOverrideTypeId
                }) || {}

              return (
                <tr key={index}>
                  <td>{position.display}</td>
                  <td className='is-capitalized'>{type.metricOverrideText}</td>
                  <td>{description}</td>
                  {/* <td className='has-text-centered'>
                                        <button className='button is-small is-borderless'>
                                            <i className='fas fa-edit'></i>
                                        </button>
                                    </td> */}
                  <td className='has-text-centered'>
                    <button
                      className='button is-small is-borderless'
                      onClick={(e) => {
                        props.store.play.deleteBadPlayData(data)
                      }}
                    >
                      <i className='fas fa-times' />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {badPlayData.length === 0 ? (
          <p className='has-text-centered my-2'>
            No bad play data for this play.
          </p>
        ) : null}
        <hr />
      </>
    )
  }),
)
