import React, { useEffect, useState } from 'react'
import { Skeleton } from '@baseball-data/react-kit'
import cx from 'classnames'
import SkeletonFigureWrapper from './SkeletonFigureWrapper'

export default function SkeletonImage(props) {
  const [src, setSrc] = useState('')

  useEffect(() => {
    setSrc('')

    const srcImage = new Image()
    const fallbackImage = new Image()

    srcImage.onload = () => setSrc(srcImage.src)
    srcImage.onerror = () => {
      fallbackImage.onload = setSrc(fallbackImage.src)
      fallbackImage.src = props.fallback
    }

    srcImage.src = props.src

    return () => {
      srcImage.onload = null
      srcImage.onerror = null
      fallbackImage.onload = null
    }
  }, [props.fallback, props.src])

  return (
    <figure className={cx(props.className, { [props.loadingClassName]: !src })}>
      {src ? <img src={src} /> : <Skeleton wrapper={SkeletonFigureWrapper} />}
    </figure>
  )
}

SkeletonImage.defaultProps = {
  className: 'image',
  loadingClassName: 'is-16by9',
}
