export const Positions = [
  { value: '1', label: 'Pitcher' },
  { value: '2', label: 'Catcher' },
  { value: '3', label: '1st Base' },
  { value: '4', label: '2nd Base' },
  { value: '5', label: '3rd Base' },
  { value: '6', label: 'Shortstop' },
  { value: '7', label: 'Left Field' },
  { value: '8', label: 'Center Field' },
  { value: '9', label: 'Right Field' },
  { value: '10', label: 'Batter' },
  { value: '11', label: '1st Base Runner' },
  { value: '12', label: '2nd Base Runner' },
  { value: '13', label: '3rd Base Runner' },
  { value: '14', label: 'Plate Umpire' },
  { value: '15', label: '1B Umpire' },
  { value: '16', label: '2B Umpire' },
  { value: '17', label: '3B Umpire' },
  { value: '20', label: 'LF Umpire' },
  { value: '21', label: 'RF Umpire' },
  { value: '18', label: '1B Coach' },
  { value: '19', label: '3B Coach' },
  { value: '0', label: 'Unknown' },
]
